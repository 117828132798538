import React from 'react';
import clsx from 'clsx';
import { readString } from 'papaparse';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import axios from 'axios';
import {getToken} from '../helpers/Token';
import {getAppUrl} from '../helpers/Defaults';
import Button from '@material-ui/core/Button';
import Loader from './blocks/Loader'

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headRows = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
  { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
  { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
  { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
  { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
  { id: 'protein1', numeric: true, disablePadding: false, label: 'Protein (g)' },
  { id: 'protein2', numeric: true, disablePadding: false, label: 'Protein (g)' },
  { id: 'protein3', numeric: true, disablePadding: false, label: 'Protein (g)' },
  { id: 'protein4', numeric: true, disablePadding: false, label: 'Protein (g)' },
];


function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, csvData, csvHeader } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
//console.log(csvData);
  

return (
	  
    <TableHead>
      <TableRow>
        {csvHeader.map(row => (
          <TableCell
            key='1'
            align="left"
            padding='default'
            sortDirection='false'
          >
            <TableSortLabel
            >
              {row}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
Vorschau          
			</Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));


export default function CSVDisplay(props) {
	var itemId = props.itemId;
	var items = props.items;
	var language = props.language;
	var planungsId = props.nextMask;
	const classes = useStyles();
	const [loading, setLoading] = React.useState(true);
	const [csvContent, setCsvContent] = React.useState([]);
	const [csvData, setCsvData] = React.useState([]);
	const [csvHeader, setCsvHeader] = React.useState([]);
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	function handleRequestSort(event, property) {
		const isDesc = orderBy === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderBy(property);
	}

	function handleSelectAllClick(event) {
		if (event.target.checked) {
		const newSelecteds = rows.map(n => n.name);
		setSelected(newSelecteds);
		return;
		}
		setSelected([]);
	}

	function handleClick(event, name) {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
		newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
		newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
		newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
		newSelected = newSelected.concat(
			selected.slice(0, selectedIndex),
			selected.slice(selectedIndex + 1),
		);
		}

		setSelected(newSelected);
	}

	function handleChangePage(event, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(+event.target.value);
		setPage(0);
	}

	function handleChangeDense(event) {
		setDense(event.target.checked);
	}

	function downloadFile(event) {
		var fileDownload = require('js-file-download');
		fileDownload(csvContent, 'report.csv');
	}


	const RowParser = row => {
		const reg = /"(.*?)"/g;
		const matches = row.match(reg);
		if (matches) {
			return matches.map(value => {
				return value.substr(1, value.length - 2);
			});
		}
		return row.split(";");
	};
  	const isSelected = name => selected.indexOf(name) !== -1;

	function getData() {			
		var sItemId = "?itemId="+itemId; 
		alert(getAppUrl() + '/gatebackend/rest/content/planning/'+ planungsId + sItemId + "&language="+language + "&items="+items);
	 	axios.get(getAppUrl() + '/gatebackend/rest/content/planning/'+ planungsId + sItemId + "&language=de" + "&items=7286214", 
					{
						headers: { 
							"Authorization": " Bearer " + getToken()
						}
					}).then(res => {
						var base64 = require('base-64');
						var utf8 = require('utf8');
						var bytes = base64.decode(res.data.data);
						var encoded = utf8.decode(bytes);

						const splitLines = require('split-lines');

						setLoading(false);

						var data = [];
						var lines = splitLines(encoded);
						lines.forEach(function (item, index) {
							if(index === 0) {
								setCsvHeader(RowParser(item));
							}
							else {
								if(item !== "")
									data.push(RowParser(item));
							}
							
						});

						setCsvData(data);
						setCsvContent(encoded);
			});
	}
  
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	if(loading === true) {
			getData();
			return (<><span>Ihre Planung wird berechnet</span><Loader/></>);
	}
  else
		return (
			<div className={classes.root}>
			<Paper className={classes.paper}>
				<EnhancedTableToolbar numSelected={selected.length} />
				<div className={classes.tableWrapper}>
				<Table
					className={classes.table}
					aria-labelledby="tableTitle"
					size={dense ? 'small' : 'medium'}
				>
					<EnhancedTableHead
					csvHeader={csvHeader}
					csvData={csvData}
					numSelected={selected.length}
					order={order}
					orderBy={orderBy}
					onSelectAllClick={handleSelectAllClick}
					onRequestSort={handleRequestSort}
					rowCount={rows.length}
					/>
					<TableBody>
					{stableSort(csvData, getSorting(order, orderBy))
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((row, index) => {
							
						const isItemSelected = isSelected(row.name);
						const labelId = `enhanced-table-checkbox-${index}`;

						return (
							<TableRow
								hover
								onClick={event => handleClick(event, row.name)}
								role="checkbox"
								aria-checked={isItemSelected}
								tabIndex={-1}
								key={row.name}
								selected={isItemSelected}
							>
								{row.map((r, i) => {
									return (<TableCell align="left"><div className="tdHeight">{r}</div></TableCell>)
								})
							}
							</TableRow>
						);
						})}
					{emptyRows > 0 && (
						<TableRow style={{ height: 49 * emptyRows }}>
						<TableCell colSpan={6} />
						</TableRow>
					)}
					</TableBody>
				</Table>
				</div>
				<div className="row clearmargin"  align="start">
					<div className="col-sm lineHeight60">
						<Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={downloadFile}>
							<span className="fa fa-download"></span>&nbsp;download File
						</Button>
					</div>
					<div className="col-sm">
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							backIconButtonProps={{
								'aria-label': 'Previous Page',
							}}
							nextIconButtonProps={{
								'aria-label': 'Next Page',
							}}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</div>
				
				</div>
				
			</Paper>
			</div>
		);
}