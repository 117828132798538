import React, { Component } from 'react';
import axios from 'axios';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import {Modal, Button} from 'react-bootstrap';
import CreateNew from './blocks/CreateNew';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import Loader from './blocks/Loader'

class Planings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentTableId: 0,
            offset: 0,
            isLoaded : false,
            productData: [],
            currentSearch: ""
        };
        this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
        this.gotoDetail = this.gotoDetail.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.renderSilentPDF = this.renderSilentPDF.bind(this);
        this.renderSilentExport = this.renderSilentExport.bind(this);
        
    }

    renderSilentPDF(id)  {
        let elem = {};
        elem.job = "renderPDF";
        elem.planing = id;
        elem.item = -1;
        elem.name = "Render Planung - ID:" + id;
        axios.post(getAppUrl() + '/gatebackend/rest/settings/insertSilentJob',
        elem,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.props.enqueueSnackbar(this.props.t('JobSilent') + elem.name, { variant: 'info' });
  
            });
    }

    renderSilentExport(id)  {
        let elem = {};
        elem.job = "exportCSV";
        elem.planing = id;
        elem.item = -1;       
        elem.name = "Export CSV - ID:" + id;
 
        axios.post(getAppUrl() + '/gatebackend/rest/settings/insertSilentJob',
        elem,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.props.enqueueSnackbar(this.props.t('JobSilent') + elem.name, { variant: 'info' });  
            });
    }    

    searchChange(e) {
        this.setState({
			currentSearch: e
		});
    }

    componentDidMount() {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/planings', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    isLoaded : true,
                    posts: res.data
                })   
            });
    }

	gotoDetail(id) {
		this.props.history.push("/planing/" + id);
	}

    handleClose() {
        this.setState({ show: false });
    }

    handleShow(e) {
        this.setState({currentTableId : e.currentTarget.dataset.id});
        this.setState({currentTableName : e.currentTarget.dataset.tablename});
        
        this.setState({ show: true });
    }

    render() {
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">
                            {this.props.t('Planungen')}
                            <CreateNew searchChange={this.searchChange} actionNew={this.createNew} title="Neue Planung"/>
                        </div>
                        <div class="Vivacardbody">
                            <div class="border">
                                <div class="row clearmargin"  align="start">
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header "> 
                                            {this.props.t('ID')}
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            {this.props.t('Name')}
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            {this.props.t('Export')}
                                        </div>
                                    </div>        
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            {this.props.t('PDF')}
                                        </div>
                                    </div>                            
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            {this.props.t('bearbeiten')}
                                        </div>
                                    </div>
                                </div>
                                {
                                    posts.map(post => (
                                        <>
                                        {this.state.currentSearch === "" || ((post.id).toString().includes(this.state.currentSearch) ||  post.label.toLowerCase().includes((this.state.currentSearch).toLowerCase())) ? (

                                        <div class="row clearmargin grid-Row" data-id={post.id} data-tablename={post.name}  onDoubleClick={((e) => this.gotoDetail(post.id))} align="start">
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value" > 
                                                    {post.id}
                                                </div>
                                            </div>
                                            <div class="grid-Cell col-sm"> 
                                                <div class="grid-Cell__value"> 
                                                    {post.label}
                                                </div>
                                            </div> 
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    <ul>  
                                                        <li class="fa fa-file-excel-o gridIcon" onClick={((e) => this.renderSilentExport(post.id))}>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    <ul>  
                                                        <li class="fa fa-file-pdf-o gridIcon" onClick={((e) => this.renderSilentPDF(post.id))}>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>                                                                                          
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    <ul>  
                                                        <li class="fa fa-pencil gridIcon">

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>                                                                                    
                                        </div>
                                        ) : (<></>)}
                                        </>                                        
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                </div> 
            );
       }
    }
}
export default withTranslation()(withSnackbar(Planings)) ; 
