import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Link} from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
class CreateNew extends Component {


    constructor(props) {

        super(props);
        this.state = {
            posts: [],
            anchorElFlag: null,
            setAnchorElFlag: null,
            newValue: "",
            open : false,
            currentSearch: ""
        };
        this.handleClickFlag = this.handleClickFlag.bind(this);
        this.handleCloseFlag = this.handleCloseFlag.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.createNew = this.createNew.bind(this);
        this.change = this.change.bind(this);
        this.searchChange = this.searchChange.bind(this);

    }

    searchChange(e) {
        if (typeof this.props.searchChange === 'function') {
            this.props.searchChange(e.target.value);
        }        
        this.setState({
			currentSearch: e.target.value
		});
    }    

    change(e) {
        this.setState({
            newValue: e.target.value
        });          
    }

    createNew(e) {
        this.setState({
            open: null
        });        
        if (typeof this.props.actionNew === 'function') {
            this.props.actionNew(this.state.newValue);
        }

    }

    handleClickFlag(event) {
        this.setState({
            anchorElFlag: event.currentTarget,
        })
    }

    handleCloseFlag() {
        this.setState({
            anchorElFlag: null
        })
    }

    handleClose() {
        this.setState({
            open: null
        })
    }

    handleNew(kind) {
        this.setState({
            anchorElFlag: null,
            open: true
        });
    }

    render() {
        const {isLoaded, posts} = this.state;
        return (
            <>                      
                <span className="float-right">
                    <form  noValidate autoComplete="off">
                        <TextField 
                            className="defaultSearch"
                            value={this.state.currentChange}
                            onChange={e => this.searchChange(e)}
                            id="standard-basic" 
                            label="Suche" />
               
                        <Button className="buttonIcon" aria-controls="simple-menuFlag" aria-haspopup="true" onClick={this.handleClickFlag}>
                            <span class="fa fa-ellipsis-v pull-right"></span>
                        </Button>
                        <Menu
                            id="simple-menuFlag "
                            anchorEl={this.state.anchorElFlag}
                            keepMounted
                            open={Boolean(this.state.anchorElFlag)}
                            onClose={this.handleCloseFlag}
                        >
                        <MenuItem onClick={((e) => this.handleNew(true))}>Neu</MenuItem>
                        </Menu>
                    </form>
                </span>
                <Dialog open={this.state.open} onClose={(e) => this.handleClose(e)} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Bitte geben Sie einen Namen ein.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            name="newLabel"
                            margin="dense"
                            label="placeholder"
                            type="input"
                            onChange={e => this.change(e)}
                            value={this.state.newValue}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => this.handleClose(e)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={(e) => this.createNew(e)} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>  
            </>          
        );
    
    }
}


export default CreateNew;