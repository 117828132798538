import React, { Component } from 'react';
import {getToken, getUser} from '../helpers/Token';
import VivaMask from './VivaMask';
import Tables from './Tables';
import Users from './Users';
import ChooseList from './ChooseList';
import Imports from './Imports';
import Groups from './Groups';
import Exports from './Exports';
import Planings from './Planings';
import PlaningDetails from './PlaningDetails';
import ConfigureMaster from './ConfigureMaster';
import ConfigureCar from './ConfigureCar';
import HandleText from './HandleText';
import Masken from './Masken';
import GateMenu from './GateMenu';
import Queries from './Queries';
import Dashboard from './Dashboard';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Drawer from '@material-ui/core/Drawer';
import { emphasize, withStyles, makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Route, Link, Switch } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {getAppUrl, getCompanyName} from '../helpers/Defaults';
import './ApplicationFrame.css';
import 'font-awesome/css/font-awesome.min.css';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import MessageBlock from './blocks/MessageBlock';
import Grid from '@material-ui/core/Grid';
import ConfirmAction from './blocks/ConfirmAction';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import DoneIcon from '@material-ui/icons/Done';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import { Redirect } from 'react-router'
import AuthenticatedRoute from './blocks/Authorization'
import Loader from './blocks/Loader'
import SnackMessage from './blocks/SnackMessage';
import GetAppIcon from '@material-ui/icons/GetApp';
import Moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import CustomNodeContentRenderer from './blocks/CustomNodeInlineEdit/CustomNodeContentRenderer';
import AlltoursIcons from './blocks/icon-helper/AlltoursIcons';
import ASTextExport from './ASTextExport';
import ASLanguageOverview from './ASLanguageOverview';
import ShopwareExports from './ShopwareExports';
import ArticleClasses from './ArticleClasses';
import ClassColumns from './ClassColumns';

import SortableTree, { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath, getNodeAtPath, toggleExpandedForAll, defaultSearchMethod, map as mapTree } from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app

const StyledBreadcrumb = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        height: 24,
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
        backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
    }))(Chip);

function generateTree(bookMarkElements) {
		let treeData = [];
		bookMarkElements.map(function(plElement) {
            let elem = {};
            elem.type= plElement.type;
            elem.editMode = 0;
            if(elem.type === 0)
                elem.class=  "bookmarkfolder";
            if(elem.type === 1)
                elem.class=  "bookmark";
            elem.link= plElement.link;
			elem.title= plElement.label;
            if(plElement.urls !== undefined) {
                elem.children = generateTree(plElement.urls);
            }
            elem.noDragging = false;				
            elem.id = plElement.id;

            treeData.push(elem);
		});	
		
		return 	treeData;
	}



    class ApplicationFrame extends Component {   
        constructor(props) {
            super(props);
            var breadcrumbs = ["Home"];
			var height = window.innerHeight - 120;
            this.state = {
                isLoaded: false,
                badgeContent: 5,
                breadcrumbs: breadcrumbs,
                anchorEl: null,
                anchorElFlag: null,
                setAnchorEl: null,
                setAnchorElFlag: null,
                notification:false,
                logout: false,
				profil: false,
				checkedGerman: "fa fa-check",
				checkedEnglish: "fa ",
				tabValue: 0,
				height: height + "px",
                user: {},
                links: {},
                bookmarkLinks: {},
                jobList:{},
                pwdtype: "password",
                pwdclass: "fa fa-eye-slash",
                pwd1: "placeholder",               
                pwd2: "placeholder",
                newWidth: {}  ,
                didUpdate: false,
                tables: [],
                searchResult: [],
                anchorElmenuTable: null,
                userOnline: [],
                latestChanges: [],
                redirect: false,
                redirectLink: "",
                showGlobalSearch: false,
                bookmarkManagerOpen: false,
                treeData: [],
                searchStatus: "searching"
            }           
            this.handleBookmarkManager = this.handleBookmarkManager.bind(this);
            this.toggle = this.toggle.bind(this);
            this.searchGlobal = this.searchGlobal.bind(this);
            this.handleStateChange = this.handleStateChange.bind(this);
            this.handleClick = this.handleClick.bind(this);
            this.handleClose = this.handleClose.bind(this);
            this.handleClickFlag = this.handleClickFlag.bind(this);
            this.handleMenueSearchClick = this.handleMenueSearchClick.bind(this);
            this.handleMenueCloseSearchClick = this.handleMenueCloseSearchClick.bind(this);
            this.handleCloseFlag = this.handleCloseFlag.bind(this);
            this.handleNotificationClick = this.handleNotificationClick.bind(this);
            this.handleBookmarkClick = this.handleBookmarkClick.bind(this);
            this.handleLogout = this.handleLogout.bind(this);
            this.handleProfil = this.handleProfil.bind(this);
			this.handleChange = this.handleChange.bind(this);
			this.handleTabChange = this.handleTabChange.bind(this);
            this.updateDimensions = this.updateDimensions.bind(this);
            this.changeProfil = this.changeProfil.bind(this);
			this.onFileChange = this.onFileChange.bind(this);
            this.uploadPreview = this.uploadPreview.bind(this);
            this.showPWD = this.showPWD.bind(this);
            this.getAvatar = this.getAvatar.bind(this);
            this.deleteBookmark = this.deleteBookmark.bind(this);
            this.handleHomeClick = this.handleHomeClick.bind(this);
            this.handleReloadUserOnline = this.handleReloadUserOnline.bind(this);
            this.handleReloadLastChanges = this.handleReloadLastChanges.bind(this);
            this.openItem = this.openItem.bind(this);
            this.isAuth = this.isAuth.bind(this);
            this.menueVisible = this.menueVisible.bind(this);
            this.downloadClick = this.downloadClick.bind(this);
            this.setSearchTerm = this.setSearchTerm.bind(this);
            this.catchReturn = this.catchReturn.bind(this);
            this.handleSaveCloseBookmark = this.handleSaveCloseBookmark.bind(this);
        }

        handleSaveCloseBookmark() {
            axios.post(getAppUrl() + "/gatebackend/rest/settings/bookmarkManager", 
                    this.state.treeData
                ,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }
            ).then(repsonse => {
                this.props.enqueueSnackbar(this.props.t('Bookmarks gespeichert'), { variant: 'info' });
                this.setState({ bookmarkManagerOpen: false });
            }
                
            )
            .catch((error) => {
                console.log(error);
                alert("error");
            });

            this.setState({ show: false });
        }


        toggleNodeExpansion = expanded => {
            this.setState(prevState => ({
                treeData: toggleExpandedForAll({
                    treeData: prevState.treeData,
                    expanded,
                }),
            }));
        };
        
        handleBookmarkManager(visible) {
            if(visible === "open") {
                this.setState({
                    bookmarkManagerOpen: true
                });
            }
            if(visible === false) {
                this.setState({
                    bookmarkManagerOpen: false
                });
            }
        }


        catchReturn(ev) {
            if (ev.key === 'Enter') {
                this.searchGlobal();
                ev.preventDefault();
            }
        }

        setSearchTerm(e) {
            this.setState({
                searchTerm: e.target.value,
            });  
        }

        searchGlobal() {
            let sObj = {};
            sObj.searchTerm = this.state.searchTerm;
            this.props.history.push("/");
            this.setState({
                showGlobalSearch: true,
                searchResult: [],
                searchStatus: "searching"
            });             
            axios.post(getAppUrl() + '/gatebackend/rest/content/globalSearch', 
                sObj,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                let searchStatus = "finish";
                if(res.data.length === 0)
                    searchStatus = "emptyResult";
                
                this.setState({
                    showGlobalSearch: true, 
                    searchResult: res.data,
                    searchStatus: searchStatus
                });               
            });
        }

        downloadClick(obj) {
            let file = JSON.parse(obj.result);
            const downloadLink = document.createElement("a");
            downloadLink.href = file.url;
            downloadLink.download = file.filename;
            downloadLink.click();
        }

        openItem(itemId, tableId, maskId) {
            this.setState({redirect: true, 
                            redirectLink: "/maske/"+tableId+"/"+maskId+"/"+itemId});
        }


        handleReloadLastChanges() {
            axios.get(getAppUrl() + '/gatebackend/rest/settings/latestChanges', 
                {
                    headers: { 
                        "Authorization": " Bearer " + getToken()
                    }
                }).then(res => {
                    this.setState({
                        latestChanges: res.data
                    })
                    
                });
        }

        handleReloadUserOnline() {
            axios.get(getAppUrl() + '/gatebackend/rest/settings/userOnline', 
                {
                    headers: { 
                        "Authorization": " Bearer " + getToken()
                    }
                }).then(res => {
                    this.setState({
                        userOnline: res.data
                    })
                    
                });
        }

        handleHomeClick() { 
            this.props.history.push("/");
            this.setState({
                showGlobalSearch: false, 
            });             
        }
        
        getAvatar(userAlt) {
            let sResult = "";
            let user = this.state.user;
            if(userAlt !== undefined)
                user = userAlt;
            if(user.firstname !== "")
                sResult = user.firstname.charAt(0);
            if(user.lastname !== "")
                sResult += user.lastname.charAt(0);

            return sResult.toUpperCase();
        }

        showPWD(e) {
            let pwdtype = this.state.pwdtype;
            let pwdclass = "";
            if(pwdtype === "password") {
                pwdtype = "text";
                pwdclass= "fa fa-eye";
            }
            else {
                pwdtype = "password";
                pwdclass= "fa fa-eye-slash";

            }

            this.setState({
                pwdtype: pwdtype,
                pwdclass: pwdclass
            })
        }

    onFileChange(e) {
        this.setState({file:e.target.files[0]});
    }        

    async uploadPreview(e){
        e.preventDefault() 
        let res = await this.uploadFile(this.state.file, "preview");
    } 
        
    async uploadFile(file, type){
        const formData = new FormData();

        formData.append('file',file)

        return  await axios.post(getAppUrl() + "/gatebackend/rest/settings/uploadPreviewUser", formData,{
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": " Bearer " + getToken()
            }
        }).then(res => {
            let user = this.state.user;
            user.avatar = "/avatar/" + res.data.avatar;
            this.setState({
			    user: user
			})        
         });
    }        

    updateDimensions() {
			var elem = document.getElementById('root');	
			var height = window.innerHeight - 120;
			this.setState({
					height: height + "px"
			})
	}

		getData() {
            var token = getToken();
            if(token !== undefined && token !== "") {
                axios.get(getAppUrl() + '/gatebackend/rest/authentication/' + getToken(), 
                {
                    headers: { 
                        "Authorization": " Bearer " + getToken()
                    }
                }).then(res => {
                    axios.get(getAppUrl() + '/gatebackend/rest/settings/linksTree',
                    {
                        headers: { 
                            "Authorization": " Bearer " + getToken()
                        }
                    }).then(res2 => {

                        axios.get(getAppUrl() + '/gatebackend/rest/settings/companyInfo/' + getCompanyName(), 
                        ).then(res3 => {
                            this.setState({
                                isLoaded : true,
                                user: res.data,
                                links: res2.data,
                                companyInfo: res3.data
                            }) 

                        }).catch((error) => {

                        });
                    }).catch((error) => {
                    });
  
                }).catch((error) => {
		            localStorage.removeItem("app-token");
                    this.props.history.push("/login");
                });
		    }
        }

		handleTabChange(event, newValue) {
			this.setState({
				tabValue : newValue,
			})
    	}

        changeProfil(e) {
            let user = this.state.user;
            let pwd1 = this.state.pwd1;
            let pwd2 = this.state.pwd2;
            if(e.target.name === "username") {
                user.username = e.target.value;
            }            
            if(e.target.name === "firstname") {
                user.firstname = e.target.value;
            }
            if(e.target.name === "lastname") {
                user.lastname = e.target.value;
            }
            if(e.target.name === "email") {
                user.email = e.target.value;
            }
            if(e.target.name === "pwd1") {
                pwd1 = e.target.value;
            }
            if(e.target.name === "pwd2") {
                pwd2 = e.target.value;
            }

            this.setState({
                user: user,
                pwd1: pwd1,
                pwd2: pwd2
            });
        }

		handleChange(which, event) {

        }
        
        deleteBookmark(id) {
            axios.get(getAppUrl() + '/gatebackend/rest/settings/bookmarks/' + id,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res2 => {
                this.setState({
                    bookmark: false
                })  
            }).catch((error) => {
                
            });
        }

        handleLogout(kind) {
			if(kind === "logout") {
                axios.get(getAppUrl() + '/gatebackend/rest/authentication/logout/'+ getToken(), 
                {
                    headers: { 
                        "Authorization": " Bearer " + getToken()
                    }
                }
                ).then(repsonse => {
                    this.props.enqueueSnackbar(this.props.t('erfolgreich ausgeloggt'), { variant: 'info' });
                    localStorage.removeItem("app-token");
                    this.props.history.push("/login");
                });
            }
            else {
                this.setState({
                    logout: kind,
                    anchorEl: null
                })
            }
        }

        handleProfil(kind) {
            if(kind === "save") {
                let user = this.state.user;
                user.password = this.state.pwd1;
                axios.post(getAppUrl() + '/gatebackend/rest/settings/users/change', 
                    user
                ,
                {
                    headers: { 
                        "Authorization": " Bearer " + getToken()
                    }
                }
                ).then(repsonse => {
                    this.props.enqueueSnackbar(this.props.t('Ihr Profil wurde geändert'), { variant: 'info' });
                    this.setState({
                        profil: false,
                        anchorEl: null
                    })
                }
                    
                )
                .catch((error) => {
                    console.log(error);
                    alert("error");
                });
                
            }
            else {
                this.setState({
                    profil: kind,
                    anchorEl: null
                })
            }
        }


        handleNotificationClick(kind) {
            if(kind === "open") {
                axios.get(getAppUrl() + '/gatebackend/rest/settings/silentJobs',
                {
                    headers: { 
                        "Authorization": " Bearer " + getToken()
                    }
                }).then(res2 => {
                    this.setState({
                        notification: kind,
                        jobList: res2.data
                    })  
                }).catch((error) => {
                    
                });
            }
            else {
                this.setState({
                    notification: kind,
                    jobList: {}                 
                })                
            }
        }

        handleBookmarkClick(kind) {
            if(kind === "open") {
                axios.get(getAppUrl() + '/gatebackend/rest/settings/bookmarks',
                {
                    headers: { 
                        "Authorization": " Bearer " + getToken()
                    }
                }).then(res2 => {
                    let treeData = generateTree(res2.data);

                    this.setState({
                        bookmark: kind,
                        bookmarkLinks: res2.data,
                        treeData: treeData
                    })  
                }).catch((error) => {
                    
                });
            }
            else {
                this.setState({
                    bookmark: kind,
                    bookmarkLinks: {}                 
                })                
            }
        }

        handleClick(event) {
            this.setState({
                anchorEl: event.currentTarget,
            })
        }

        handleClose() {
              this.setState({
                anchorEl: null
            })
        }

        handleClickFlag(event) {
            this.setState({
                anchorElFlag: event.currentTarget,
            })
        }

        handleMenueSearchClick(event) {
            let x = event.currentTarget;
            axios.get(getAppUrl() + '/gatebackend/rest/settings/searchtables', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    anchorElmenuTable: x,
                    tables: res.data
                })                
            });            
        }

        handleMenueCloseSearchClick() {
            this.setState({
                anchorElmenuTable: null,
            })
        }        

        handleCloseFlag() {
              this.setState({
                anchorElFlag: null
            })
        }
        
        handleStateChange(breadcrumbs){
            this.setState({
                breadcrumbs: breadcrumbs
            })
        }

        componentDidUpdate() {
            if(this.state.didUpdate !== true) {
                axios.get(getAppUrl() + '/gatebackend/rest/settings/userOnline', 
                {
                    headers: { 
                        "Authorization": " Bearer " + getToken()
                    }
                }).then(res => {
                    axios.get(getAppUrl() + '/gatebackend/rest/settings/latestChanges', 
                    {
                        headers: { 
                            "Authorization": " Bearer " + getToken()
                        }
                    }).then(res1 => {
                        this.setState({
                            userOnline: res.data,
                            latestChanges: res1.data,
                            didUpdate: true
                        })   
                    }); 
                });
            }
        }

        componentDidMount() {
			const token = getToken();
        	this.getData();
            if(!token) {
                this.props.history.push('/Login');
            }
            window.addEventListener("resize", this.updateDimensions);
        }

        toggle(event) {  
            event.preventDefault();
        }

        menueVisible(menuEntry) {
            let group = this.state.user.group;
            let hasAccess = false;
            menuEntry.access.map(function(acs) {
                if(group === acs.name && (acs.right === "readOnly" || acs.right === "write"))
                    hasAccess = true;
            });
            return hasAccess;
        }

        isAuth(link) {
            let group = this.state.user.group;
            let hasAccess = false;
            this.state.links.map(function(node1) {
                if(node1.children) {
                    node1.children.map(function(node2) {
                        if(node2.children) {
                            node2.children.map(function(node3) {
                                if(node3.link === link) {
                                    node3.access.map(function(acs) {
                                        if(group === acs.name && (acs.right === "readOnly" || acs.right === "write"))
                                            hasAccess = true;
                                    });
                                }            
                            });
                        }
                    });
                }
            });
            if(this.state.user.id === 1)
                hasAccess = true;
            let elm = {};
            elm.isAuthenticated = hasAccess;
            return elm;
        };



        render() {
			const isLoaded = this.state.isLoaded;
            const user = this.state.user;
            const links = this.state.links;
            Moment.locale('de');
			const changeI18n = lng => event => {
                

				var nGerman;
				var nEnglish;
				if(lng === "de") {
					nGerman = "fa fa-check";
					nEnglish = "fa ";
				}
				else if(lng === "en") {
					nGerman = "fa ";
					nEnglish = "fa fa-check";
				}
				this.props.i18n.changeLanguage(lng);
				this.setState({
					anchorElFlag: null,
					checkedGerman: nGerman,
					checkedEnglish: nEnglish
				})
			};


            localStorage.setItem('app-breadcrumb', this.handleStateChange)



			const breadcrumbs = this.state.breadcrumbs;
			var parts = this.props.location.pathname.split("/")
			var firstUrlPart = parts[1];
			if(!isLoaded) {
                return (<div>loading</div>)
            }
            else if (this.state.redirect) {
                return <Redirect to={{
                    pathname: this.state.redirectLink,
                    state: { selections: undefined}
                    }}
                />;
            }
            else {

            	return(
                <div className="min-h-fullscreen">
                    <AlltoursIcons/>
                    <div className="applicationTopbar">
                        <div className="row">
                            <div className="col-md-3 col-lg-3 col-xl-2 disablePaddingRight">
                                <img src="/images/viva_logo.png"/>	
                            </div>
                            <div className="col-md-3 col-lg-3 col-xl-7 ">
                                <Paper className="searchRoot">
                                    <IconButton className="searchIconButton" aria-label="Menu" onClick={this.handleMenueSearchClick}>
                                        <MenuIcon />
                                    </IconButton>

                                    <Menu
                                        id="simple-menuTable "
                                        anchorEl={this.state.anchorElmenuTable}
                                        keepMounted
                                        open={Boolean(this.state.anchorElmenuTable)}
                                        onClose={this.handleMenueCloseSearchClick}
                                    >
                                    {this.state.tables.map(table => (
                                        <MenuItem onClick={changeI18n('de')}><span className={this.state.checkedGerman}></span>&nbsp;{table.name}</MenuItem>
                                    ))}                          
                                    </Menu>

                                    <InputBase
                                        onChange={e => this.setSearchTerm(e)}
                                        className="searchInput"
                                        onKeyPress={this.catchReturn}
                                        placeholder={this.props.t('Suchbegriff')}
                                        inputProps={{ 'aria-label': 'Suchbegriff' }}
                                    />
                                    <IconButton className="searchIconButton" 
                                                aria-label="Search"
                                                onClick={e => this.searchGlobal()}>
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </div>
                            <div className="col-md-2 col-lg-2 col-xl-3 disablePaddingLeft pull-right">
                                <div className="pull-right">
                                    <ul>
                                        <li className="fa  applicationTopbarItem">
                                            <Button className="buttonIcon" 
                                                    aria-controls="simple-menu" 
                                                    aria-haspopup="true" 
                                                    onClick={((e) => this.handleNotificationClick('open'))}>              
                                                <Badge badgeContent="new" color="primary">
                                                    <MailIcon />
                                                </Badge>                                                
                                            </Button>
                                        </li> 
                                        <li className="fa  applicationTopbarItem">

                                            <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.handleHomeClick('open'))}>              
                                                <span className="fa fa-home iconBig"></span>
                                            </Button>
                                        </li>                                          
										<li className="fa  applicationTopbarItem">
                                            <Button className="buttonIcon" aria-controls="simple-menuFlag" aria-haspopup="true" onClick={this.handleClickFlag}>
                                                <span className="fa fa-flag iconBig"></span>
                                            </Button>
                                            <Menu
                                                id="simple-menuFlag "
                                                anchorEl={this.state.anchorElFlag}
                                                keepMounted
                                                open={Boolean(this.state.anchorElFlag)}
                                                onClose={this.handleCloseFlag}
                                            >
											<MenuItem onClick={changeI18n('de')}><span className={this.state.checkedGerman}></span>&nbsp;Deutsch</MenuItem>
											<MenuItem onClick={changeI18n('en')}><span className={this.state.checkedEnglish}></span>&nbsp;English</MenuItem>
                                            </Menu>
                                        </li>
                                        <li className="fa  applicationTopbarItem">
                                            <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.handleBookmarkClick('open'))}>              
                                                <span className="fa fa-bookmark-o iconBig"></span>
                                            </Button>
                                        </li>
                                        <li className="fa  applicationTopbarItem">
                                            <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                                                <span className="fa fa-user iconBig"></span>
                                            </Button>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={this.state.anchorEl}
                                                keepMounted
                                                open={Boolean(this.state.anchorEl)}
                                                onClose={this.handleClose}
                                            >
                                                <MenuItem onClick={((e) => this.handleProfil(true))}><span className="fa fa-coq"></span>&nbsp;{this.props.t('Profile')}</MenuItem>
                                                <MenuItem onClick={((e) => this.handleLogout(true))}><span className="fa fa-power-off"></span>&nbsp;Logout</MenuItem>
                                            </Menu>
                                        </li>

                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row sidebar" style={{height: this.state.height}}     >
                        <div className="col-md-3 col-lg-3 col-xl-2 disablePaddingRight" >
                            <div className="applicationSidebar ">
                           
                                <div className="applicationSidebarNav">
                                    <ul className="menu">
                                        <li className="menu-item">
											<ExpansionPanel>
												<ExpansionPanelSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel2a-content"
												id="panel2a-header"
												>
												      
                                                {user.avatar === "" ? (
                                                    <>
                                                    <span className=" profilAvatarSmall" style={{backgroundColor: "#3f51b5", color: "white", paddingTop: "8px"}}>{this.getAvatar()}</span>
                                                    </>
                                                ) : (
                                                    <img className=" profilAvatarSmall" src={user.avatar}/>
                                                ) }                                                
											    <span className="MuiTypography-body1" style={{marginLeft: "10px", marginTop: "10px"}}>{user.firstname + " " + user.lastname}</span>
												</ExpansionPanelSummary>
												<ExpansionPanelDetails>
													<ul className="menu-submenu">
														<li className="menu-item">
															<span onClick={((e) => this.handleProfil(true))}  className="menu-link"  >
																<span className="title">{this.props.t('mein Profil')}</span>
																<span className="dot fa fa-circle-thin"></span>                                                    
															</span>
														</li>                                                    
														<li className="menu-item">
													
															<span onClick={((e) => this.handleLogout(true))}  className="menu-link"  >
																<span className="title">{this.props.t('Logout')}</span>
																<span className="dot fa fa-circle-thin"></span>                                                    
															</span>
														</li>	 
	 

                                            		</ul>
												</ExpansionPanelDetails>
											</ExpansionPanel>													
  										</li>

                     
                                        {links.map(lk1 => (  
                                            <>        
                                            {this.menueVisible(lk1) === true ? (
                                                <>
                                                <li className="menu-category">{lk1.title}</li> 
                                                    {lk1.children.map(lk2 => (
                                                        <>
                                                            {this.menueVisible(lk2) === true ? (
                                                                <li className="menu-item">
                                                                    <ExpansionPanel>
                                                                        <ExpansionPanelSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            >
                                                                            <Typography >{lk2.title}</Typography>
                                                                        </ExpansionPanelSummary>
                                                                        <ExpansionPanelDetails>
                                                                            <ul className="menu-submenu">
                                                                                {lk2.children.map(lk3 => (
                                                                                    <>
                                                                                    {this.menueVisible(lk3) === true ? (
                                                                                        <li className="menu-item">
                                                                                            {lk3.children.length > 0 ? (
                                                                                                <>
                                                                                                    <div className="menu-link" 
                                                                                                        style={{marginLeft: "10px"}}>{lk3.title}
                                                                                                    </div>
                                                                                                    {lk3.children.map(lk4 => (
                                                                                                        <>
                                                                                                            {this.menueVisible(lk4) === true ? (
                                                                                                                <Link to={{
                                                                                                                    pathname: lk4.link,
                                                                                                                    handleStateChange: this.handleStateChange
                                                                                                                }}  className="menu-link"  
                                                                                                                    style={{marginLeft: "20px"}}>
                                                                                                                    <span className="title">{lk4.title}</span>
                                                                                                                    <span className="dot fa fa-circle-thin"></span>                                                    
                                                                                                                </Link>
                                                                                
                                                                                                                ) 
                                                                                                                : (<></>)
                                                                                                            }
                                                                                                        </>
                                                                                                    ))}
                                                                                                </>
                                                                                                ) : (
                                                                                            <>
                                                                                                {this.menueVisible(lk3) === true ? (
                                                                                                    <Link to={{
                                                                                                        pathname: lk3.link,
                                                                                                        handleStateChange: this.handleStateChange
                                                                                                    }}  className="menu-link"  >
                                                                                                        <span className="title">{lk3.title}</span>
                                                                                                        <span className="dot fa fa-circle-thin"></span>                                                    
                                                                                                    </Link>
                                                                                                ) : (<></>)
                                                                                                }                                                                                    
                                                                                            </>
                                                                                        )}
                                                                                        </li>
                                                                                    ) : (<></>)}
                                                                                </>
                                                                            ))  }
                                                                        </ul>
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </li>
                                                ) : (<></>)}
                                                </>
                                            )) } 
                                             
</>) : (<></>)}


                                            </>
                                        )) }                                            
                                        										                                                                         
                                    </ul>
                                </div>
                               
                            </div>
                            <div className="applicationSidebarImg">
                            </div> 
                        </div>
                        <div className="col-md-7 col-lg-7 col-xl-10 disablePaddingLeft ">

                            <div className="applicationAction" id="actionFrame">
                            {firstUrlPart === "" && 
                            <>
								<Card >
                                    <CardActionArea>
                                        <CardMedia
                                            className="startPageTop"
                                            image={"/images/" + this.state.companyInfo.imageBig}
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Vivagate 10 für {this.state.companyInfo.label}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Willkommen beim neuen Vivagate, Ihre zentrale Anlaufstelle für Ihre Daten. Version 23.08.2020
                                        </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>

                                {this.state.showGlobalSearch === false ? (
                                    <Grid container  spacing={2} style={{marginTop: "25px"}}>
                                        <Grid item md={12}>
                                            <Grid container justify="center" spacing={2}>                                        
                                                <Grid style={{width: "33%", minHeight: "200px"}} key={1} item>
                                                    <Paper>
                                                        <div className="card-icon">
                                                            <span className="fa fa-database innerCenter"></span>
                                                        </div>
                                                        <span className="float-right headlineDashboard" > letzte Änderungen
                                                            <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.handleReloadLastChanges(e))}>              
                                                                <span className="fa fa-refresh iconBig"></span>
                                                            </Button>                                                    
                                                        </span>
                                                        <hr style={{marginLeft: "10px", marginRight: "10px"}}/>
                                                            <List>
                                                                {this.state.latestChanges && this.state.latestChanges.length > 0 ? (
                                                                    <>
                                                                { this.state.latestChanges.map(lchange => (
                                                                <ListItem >
                                                                    <ListItemAvatar>
                                                                    <Avatar className="classesAvatar">
                                                                        {
                                                                            lchange.prevImage !== null && lchange.prevImage !== "/" ? ( <img style={{width: "42px", height: "42px"}}src={"/imagepool" + lchange.prevImage}/>
                                                                            ) : (<LocalOfferIcon/>)
                                                                        }    
                                                                    </Avatar>
                                                                    </ListItemAvatar>
                                                                    
                                                                    <ListItemText primary={
                                                                        <div>
                                                                            <div>{lchange.msg + ": " + lchange.defaultName  }</div>
                                                                            <div className="MuiTypography-body2">{"Änderung am: " + Moment(lchange.changeDate).format('DD MM YYYY') }</div>
                                                                        </div>
                                                                        }
                                                                    />
                                                                    
                                                                    <ListItemSecondaryAction>
                                                                        <Link to={{
                                                                            pathname: "/maske/"+lchange.tableId+"/"+lchange.maskId+"/"+lchange.itemId,
                                                                            handleStateChange: this.handleStateChange
                                                                        }}    >
                                                                        <IconButton edge="end" aria-label="Open" >
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                        </Link>                                                                    
                                                                        
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                                ))}
                                                                </>) : (<><Loader/></>)
                                                                }
                                                            </List>  
                                                    </Paper>
                                                </Grid>
                                                <Grid style={{width: "33%", minHeight: "200px"}} key={1} item>
                                                    <Paper>
                                                        <div className="card-icon" style={{background: "linear-gradient(60deg, #66bb6a, #43a047)"}}>
                                                            <span className="fa fa-calendar-o innerCenter"></span>
                                                        </div>
                                                        <span className="float-right headlineDashboard" > Letzte Neuigkeiten</span>
                                                        <hr style={{marginLeft: "10px", marginRight: "10px"}}/>
                                                            <List>
                                                                <ListItem >
                                                                    <ListItemAvatar>
                                                                    <Avatar className="classesAvatar">
                                                                        <DoneIcon />
                                                                    </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary="Globale Suche fertig ;-)"/>
                                                                </ListItem>
                                                                <ListItem >
                                                                    <ListItemAvatar>
                                                                    <Avatar className="classesAvatar">
                                                                        <DoneIcon />
                                                                    </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary="Bookmarks fertig gestellt"/>
                                                                </ListItem>
                                                            </List>
                                                    </Paper>
                                                </Grid>
                                                <Grid style={{width: "33%", minHeight: "200px"}} key={1} item>
                                                    <Paper>
                                                        <div className="card-icon" style={{background: "linear-gradient(60deg, #ef5350, #e53935)"}}>
                                                            <span className="fa fa-users innerCenter"></span>
                                                        </div>
                                                        <span className="float-right headlineDashboard" > User online
                                                            <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.handleReloadUserOnline(e))}>              
                                                                <span className="fa fa-refresh iconBig"></span>
                                                            </Button>                                                    
                                                        </span>
                
                                                        <hr style={{marginLeft: "10px", marginRight: "10px"}}/>
                                                            <List>
                                                                {this.state.userOnline && this.state.userOnline.length > 0 ? (
                                                                    <>
                                                                { this.state.userOnline.map(user => (
                                                                <ListItem button onClick={() => this.handleListItemClick(user)} key={user}>
                                                                    <ListItemAvatar>
                                                                        {user.avatar === "" ? (
                                                                            <>
                                                                            <span className=" profilAvatarSmall" style={{backgroundColor: "#3f51b5", color: "white", paddingTop: "8px"}}>{this.getAvatar(user)}</span>
                                                                            </>
                                                                        ) : (
                                                                            <img className=" profilAvatarSmall" src={user.avatar}/>
                                                                        ) } 
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary={user.firstname + " " + user.lastname} />
                                                                </ListItem>
                                                                ))}
                                                                </>) : (<>

                                                                <ListItem >
                                                                    <ListItemAvatar>
                                                                    <Avatar className="classesAvatar">
                                                                        <PriorityHighIcon />
                                                                    </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary="Schade, aktuell sind Sie nur online"/>
                                                                </ListItem>
                                                                                    
                                                            </>)
                                                            }

                                                        </List>                                         
                                                    </Paper>
                                                </Grid>                                            
                                            </Grid>
                                        </Grid> 
                                    </Grid>  
                                ) : (<>
                                    <Grid container  spacing={2} style={{marginTop: "25px"}}>
                                        <Grid item md={12}>
                                            <Grid container justify="center" spacing={2}>                                        
                                                <Grid style={{width: "100%", minHeight: "200px"}} key={1} item>
                                                    <Paper>
                                                        <div className="card-icon">
                                                            <span className="fa fa-search innerCenter"></span>
                                                        </div>
                                                        <span className="float-right headlineDashboard" > Suche nach: "{this.state.searchTerm}"
                                                                                                                
                                                        </span>
                                                        <hr style={{marginLeft: "10px", marginRight: "10px"}}/>
                                                            <div style={{height: "600px", overflowY: "scroll"}}>
                                                                <List>
                                                                    {this.state.searchResult && this.state.searchResult.length > 0 ? (
                                                                        <>
                                                                    { this.state.searchResult.map(lchange => (
                                                                    <ListItem >
                                                                        <ListItemAvatar>
                                                                        <Avatar className="classesAvatar">
                                                                            {
                                                                                lchange.prevImage !== null && lchange.prevImage !== "/" ? ( <img src={"/imagepool" + lchange.prevImage}/>) : (<LocalOfferIcon/>)
                                                                            }
                                                                        </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText primary={
                                                                            <div>
                                                                                <div>{lchange.msg + ": " + lchange.defaultName  }</div>
                                                                                <div className="MuiTypography-body2">{lchange.searchResultTxt}</div>
                                                                            </div>
                                                                            }
                                                                        />
                                                                        <ListItemSecondaryAction>
                                                                            <Link to={{
                                                                                pathname: "/maske/"+lchange.tableId+"/"+lchange.maskId+"/"+lchange.itemId,
                                                                                handleStateChange: this.handleStateChange
                                                                            }}    >
                                                                            <IconButton edge="end" aria-label="Open" >
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                            </Link>                                                                    
                                                                            
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                    ))}
                                                                    </>) : (
                                                                        <>
                                                                            {this.state.searchStatus === "searching" ? (<><Loader/></>) : (<>Die Suche nach "{this.state.searchTerm}" führte zu keinem Ergebnis."</>)}
                                                                            
                                                                        </>
                                                                    )
                                                                    }
                                                                </List> 
                                                            </div>                                                                    
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                
                                
                                </>)
                                }
                            </>                             
							}
							{firstUrlPart !== "" && 
                                <Switch>
                                    <Route path='/maske/:tableId/:maskId'  component={VivaMask} />
                                    <Route path='/planing/:planingId'  component={PlaningDetails} />
                                    <Route path='/configureMaster/:itemId/:vivaId'  component={ConfigureMaster} />
                                    <Route path='/configureCar/:itemId'  component={ConfigureCar} />
                                    <Route path='/handleText/:itemId'  component={HandleText} />
                                    <Route path='/textExport'  component={ASTextExport} />
                                    <Route path='/languagesO'  component={ASLanguageOverview} />
                                    <Route path='/shopwareexport'  component={ShopwareExports} />

                                    <AuthenticatedRoute
                                        path="/planings"
                                        component={Planings}
                                        appProps={this.isAuth("/planings")}
                                    />
                                    <AuthenticatedRoute
                                        path="/ArticleClasses"
                                        component={ArticleClasses}
                                        appProps={this.isAuth("/ArticleClasses")}
                                    />                                    
                                    <AuthenticatedRoute
                                        path="/ClassColumns"
                                        component={ClassColumns}
                                        appProps={this.isAuth("/ClassColumns")}
                                    />                                    
                                    <AuthenticatedRoute
                                        path="/chooseList"
                                        component={ChooseList}
                                        appProps={this.isAuth("/chooseList")}
                                    />
                                    <AuthenticatedRoute
                                        path="/imports"
                                        component={Imports}
                                        appProps={this.isAuth("/imports")}
                                    />
                                    <AuthenticatedRoute
                                        path="/exports"
                                        component={Exports}
                                        appProps={this.isAuth("/exports")}
                                    />
                                    <AuthenticatedRoute
                                        path="/tables"
                                        component={Tables}
                                        appProps={this.isAuth("/tables")}
                                    />
                                    <AuthenticatedRoute
                                        path="/users"
                                        component={Users}
                                        appProps={this.isAuth("/users")}
                                    />
                                    <AuthenticatedRoute
                                        path="/groups"
                                        component={Groups}
                                        appProps={this.isAuth("/groups")}
                                    />
                                    <AuthenticatedRoute
                                        path="/masks"
                                        component={Masken}
                                        appProps={this.isAuth("/masks")}
                                    />
                                    <AuthenticatedRoute
                                        path="/menu"
                                        component={GateMenu}
                                        appProps={this.isAuth("/menu")}
                                    />                                    
                                    <AuthenticatedRoute
                                        path="/queries"
                                        component={Queries}
                                        appProps={this.isAuth("/queries")}
                                    />                                    
                                    <Route path='/dashboard'  component={Dashboard} />
                                </Switch>
							}

                            </div>
                             
                        </div>
                    </div>
                    <div className="applicationTopbar" style={{backgroundColor: "#000000"}}>
                    <div style={{width: "50%", margin: "0 auto", color: "white", textAlign: "center"}}>VivaGate 10 Copyright 1996 - 2020</div>
                    </div>
                    <Drawer anchor="right" open={this.state.notification} onClose={((e) => this.handleNotificationClick(false))} >
                        <div className="notificationFrame" >
                        <div className="notificationHeader">
                            <div className="notificationHeader">
                                <span className="fa fa-clock-o" style={{fontSize: "32px"}}/>
                                <span className="float-right">{this.props.t("JobResult")}</span>
                            </div>
                            <hr style={{marginLeft: "10px", marginRight: "10px"}}/>

                        </div>
                            <List>
                                {
                                this.state.jobList.length && this.state.jobList.map(li => (
                                <ListItem >
                                    <ListItemAvatar>
                                    <Avatar className="classesAvatar">
                                        <IconButton  onClick={((e) => this.downloadClick(li))}>
                                            <GetAppIcon />
                                        </IconButton>
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={li.name} />
                                    <ListItemSecondaryAction>
                                        <ConfirmAction  actionConfirm={((e) => this.deleteBookmark(li.id))} 
                                                        title="Job löschen?"
                                                        description="Möchten Sie den Job wirklich entfernen?"/>
                                    </ListItemSecondaryAction>
                                </ListItem>

                                ))                                                                                                        
                                }

                            </List>
                        </div>
                    </Drawer>
                    <Drawer anchor="right" 
                            open={this.state.bookmark} 
                            onClose={((e) => this.handleBookmarkClick(false))} 
                            >
                        <div className="notificationFrame" >
                            <div className="notificationHeader">
                                <span className="fa fa-bookmark-o" style={{fontSize: "32px"}} />
                                <span className="float-right">Bookmarks</span>
                            </div>
                            <Button className="buttonIcon float-right" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.handleBookmarkManager('open'))}>              
                                <span className="fa fa-cog "></span>
                            </Button>    
                                        <br/>            
                            <hr style={{marginLeft: "10px", marginRight: "10px"}}/>

                            
                            
                            {
                                this.state.bookmarkLinks.length && this.state.bookmarkLinks.map(folder => (
                                    <List>
                                    <span style={{paddingLeft: "5px"}}>{folder.label}</span>
                                    <hr style={{marginLeft: "20px", marginRight: "20px"}}/>
                                    { folder.urls.length && folder.urls.map(li => (
                                        <ListItem >
                                            <ListItemAvatar>
                                            <Avatar className="classesAvatar">
                                                <Link to={{
                                                    pathname: li.link,
                                                    handleStateChange: this.handleStateChange
                                                }}    >
                                                    <IconButton >
                                                        {
                                                            li.prevImage !== null && li.prevImage !== "/" && li.prevImage !== "" ? ( <img src={"/imagepool" + li.prevImage}/>) : (<LocalOfferIcon/>)
                                                        }
                                                    </IconButton>
                                                </Link>                                     
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={li.label} />
                                            <ListItemSecondaryAction>
                                                <ConfirmAction  actionConfirm={((e) => this.deleteBookmark(li.id))} 
                                                                title="Bookmark löschen?"
                                                                description="Möchten Sie den Bookmark wirklich entfernen?"/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        ))
                                    }
                                    </List>
                                ))                                                                                                        
                            }

                            
                        
                        </div>
                    </Drawer>
                    <Dialog
                            open={this.state.logout}
                            onClose={((e) => this.handleLogout(false))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">Logout</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Möchten Sie sich ausloggen?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handleLogout(false))} color="primary">
                            {this.props.t('Abbruch')}
                        </Button>
                        <Button onClick={((e) => this.handleLogout("logout"))} color="primary" autoFocus>
                            {this.props.t('OK')}
                        </Button>
                        </DialogActions>
                    </Dialog> 

                    <Dialog
                            fullWidth="md"
                            maxWidth="md"
                            open={this.state.profil}
                            onClose={((e) => this.handleProfil(false))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{this.props.t('Profil ändern')}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                           <div className="notificationHeader">
                            <AppBar position="static" color="default">
                                    <Tabs
                                    className="grid-Cell-Header"
                                    value={this.state.tabValue}
                                    onChange={this.handleTabChange}
                                    indicatorColor="secondary"
                                    textColor="white"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    >
										<Tab label="Allgemein" />
										<Tab label="Privat" />
										<Tab label="Benachrichtigungen" />

                                    </Tabs>
                            </AppBar>
							<div className="profilHeight">
								{this.state.tabValue === 0 && 
									<div className="row ">
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
											<div>
												<TextField
                                                    name="username"
													label={this.props.t('Benutzername')}											
													value={user.username}
													onChange={e => this.changeProfil(e)}
													margin="normal"
												/>
											</div>
											<div>
												<TextField
                                                    name="email"
													label={this.props.t('Email')}											
													value={user.email}
													onChange={e => this.changeProfil(e)}
													margin="normal"
												/>
											</div>
											<div>
												<TextField
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    name="Rolle"
													label={this.props.t('Rolle')}											
													value={user.role}
													margin="normal"
												/>
											</div>											
                                            <div>
												<TextField
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    name="Gruppe"
													label={this.props.t('Gruppe')}											
													value={user.group}
													margin="normal"
												/>
											</div>

										</div>
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
											<FormControl className="avProfilTop">      
												<InputLabel shrink htmlFor="age-label-placeholder">
													Avatar
												</InputLabel>
                                                {user.avatar === "" ? (
                                                    <>
                                                    <span className=" profilAvatar" style={{backgroundColor: "#3f51b5", color: "white"}}>{this.getAvatar()}</span>
                                                    </>
                                                ) : (
                                                    <img className=" profilAvatar" src={user.avatar}/>
                                                ) }                                                
											</FormControl>
                                            <form onSubmit={ this.onSubmit }>
                                                <input type="file" onChange={ this.onFileChange } />

                                                <Button variant="primary" onClick={this.uploadPreview}>
                                                ausführen
                                                </Button>
                                            </form>
										</div>
								</div>
								}
								{this.state.tabValue === 1 && 
									<div className="row ">
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
											<div>
												<TextField
                                                    name="pwd1"
													label={this.props.t('Passswort')}											
													value={this.state.pwd1}
													type={this.state.pwdtype}
													onChange={e => this.changeProfil(e)}
													margin="normal"
												>
                                                </TextField>
                                                <button className="unmask " type="button" style={{marginLeft: "-10px", marginTop: "30px"}} onClick={e => this.showPWD(e)}><i className={this.state.pwdclass}></i></button>
											</div>
											<div>
												<TextField
                                                    name="pwd2"
                                                    label={this.props.t('Passswort wiederholen')}											
													value={this.state.pwd2}
													type={this.state.pwdtype}
													onChange={e => this.changeProfil(e)}
													margin="normal"
												/>
											</div>
										</div>
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
											<div>
												<TextField
                                                    name="firstname"
													label={this.props.t('Vorname')}											
													value={user.firstname}
													onChange={e => this.changeProfil(e)}
													margin="normal"
												/>
											</div>
											<div>
												<TextField
                                                    name="lastname"
													label={this.props.t('Nachname')}											
													value={user.lastname}
													onChange={e => this.changeProfil(e)}
													margin="normal"
												/>
											</div>
										</div>                                        
									</div>
								}											
							</div>
						   </div>
						
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handleProfil(false))} color="primary">
                            {this.props.t('Abbruch')}
                        </Button>
                        <Button onClick={((e) => this.handleProfil("save"))} color="primary" autoFocus>
							{this.props.t('Speichern')}
                        </Button>
                        </DialogActions>
                    </Dialog> 

                    <Dialog
                            fullWidth="md"
                            maxWidth="md"
                            open={this.state.bookmarkManagerOpen}
                            onClose={((e) => this.handleBookmarkManager(false))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle className="dialogTitle" 
                                         disableTypography 
                                         id="form-dialog-title">{this.props.t('Bookmark Manager')}
                                        <IconButton edge="end" 
                                                    color="inherit" 
                                                    onClick={((e) => this.handleBookmarkManager(false))} 
                                                    aria-label="close">
                                            <CloseIcon />
                                        </IconButton>
                            </DialogTitle>                        
                        <DialogContent>
                            <div class="Vivacardheader">
                                <Button
                                    type="button"
                                    className="buttonIcon"
                                    style={{
                                        verticalAlign: 'middle',
                                    }}
                                    onClick={this.toggleNodeExpansion.bind(this, true)}
                                    >
                                    <span className="fa fa-expand "></span>
                                </Button>   
                                <Button
                                    type="button"
                                    className="buttonIcon"
                                    style={{
                                        verticalAlign: 'middle',
                                    }}
                                    onClick={this.toggleNodeExpansion.bind(this, false)}
                                    >
                                    <span className="fa fa-compress "></span>
                                </Button> 
                                                                                                                                                                                        
                            </div>                            
                            <div style={{height: "400px", overflow: "scroll"}}>
                                <SortableTree
                                        nodeContentRenderer={CustomNodeContentRenderer}
                                        treeData={this.state.treeData}
                                        onChange={treeData => this.setState({ treeData })}
                                        generateNodeProps={rowInfo => ({
                                        className: rowInfo.node.class,
                                        buttons: [                                            
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={(e) => this.handleDelete(rowInfo)}
                                                >
                                                <span className="fa fa-minus "></span>
                                            </Button>,                                           
                                        ],
                                })}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handleBookmarkManager(false))} color="primary">
                            {this.props.t('Abbruch')}
                        </Button>
                        <Button onClick={((e) => this.handleSaveCloseBookmark())} color="primary" autoFocus>
							{this.props.t('Speichern')}
                        </Button>
                        </DialogActions>
                    </Dialog> 

                </div>
			)
			}
        }
    }

export default withTranslation()(withSnackbar(ApplicationFrame)) ;