import React, { Component } from 'react';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import VivaGrid from './VivaGrid'
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import CSVDisplay from './CSVDisplay';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useSnackbar, withSnackbar } from 'notistack';
import PDFDisplay from './blocks/PDFDisplay';
import ExportFields from './ExportFields.js';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import QueryDetail from './QueryDetail.js';
import Loader from './blocks/Loader'
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import VivaMaskEingabe from './VivaMaskEingabe';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import SortableTree, { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath, getNodeAtPath, toggleExpandedForAll, defaultSearchMethod, map as mapTree } from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import CustomNodeContentRenderer from './blocks/CustomNode/CustomNodeContentRenderer';
import VivaGrid2 from './VivaGrid';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/core/Menu';
import {Route, Link } from "react-router-dom";

function generateSmallNodeMask(contentResultNodeMask, itemId, location, withName) {
	let sResult = "";
	contentResultNodeMask.mask.maskfields.map(function(mField) {
		if(mField.gravity === location) {
			if(withName)
				sResult += mField.name + ":";
			contentResultNodeMask.contentResult.gateContent.map(function(gC) {
				if(gC.itemID === itemId) {
					gC.contentFields.map(function(fC) {
						if(mField.elementValue == fC.fieldId) {
							if(mField.gravity === "bottom")
								sResult += fC.plainContent + " | ";
							else
								sResult += fC.plainContent;
						}
					})
				}
			});
		}
	});
	return sResult;
}

function searchItemValue(contentResultNodeMask, itemId, field) {
	let sResult = "";
	contentResultNodeMask.mask.maskfields.map(function(mField) {
		contentResultNodeMask.contentResult.gateContent.map(function(gC) {
			if(gC.itemID === itemId) {
				gC.contentFields.map(function(fC) {
					if(field == fC.fieldId) {
						sResult = fC.plainContent;
					}
				})
			}
		});
		
	});
	return sResult;
}

function getFormDetail(forms,fname) {
	let e = {};
	forms.map(function(form) {
		if(form.name == fname) {
			e.width = form.width;
			e.height = form.height;
		}
	})
	return e;
}

function generatePlanningMap(treeData, forms) {
//console.log(treeData);
	let page = {};
	let planningMap = [];
	let pageHeight = 0;
	let pageWidth = 0;
	let pageSide = "left";
	treeData.map(function(node) {
		if(node.type == "sub") {
			console.log("sub");
			node.children.map(function(node1) {
				if(node1.type == "item") {
					let items = [];
					let elem = {};
					node1.children.map(function(node2) {
						if(node2.type == "plItem") {
							elem = {};
							elem.form = node2.viva_form;
							let fD = getFormDetail(forms, node2.viva_form);
							elem.width = fD.width;
							elem.height = fD.height;
							elem.dbId = node2.dbId;
							let a = [];
							if(node2.viva_form != null && node2.viva_form != "")
								a = node2.viva_form.split(" ");
							let b = "";
							let v = "";
							if(a.length == 2) {
								b = a[0];
								v = a[1];
							}	
							elem.formVersion = v;						
							if(pageHeight == 0) {
								items.push(elem);
								pageHeight += fD.height;
							}							
							else if(pageHeight + elem.height < 100) {
								items.push(elem);
								pageHeight += fD.height;
							}
							else if (pageHeight + elem.height > 100) {
								page.side = pageSide;
								page.header = node1.subtitle;
								page.width = 240;
								page.height = 320;
								page.items = items;
								planningMap.push(page);	
								if(pageSide == "left")
									pageSide = "right";
								else	
									pageSide = "left";
								page = {};	
								items = [];
								pageHeight = elem.height;	
								items.push(elem);				
							}

						}
					})
					if(items.length > 0) {
						items.push(elem);
						page.side = pageSide;
						page.header = node1.subtitle;
						page.width = 240;
						page.height = 320;
						page.items = items;
						planningMap.push(page);	
						if(pageSide == "left")
							pageSide = "right";
						else	
							pageSide = "left";
						page = {};	
						items = [];
						pageHeight = 0;	
					}					
				
				}
			})
			
		}
	})
	return planningMap;
}

function setconvertJSON(c, t, l) {
	let tJ = JSON.parse(c);
	tJ[l].content = t;
	return JSON.stringify(tJ);
}

function convertJSON(c, l)  {
	let tJ = JSON.parse(c);
	return  tJ[l].content;
}

function generateTree(plElements, showSubPlaning) {
				let treeData = [];
			if(plElements.planingElemets !== undefined)
				plElements.plElemente = plElements.planingElemets;

			plElements.plElemente.map(function(plElement) {
				let elem = {};
				elem.isSelected = false;
				elem.expanded = true;
				elem.link= "";
				elem.merge= plElement.merge;
				elem.type= plElement.type;
				elem.class=plElement.type;
				elem.subPlaning = plElement.subPlaning;
				elem.subPlaningDetail = plElement.subPlaningDetail;
				elem.export = plElement.export.id;
				elem.title= plElement.subPlaningLabel;
				elem.plId = plElement.id;
				elem.maskId = plElement.maskId;
				elem.nodeMaskId = plElement.nodeMaskId;

				elem.queryId = plElement.query;
				
				elem.subTitle= plElement.type + plElement.id;
				if(plElement.type === "sub")
					elem.children = generateTree(plElement, showSubPlaning);
				else if(plElement.type === "query") {
					
					elem.children = generateTree(plElement, showSubPlaning);
				}
				else if(plElement.type === "item") {
					

					let childs = [];
					if(plElement.subPlaningDetail !== undefined && showSubPlaning === true) {
						let elem1 = {};
						elem1.expanded = true;
						elem1.isSelected = false;
						elem1.link= "";
						elem1.merge= plElement.subPlaningDetail.merge;
						elem1.subPlaning = plElement.subPlaning;
						elem1.type = "sub";
						elem1.class = "";
						elem1.export = plElement.export.id;
						elem1.title= plElement.subPlaningDetail.label;
						elem1.subTitle= "";
						elem1.children = generateTree(plElement.subPlaningDetail, showSubPlaning);
						childs.push(elem1);
					}
					else {
						elem.subtitle =  convertJSON(plElement.zusatz1, "de") + " "+ plElement.id;
						elem.sublabel =  convertJSON(plElement.zusatz1, "de");
						elem.sublabelJson =  plElement.zusatz1;
						elem.plElementId = plElement.id;
						elem.zusatz2 = plElement.zusatz2;
						elem.zusatz3 = plElement.zusatz3;
					}
					let prChilds = [];
					plElement.contentResultMask.contentResult.gateContent.map(function(result) {
						let refId = searchItemValue(plElement.contentResultNodeMask, result.itemID,840);
						if(refId == 0)
							prChilds = [];
						let elem1 = {};
						elem1.isSelected = false;
						elem1.link= "";
						elem1.type = "plItem";
						elem1.refId = refId;
						elem1.itemID = result.itemID; 
						elem1.classColumns = result.classColumns;
						elem1.title= result.dbId + generateSmallNodeMask(plElement.contentResultNodeMask, result.itemID, "top", false);
						elem1.viva_form = searchItemValue(plElement.contentResultNodeMask, result.itemID,823);
						elem1.viva_formz1 = searchItemValue(plElement.contentResultNodeMask, result.itemID,837);
						elem1.viva_formz2 = searchItemValue(plElement.contentResultNodeMask, result.itemID,839);
						elem1.viva_formz3 = searchItemValue(plElement.contentResultNodeMask, result.itemID,866);
						elem1.viva_formz4 = searchItemValue(plElement.contentResultNodeMask, result.itemID,867);
						elem1.viva_layout = searchItemValue(plElement.contentResultNodeMask, result.itemID,842);
						elem1.viva_relationship = searchItemValue(plElement.contentResultNodeMask, result.itemID,841);
						elem1.viva_formst_preis = searchItemValue(plElement.contentResultNodeMask, result.itemID,843);
						elem1.viva_markeLogo = searchItemValue(plElement.contentResultNodeMask, result.itemID,874);
						
						elem1.viva_formst_neu = searchItemValue(plElement.contentResultNodeMask, result.itemID,844);
						elem1.viva_formst_text1 = searchItemValue(plElement.contentResultNodeMask, result.itemID,845);
						elem1.viva_formst_text2 = searchItemValue(plElement.contentResultNodeMask, result.itemID,846);
						elem1.viva_formst_text3 = searchItemValue(plElement.contentResultNodeMask, result.itemID,847);

						elem1.viva_formst_textValue1 = searchItemValue(plElement.contentResultNodeMask, result.itemID,868);
						elem1.viva_formst_textValue2 = searchItemValue(plElement.contentResultNodeMask, result.itemID,869);
						elem1.viva_formst_textValue3 = searchItemValue(plElement.contentResultNodeMask, result.itemID,870);	

						elem1.viva_formst_textForm1 = searchItemValue(plElement.contentResultNodeMask, result.itemID,871);
						elem1.viva_formst_textForm2 = searchItemValue(plElement.contentResultNodeMask, result.itemID,872);
						elem1.viva_formst_textForm3 = searchItemValue(plElement.contentResultNodeMask, result.itemID,873);	

						elem1.viva_formst_iconPosition = searchItemValue(plElement.contentResultNodeMask, result.itemID,848);
						elem1.viva_pagebreak = searchItemValue(plElement.contentResultNodeMask, result.itemID,854);
						elem1.viva_tableDisplay = searchItemValue(plElement.contentResultNodeMask, result.itemID,877);
						elem1.viva_columnGroup = searchItemValue(plElement.contentResultNodeMask, result.itemID,878);

						if(result.prevImage !== null) {
							elem1.image= result.prevImage;
							elem1.class = ""; //plElement.contentResultMask.contentResult.meta.defaultIcon;
						}
						else {
							elem1.image= undefined;
							elem1.class = plElement.contentResultMask.contentResult.meta.defaultIcon;
						}
						elem1.subtitle = generateSmallNodeMask(plElement.contentResultNodeMask, result.itemID, "bottom", true);
						elem1.subTitle= result.itemID;
						elem1.dbId = result.dbId;
						if(refId == 0) {
							elem1.children = prChilds
							childs.push(elem1);
						}
						else
							prChilds.push(elem1);
					});
					elem.children = childs;
				}
				else 
					elem.children = [];

				elem.noDragging = false;				
				elem.id = plElement.id;

				treeData.push(elem);
			});	
		
		return 	treeData;
	}

class PlaningDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
			showSubPlaning: false,
			showEditPLElement: false,
			oldNode: undefined,
			oldPath: undefined,
			oldTreeIndex: undefined,			
			show: false,
			showLayout: false,
			exportShow: false,
			showQuery: false,
			exportStatus: 0,
			currentTableId: 0,
			currentExportId: 0,
			currentPlaningId: 0,
			queryId: 0,
			currentExportName: "",
			offset: 0,
			tabValue: 0,
			tabValueOverview: 0,
			isLoaded : false,
			ItemsDialog: false,
			productData: [],
			masks: [],
			planingElementId: 0,
			previewDocument: {},
			currentHighlightItemId: -1,
			currentPlaning: {},
			exportPlaning: false,
			waitCursor: true,
			bookmarklabel: "",
			bookmarkLinks: [],
			forms: [],
			bookmarkfolderId: 0,
			newElemChoice: "sub",	
			notification: false,
			elementlabel: "",
			layoutWidth: "100",
			layoutContent: "all",
			showLayoutDetail: false,
			maskEdit: false,
			currentItemID: 0,
			viva_formst_preis: "",
			viva_formst_neu: "",
			viva_formst_text1: "",
			viva_formst_text2: "",
			viva_formst_text3: "",
			viva_formst_iconPosition: "",
			viva_pagebreak: "",
			exportItem: -1,
			exportItems: -1,
			viva_formBase: "",
			viva_formVersion: "",
			PLElement_Zusatz2: "",
			PLElement_Zusatz3: "",
			formsVersion: [],
			formsBase: [],
			planningMap: [],
			currentPlElementId: 0,
			itemLanguage: "de",
			itemLanguages: [],
			anchorElFlag: null,
			confirmDialog: false,
			classColumns: [],
			treeData: [
				{ title: 'Chicken', children: [{ title: 'Egg' }] },
				{ title: 'Fish', children: [{ title: 'fingerline'}] }
			],
		};
        this.change = this.change.bind(this);

		this.handleShowQuery = this.handleShowQuery.bind(this);
		this.handleExportClose = this.handleExportClose.bind(this);
		this.handleExportShow = this.handleExportShow.bind(this);
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleCloseEditPLElement = this.handleCloseEditPLElement.bind(this);
		
		this.handleTabChange = this.handleTabChange.bind(this);
		this.gotoDetail = this.gotoDetail.bind(this);
		this.handleCloseItemDialog = this.handleCloseItemDialog.bind(this);
		this.handleInfo = this.handleInfo.bind(this);
		this.setCurrentItem = this.setCurrentItem.bind(this);
		this.reloadAction = this.reloadAction.bind(this);
		this.reloadPreview = this.reloadPreview.bind(this);
		this.handleExportPlaning = this.handleExportPlaning.bind(this);
		this.handleExportPlaningDetail = this.handleExportPlaningDetail.bind(this);
		
		this.handleShowSubPlaning = this.handleShowSubPlaning.bind(this);
		this.addBookmark = this.addBookmark.bind(this);
		this.handleFunctionClick = this.handleFunctionClick.bind(this);
		this.c = this.handleDeleteItem.bind(this);
		this.handleAddSubPlaning = this.handleAddSubPlaning.bind(this);
		this.handlePrepareAddSubPlaning = this.handlePrepareAddSubPlaning.bind(this);
		this.handlePrepareAddItems = this.handlePrepareAddItems.bind(this);
		this.handleChangeNewElem = this.handleChangeNewElem.bind(this);
		this.handleMousemove.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.handlemaskEditClick = this.handlemaskEditClick.bind(this);
		this.handleAddItems = this.handleAddItems.bind(this);
		this.changeElementlabel = this.changeElementlabel.bind(this);
		this.changeElementSubLabel = this.changeElementSubLabel.bind(this);
		
		this.handleEditItemClick = this.handleEditItemClick.bind(this);
		this.handleEditPLElementClick = this.handleEditPLElementClick.bind(this);
		this.handleCloseLayout = this.handleCloseLayout.bind(this);
		this.handleChangeLayoutItem = this.handleChangeLayoutItem.bind(this);
		this.handleChangeForm = this.handleChangeForm.bind(this);
		this.handleCloseLayoutDetail = this.handleCloseLayoutDetail.bind(this);
		this.handleChangeLayoutItemDetail = this.handleChangeLayoutItemDetail.bind(this);
		this.handleSavePlaning = this.handleSavePlaning.bind(this);
		this.handlePDFItemClickDetail = this.handlePDFItemClickDetail.bind(this);
		this.handleClickFlag = this.handleClickFlag.bind(this);
		this.handleCloseFlag = this.handleCloseFlag.bind(this);	
		this.changeItemLanguage = this.changeItemLanguage.bind(this);	
		this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);

		this.handleChangeLayoutPLElement = this.handleChangeLayoutPLElement.bind(this);
	}
	handleSearchOnChange(e){
    	this.setState({
             searchQuery: e.target.value,
        });
	};
	  
	changeItemLanguage(language) {
		this.setState({
			itemLanguage: language,
			anchorElFlag: null
		})
	}

	handleCloseFlag() {
		this.setState({
			anchorElFlag: null
		})
	}	
	
	handleClickFlag(event) {
		this.setState({
			anchorElFlag: event.currentTarget,
		})
	}	            
	
	handleSavePlaning() {
        axios.post(getAppUrl() + "/gatebackend/rest/settings/savePlaning", 
	       this.state.treeData,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }      
        ).then(repsonse => {
			this.props.enqueueSnackbar(this.props.t('Planung gespeichert'), { variant: 'info' });
			this.reload(this.props.match.params.planingId);
			
        }
            
        )
        .catch((error) => {
            alert("error");
        });
    }

	handleAddItems() {
		alert("hier");
	}


	handlemaskEditClick(kind) {
        this.setState({
            maskEdit: kind
        })       
	}
	
	handleNotificationClick(kind) {
        this.setState({
            notification: kind
        })       
    }
    handleMousedown = e => {
        this.setState({ isResizing: true, lastDownX: e.clientX });
	};
	
	handleMousemove = e => {
        // we don't want to do anything if we aren't resizing.
        if (!this.state.isResizing) {
            return;
        }

        let offsetRight =
        document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        let minWidth = 400;
        let maxWidth = 1400;
        if (offsetRight > minWidth && offsetRight < maxWidth) {
        this.setState({ newWidthLinkItems: { width: offsetRight },
                        currentMaskWidthLink: offsetRight });
        }
	};
	
    handleMouseup = e => {
        this.setState({ isResizing: false });
	};

	handleChangeForm(e) {
		if(e.target.name == "viva_formst_preis") {
			this.setState({
				viva_formst_preis : e.target.value,
			})
		}	
		
		if(e.target.name == "viva_tableDisplay") {
			this.setState({
				viva_tableDisplay : e.target.value,
			})
		}
		if(e.target.name == "viva_columnGroup") {
			this.setState({
				viva_columnGroup : e.target.value,
			})
		}		
		if(e.target.name == "viva_markeLogo") {
			this.setState({
				viva_markeLogo : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_neu") {
			this.setState({
				viva_formst_neu : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_text1") {
			this.setState({
				viva_formst_text1 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_text2") {
			this.setState({
				viva_formst_text2 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_text3") {
			this.setState({
				viva_formst_text3 : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_textForm1") {
			this.setState({
				viva_formst_textForm1 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_textForm2") {
			this.setState({
				viva_formst_textForm2 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_textForm3") {
			this.setState({
				viva_formst_textForm3 : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_textValue1") {
			this.setState({
				viva_formst_textValue1 : e.target.value,
			})
		}	
		if(e.target.name == "viva_formst_textValue2") {
			this.setState({
				viva_formst_textValue2 : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_textValue3") {
			this.setState({
				viva_formst_textValue3 : e.target.value,
			})
		}				
		if(e.target.name == "viva_formst_iconPosition") {
			this.setState({
				viva_formst_iconPosition : e.target.value,
			})
		}		
		if(e.target.name == "viva_pagebreak") {
			this.setState({
				viva_pagebreak : e.target.value,
			})
		}		
		
		if(e.target.name == "viva_formBase") {
			this.setState({
				viva_form: e.target.value + " " + this.state.viva_formVersion,
				viva_formBase : e.target.value,
			})
		}
		if(e.target.name == "viva_formVersion") {
			this.setState({
				viva_formVersion : e.target.value,
				viva_form: this.state.viva_formBase + " " + e.target.value,
			})
		}
		if(e.target.name == "viva_formz1") {
			this.setState({
				viva_formz1 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formz2") {
			this.setState({
				viva_formz2 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formz3") {
			this.setState({
				viva_formz3 : e.target.value,
			})
		}
		if(e.target.name == "viva_formz4") {
			this.setState({
				viva_formz4 : e.target.value,
			})
		}		
		if(e.target.name == "viva_relationship") {
			this.setState({
				viva_relationship : e.target.value,
			})
		}
		if(e.target.name == "viva_layout") {
			this.setState({
				viva_layout : e.target.value,
			})
		}						
	}

	handleChangeNewElem(e) {
		this.setState({
			newElemChoice : e.target.value,
		})
	}

	changeElementlabel(e) {
		this.setState({
			elementlabel : e.target.value,
		})
	}

	changeElementSubLabel(e) {
		this.setState({
			PLElement_SubLabel : e.target.value,
		})
	}

	changeElementZusatz2(e) {
		this.setState({
			PLElement_Zusatz2 : e.target.value,
		})
	}


	changeElementZusatz3(e) {
		this.setState({
			PLElement_Zusatz3: e.target.value,
		})
	}	

	change(e) { 
        var select = this.state.select;
        if(e.target.name === "bookmarklabel") {
            this.setState({
                bookmarklabel : e.target.value,
            })
		}
        if(e.target.name === "bookmarkfolder") {
            this.setState({
                bookmarkfolderId :e.target.value,
            })
        }		
	}
	addBookmark(kind) {
		var k = true;
		if(kind === 'true') {
			axios.get(getAppUrl() + '/gatebackend/rest/settings/bookmarks',
			{
				headers: { 
					"Authorization": " Bearer " + getToken()
				}
			}).then(res2 => {
				this.setState({
					bookmarkOpen: kind,
					bookmarkLinks: res2.data
				})  
			}).catch((error) => {
				
			});
		}
		else {
			if(kind === 'false')
				k = false;
			if(kind === 'add') {
				let bookmark = {};
				bookmark.label = this.state.bookmarklabel;
				bookmark.link = "/planing/"+ this.props.match.params.planingId ;
				bookmark.itemId = 0;
				bookmark.folder = parseInt(this.state.bookmarkfolderId);
				axios.post(getAppUrl() + '/gatebackend/rest/settings/bookmarks/add', 
					bookmark
				,
				{
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}
				).then(repsonse => {
				this.props.enqueueSnackbar(this.props.t('Bookmark hinzugefügt'), { variant: 'info' });
				}
					
				)
				.catch((error) => {
					console.log(error);
					alert("error");
				});
				k = false;
			}
			this.setState({
				bookmarkOpen : k
			})  

		}
	}	
	handleExportPlaningDetail(rowInfo) {   
		let {node, treeIndex, path} = rowInfo;	
		this.setState({
				exportItem: node.dbId,
				rowInfo: rowInfo,
				exportPlaning: true,
				exportStatus: 1			
		})
	}

	handlePDFItemClickDetail(rowInfo) {   
		let {node, treeIndex, path} = rowInfo;	
		if(node.type === "plItem") {		
			this.setState({
					tabValue: 2,
					exportItem: node.dbId,
					exportItems: -1,
					rowInfo: rowInfo,
			})
		}
		if(node.type === "item") {		
			this.setState({
					tabValue: 2,
					exportItem: -1,
					exportItems: node.plElementId,
					rowInfo: rowInfo,
			})
		}
	}

	handlePrepareAddItems(rowInfo) { 
		let {node, treeIndex, path} = rowInfo;			     
		let nw = {width: 1200};
		this.setState({
			newWidthLinkItems: nw,
			notification: true,
			rowInfo: rowInfo,
			currentPlElementId: node.plElementId
		})
	}
	

	handleEditPLElementClick(rowInfo) {   
		let {node, treeIndex, path} = rowInfo;
		this.setState({
			showEditPLElement: true,
			rowInfo: rowInfo,
			PLElement_SubLabel: node.sublabel,
			PLElement_SubLabelJson: node.sublabelJson,
			PLElement_Zusatz2: node.zusatz2,
			PLElement_Zusatz3: node.zusatz3,
			viva_relationship: node.viva_relationship,
		});
	}

	handleEditItemClickDetail(rowInfo) {   
		let {node, treeIndex, path} = rowInfo;
		this.setState({
			showLayoutDetail: true,
			rowInfo: rowInfo,
			viva_layout: node.viva_layout,
			viva_relationship: node.viva_relationship,
		});
	}

	handleEditItemClick(rowInfo) {   
		let {node, treeIndex, path} = rowInfo;
		let a = [];
		if(node.viva_form != null && node.viva_form != "")
			a = node.viva_form.split(" ");
		let b = "";
		let v = "";
		if(a.length == 2) {
			b = a[0];
			v = a[1];
		}
		this.setState({
			showLayout: true,
			rowInfo: rowInfo,
			viva_formBase: b,
			viva_formVersion : v,
			viva_form: node.viva_form,
			viva_formz1: node.viva_formz1,
			viva_formz2: node.viva_formz2,
			viva_formz3: node.viva_formz3,
			viva_formz4: node.viva_formz4,
			viva_formst_preis: node.viva_formst_preis,
			viva_tableDisplay: node.viva_tableDisplay,
			classColumns: node.classColumns,
			viva_columnGroup: node.viva_columnGroup,			
			viva_formst_neu: node.viva_formst_neu,
			viva_markeLogo: node.viva_markeLogo,
			viva_formst_text1: node.viva_formst_text1,
			viva_formst_text2: node.viva_formst_text2,
			viva_formst_text3: node.viva_formst_text3,
			viva_formst_textForm1: node.viva_formst_textForm1,
			viva_formst_textForm2: node.viva_formst_textForm2,
			viva_formst_textForm3: node.viva_formst_textForm3,
			viva_formst_textValue1: node.viva_formst_textValue1,
			viva_formst_textValue2: node.viva_formst_textValue2,
			viva_formst_textValue3: node.viva_formst_textValue3,
			viva_tableDisplay: node.viva_tableDisplay,
			
						
			viva_formst_iconPosition: node.viva_formst_iconPosition,
			viva_pagebreak: node.viva_pagebreak
		});

	}	

	handleChangeLayoutPLElement() { 
		let {node, treeIndex, path} = this.state.rowInfo;


		let smallPlanung = [];
		let elem = {};
		elem.type = "item";
		elem.sticky = "saveElement";
		elem.plElementId = node.plElementId;
		elem.zusatz1 =  setconvertJSON(this.state.PLElement_SubLabelJson, this.state.PLElement_SubLabel, "de");

		elem.zusatz2 = this.state.PLElement_Zusatz2;
		elem.zusatz3 = this.state.PLElement_Zusatz3;
		elem.children = [];
		elem.children.push(node);
		smallPlanung.push(elem);
        axios.post(getAppUrl() + "/gatebackend/rest/settings/savePlaningItem", 
                smallPlanung
            ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
			let planningMap = generatePlanningMap(this.state.treeData, this.state.formsOrig);

			this.props.enqueueSnackbar(this.props.t('PlanungsItem Layout gespeichert'), { variant: 'info' });
			this.setState({ showEditPLElement: false });
			this.reload(this.props.match.params.planingId);

		}
            
        )
        .catch((error) => {
            alert("error");
        });    

	}	

	handleChangeLayoutItem() { 
		let {node, treeIndex, path} = this.state.rowInfo;
		node.viva_form = this.state.viva_form;

		node.viva_formz1 = this.state.viva_formz1;
		node.viva_formz2 = this.state.viva_formz2;
		node.viva_formz3 = this.state.viva_formz3;
		node.viva_formz4 = this.state.viva_formz4;
		node.viva_formst_preis =  this.state.viva_formst_preis;
		node.viva_formst_neu	= this.state.viva_formst_neu;
		node.viva_markeLogo = this.state.viva_markeLogo;
		node.viva_formst_text1	= this.state.viva_formst_text1;
		node.viva_formst_text2	= this.state.viva_formst_text2;
		node.viva_formst_text3	= this.state.viva_formst_text3;
		node.viva_formst_textValue1	= this.state.viva_formst_textValue1;
		node.viva_formst_textValue2	= this.state.viva_formst_textValue2;
		node.viva_formst_textValue3	= this.state.viva_formst_textValue3;
		node.viva_formst_textForm1	= this.state.viva_formst_textForm1;
		node.viva_formst_textForm2	= this.state.viva_formst_textForm2;
		node.viva_formst_textForm3	= this.state.viva_formst_textForm3;
		node.viva_tableDisplay	= this.state.viva_tableDisplay;
		node.classColumns = this.state.classColumns;
		node.viva_columnGroup	= this.state.viva_columnGroup;
		node.viva_formst_iconPosition= this.state.viva_formst_iconPosition;
		node.viva_pagebreak = this.state.viva_pagebreak;

		let smallPlanung = [];
		let elem = {};
		elem.type = "item";
		elem.sticky = "noSave";
		elem.children = [];
		elem.children.push(node);
		smallPlanung.push(elem);
        axios.post(getAppUrl() + "/gatebackend/rest/settings/savePlaningItem", 
                smallPlanung
            ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
			let planningMap = generatePlanningMap(this.state.treeData, this.state.formsOrig);

            this.props.enqueueSnackbar(this.props.t('PlanungsItem Layout gespeichert'), { variant: 'info' });
			this.setState({
				showLayout: false,
				planningMap: planningMap
			});

		}
            
        )
        .catch((error) => {
            console.log(error);
            alert("error");
        });
    

	}
	
	handleChangeLayoutItemDetail() { 
		let {node, treeIndex, path} = this.state.rowInfo;
		node.viva_relationship = this.state.viva_relationship;
		node.viva_layout = this.state.viva_layout;
		this.setState({
			showLayoutDetail: false,
		});
    }	
	
	handleCloseLayout() {      
		this.setState({
			showLayout: false,
		});
	}
	
	handleCloseLayoutDetail() {      
		this.setState({
			showLayoutDetail: false,
		});
	}
	
	

	handlePrepareAddSubPlaning(rowInfo) {      
		this.setState({
			show: true,
			rowInfo: rowInfo
		});	
    }

	handleAddSubPlaning() {
		let newCounter = this.state.counter;
		let rowInfo = this.state.rowInfo;
		let newElemChoice = this.state.newElemChoice;
		let label = this.state.elementlabel;
        let NEW_NODE = {title: label, noDragging: false, id: newCounter--, class: newElemChoice, type: newElemChoice};
        let {node, treeIndex, path} = rowInfo;
        path.pop();
        let parentNode = getNodeAtPath({
            treeData: this.state.treeData,
            path : path,
            getNodeKey: ({ treeIndex }) =>  treeIndex,
            ignoreCollapsed : true
        });
        let getNodeKey = ({ node: object, treeIndex: number }) => {
            return number;
        };
        let parentKey = getNodeKey(parentNode);
        if(parentKey == -1) {
            parentKey = null;
        }
        let newTree = addNodeUnderParent({
                treeData: this.state.treeData,
                newNode: NEW_NODE,
                expandParent: true,
                parentKey: parentKey,
                getNodeKey: ({ treeIndex }) =>  treeIndex
         });
         this.setState({treeData: newTree.treeData,
                        show: false,
                        currentNode: NEW_NODE,
                        counter: newCounter
        });
    }


	handleShowSubPlaning() {
		let showSubPlaning = this.state.showSubPlaning;
		if(showSubPlaning === true)
			showSubPlaning = false;
		else 
			showSubPlaning = true;
		let treeData = [];
		treeData = generateTree(this.state.posts, showSubPlaning);

		this.setState({
			treeData : treeData,
			showSubPlaning: showSubPlaning
		})  
	}

	handleShowQuery(visible, id) {
		if(visible === true)
	        this.setState({ queryId: id,
							showQuery: true });
		else
	        this.setState({ showQuery: false });

	}

    handleExportClose() {
        this.setState({ exportShow: false });
    }

    handleExportShow(id) {        
        this.setState({ 
			currentExportId : id,
			
			exportShow: true 
		});
    }

	handleExportPlaning(visible) {
		if(visible === true) {
			this.setState({ exportPlaning: true,
							exportStatus: 1 })
		}
		else {
			this.setState({ exportPlaning: false,
							exportStatus: 0 })
		}
	}

	setCurrentItem (itemId) {
		this.setState({ currentHighlightItemId: itemId })
	}

	handleInfo(id) {
		alert("hier");
	}

    handleCloseItemDialog() {
        this.setState({
            ItemsDialog : false,
        })  
	}
	
	gotoDetail(id) {
		this.props.history.push("/planing/" + id);
	}

	handleTabChange(event, newValue) {
		this.setState({
			tabValue : newValue,
			exportItem: -1,
			exportItems: -1
		})
	}
		
   	componentDidMount() {
       	document.addEventListener("mousemove", e => this.handleMousemove(e));
        document.addEventListener("mouseup", e => this.handleMouseup(e));
		this.reload(this.props.match.params.planingId);

	}
	
	reloadAction() {
		this.reloadPreview(this.props.match.params.planingId);
	}

	reloadPreview() {
		let maskID = this.state.currentPlaning.node.maskId;
		let planingElementId = this.state.currentPlaning.node.plId;
		let offset = 0;
		let orderField = "";
		let searchTerm = "";
		let modus = "";

		let getURL = getAppUrl() + '/gatebackend/rest/content/mask/'+ maskID +'/planingElementPreview/' + planingElementId +'?offset='+ offset +'&count=15&orderField='+orderField+'&searchTerm='+searchTerm+'&modus='+modus;		
		axios.get(getURL, 
		{
			headers: { 
				"Authorization": " Bearer " + getToken()
			}
		}).then(res => {
			this.setState({
				tabValue : 1,
				previewDocument: res.data
			})
		});
	}	

   	reload(id) {
		axios.get(getAppUrl() + '/gatebackend/rest/content/languages',  {
				headers: { 
					"Authorization": " Bearer " + getToken()
				}
			}).then(res4 => {

				axios.get(getAppUrl() + '/gatebackend/rest/settings/forms', 
					{
						headers: { 
							"Authorization": " Bearer " + getToken()
						}
					}).then(res3 => {	
						console.log(res3.data);
						console.log("Forms------");	   
						axios.get(getAppUrl() + '/gatebackend/rest/settings/masks', 
							{
								headers: { 
									"Authorization": " Bearer " + getToken()
								}
							}).then(res => {
								axios.get(getAppUrl() + '/gatebackend/rest/settings/queries', 
								{
									headers: { 
										"Authorization": " Bearer " + getToken()
									}
								}).then(res1 => {

									axios.get(getAppUrl() + '/gatebackend/rest/settings/planing/' + id, 
									{
										headers: { 
											"Authorization": " Bearer " + getToken()
										}
									}).then(res2 => {
										let treeData = [];
										console.log("=======");
										console.log(res2.data);
										console.log("=======");
										treeData = generateTree(res2.data, this.state.showSubPlaning);
										let elem = {};
										elem.node = treeData[0];
										let planningMap = generatePlanningMap(treeData, res3.data);
										let forms = [];
										let bases = {};
										let versions = [];
										let oB = "";
										res3.data.map(function(ex) {
											let a = ex.name.split(" ");
											let b = a[0];
											let v = a[1];
											if(oB === "")
												oB = b;
											if(oB != b) {
												bases[oB] = versions;
												forms.push(oB);
												versions = [];
											}
											versions.push(v);
											oB = b;
										})
										forms.push(oB);
										bases[oB] = versions;

										this.setState({
											isLoaded : true,
											treeData : treeData,
											currentPlaning: elem,
											posts: res2.data,
											masks: res.data,
											queries: res1.data,
											forms: forms,
											formsVersion: versions,
											formsBase: bases,
											planningMap: planningMap,
											itemLanguages: res4.data,
											formsOrig: res3.data
										})   
								}); 
							});	 
						});
					});
				});
	}	

    componentDidUpdate(prevProps) {
        if(this.props.match.params.planingId !== prevProps.match.params.planingId) {
            this.reload(this.props.match.params.planingId)
        }
    }

    handleCloseEditPLElement() {
        this.setState({ showEditPLElement: false });
    }
    handleClose() {
        this.setState({ show: false });
    }

    handleShow(e) {
        this.setState({currentTableId : e.currentTarget.dataset.id});
        this.setState({currentTableName : e.currentTarget.dataset.tablename});
        
        this.setState({ show: true });
    }

    toggleNodeExpansion = expanded => {
        this.setState(prevState => ({
			treeData: toggleExpandedForAll({
				treeData: prevState.treeData,
				expanded,
			}),
        }));
	};

	handleConfirmDialog(s) {
		if(s == "confirm") {
			let {node, treeIndex, path} = this.state.rowInfo;
			node.markAsDelete = "true";

			let smallPlanung = [];
			let elem = {};
			elem.type = "item";
			elem.sticky = "noSave";
			elem.children = [];
			elem.children.push(node);
			smallPlanung.push(elem);
			axios.post(getAppUrl() + "/gatebackend/rest/settings/savePlaningItem", 
				smallPlanung
				,
			{
				headers: { 
					"Authorization": " Bearer " + getToken()
				}
			}
			).then(repsonse => {
				const newTree = removeNodeAtPath({
					treeData: this.state.treeData,
					path,
					getNodeKey: ({ treeIndex }) => treeIndex,
			
				});

				let planningMap = generatePlanningMap(this.state.treeData, this.state.formsOrig);
		
				this.props.enqueueSnackbar(this.props.t('PlanungsItem gelöscht'), { variant: 'info' });
				this.setState({
					showLayout: false,
					planningMap: planningMap,
					treeData: newTree,
					confirmDialog: false
				});

			}
				
			)
			.catch((error) => {
				console.log(error);
				alert("error");
			});

		}
		else {
			this.setState({
				confirmDialog: false
			});
		}
	}

	handleDeleteItem(rowInfo) {

		this.setState({
			rowInfo: rowInfo,
			confirmDialog: true
		});

	}

	handleFunctionClick(rowInfo) {   
		let {node, treeIndex, path} = rowInfo;   
		let nw = {width: 1200};
		this.setState({
			newWidthLinkItems: nw,
			maskEdit: true,
			currentItemID: node.itemID
		})
	}

	handleNodeClick(rowInfo, e) {
		if(e._dispatchListeners.length === 1) {
			if(rowInfo.node.type !== "plItem") {
				let {node, treeIndex, path} = rowInfo;
				let oldNode = this.state.oldNode;
				let oldPath = this.state.oldPath;
				let oldTreeIndex = this.state.oldTreeIndex;
				if(oldNode !== undefined) {
					oldNode.isSelected = false;
					if (oldNode.class.indexOf("selectedNode") !== -1) { 
						oldNode.class = oldNode.class.replace('selectedNode','');
					}
					changeNodeAtPath({
							treeData: this.state.treeData,
							path: oldPath,
							newNode: oldNode,
							getNodeKey: ({ treeIndex }) =>  treeIndex,
							ignoreCollapsed: false,
					});
				}
				node.class = node.class + " selectedNode";
				node.isSelected = true;
				const treeData = changeNodeAtPath({
						treeData: this.state.treeData,
						path: path,
						newNode: node,
						getNodeKey: ({ treeIndex }) =>  treeIndex,
						ignoreCollapsed: false,
				});

				this.setState({treeData,
					oldNode: node,
					oldPath: path,
					oldTreeIndex: treeIndex,
					currentPlaning: rowInfo
				});
			}
		}
  	}

    render() {
		const {isLoaded, posts} = this.state;

		const handleOpenItemDialog = id => event => {
			this.setState({
				ItemsDialog: true,
				planingElementId: id
			});		
		}
		
		const handleChange = id => event => {
			var posts = this.state.posts;
			posts.planingElemets.map(function(planingElement) {
				if(planingElement.id == id ) {
					if(event.target.name === "export")
						planingElement.export.id = event.target.value;
					if(event.target.name === "subplaning")
						planingElement.subPlaning = event.target.value;
				}
			});
			this.setState({
				posts: posts
			});
		};


        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {
			const planingElement = this.state.posts.planingElemets[0];

            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'height': elHeight + 'px',
            };
            return (
                <>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">
                           {this.props.t('Details ')}:&nbsp;{posts.label} 
							<Button className="buttonIcon pull-right" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.addBookmark('true'))}>
								<span className="fa fa-bookmark-o"></span>
							</Button>							

                        </div>
                        <div class="Vivacardbody">
							<div className="row " style={{ height: "calc(100vH - 200px)" }}>							
								<div style={{width: "50%"}}>
									<AppBar position="static" color="default">
											<Tabs 	className="grid-Cell-Header"
													value={this.state.tabValueOverview}
													onChange={this.handleTabChange}
													indicatorColor="secondary"
													textColor="white"
													variant="scrollable"
													scrollButtons="auto"
												>
												<Tab label={this.props.t('Darstellung')} />
											</Tabs>
									</AppBar>  
									{this.state.tabValueOverview === 0 &&
									<>

									<div class="Vivacardheader">
                                        <Button
                                            type="button"
                                            className="buttonIcon"
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            onClick={(e) => this.handleSavePlaning(true)}
                                            >
                                            <span className="fa fa-download "></span>
                                        </Button>
                                        <Button
                                            type="button"
                                            className="buttonIcon"
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            onClick={this.toggleNodeExpansion.bind(this, true)}
                                            >
                                            <span className="fa fa-expand "></span>
                                        </Button>   
                                        <Button
                                            type="button"
                                            className="buttonIcon"
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            onClick={this.toggleNodeExpansion.bind(this, false)}
                                            >
                                            <span className="fa fa-compress "></span>
                                        </Button> 
										<Tooltip title={this.props.t('exportPlaning')} placement="top-start">
											<Button type="button"
													className="buttonIcon"
													style={{
														verticalAlign: 'middle',
													}}
													onClick={(e) => this.handleExportPlaning(true)}
												>
												<span className="fa fa-file-excel-o"></span>
											</Button>
										</Tooltip>
										<Tooltip title={this.props.t('showSubPlaning')} placement="top-start">
											<Button type="button"
													className="buttonIcon"
													style={{
														verticalAlign: 'middle',
													}}
													onClick={(e) => this.handleShowSubPlaning()}
												>
												<span className={this.state.showSubplaning === true ? 'fa fa-eye' : 'fa fa-eye-slash'}></span>
											</Button>	
										</Tooltip>
										<Tooltip title={this.props.t('Suche')} placement="top-start">
											<span >
											<input type="search" onChange={e => this.handleSearchOnChange(e)} />
											&nbsp;Anzahl: {this.state.searchFoundCount}
											</span>
										</Tooltip>

										
										<span className="pull-right">
											<Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleClickFlag(e)}>              
												<span className="fa fa-flag"></span>
											</Button>
											<Menu	id="simple-menuFlag "
													anchorEl={this.state.anchorElFlag}
													keepMounted
													open={Boolean(this.state.anchorElFlag)}
													onClose={this.handleCloseFlag}
												>											
												{this.state.itemLanguages.map(iT => (
													<>
													<MenuItem onClick={e => this.changeItemLanguage(iT.iso)}><span className={iT.iso == this.state.itemLanguage ? "fa fa-check" : ""}></span>&nbsp;{iT.label}</MenuItem>
													</>
												))}
											</Menu>	
										</span>																																						                                                                             
                                    </div>	
										<SortableTree	
											nodeContentRenderer={CustomNodeContentRenderer}
											style={{marginLeft: "10px"}}
											treeData={this.state.treeData}
											canDrag={({ node }) => !node.noDragging}
											onChange={treeData => this.setState({ treeData })}
											searchQuery={this.state.searchQuery}
											searchFinishCallback={matches =>
																		this.setState({
																			searchFoundCount: matches.length,
																			
																})
											}

											generateNodeProps={rowInfo => ({
												onClick: (e) => this.handleNodeClick(rowInfo, e),
												title: <div className={rowInfo.node.isSelected ? 'selectedNodeX' : ''}>{rowInfo.node.title}</div>	,									
												className: rowInfo.node.class,
												buttons: rowInfo.node.type === "plItem" ?
												rowInfo.node.refId == 0 ?												 
													[ <Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleFunctionClick(rowInfo)}
														>
														<span className="fa fa-pencil "></span>
													</Button>,
													<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleDeleteItem(rowInfo)}
														>
														<span className="fa fa-minus "></span>
													</Button>,
													<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleEditItemClick(rowInfo)}
														>
														<span className="fa fa-cog "></span>
													</Button>,
													<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleExportPlaningDetail(rowInfo)}
														>
														<span className="fa fa-file-excel-o "></span>
													</Button>,													
													<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handlePDFItemClickDetail(rowInfo)}
														>
														<span className="fa fa-file-pdf-o "></span>
													</Button>,
													]
													:
													[<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleFunctionClick(rowInfo)}
														>
														<span className="fa fa-pencil "></span>
													</Button>,
													<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleDeleteItem(rowInfo)}
														>
														<span className="fa fa-minus "></span>
													</Button>,
													<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleEditItemClickDetail(rowInfo)}
														>
														<span className="fa fa-cog "></span>
													</Button>,]
													: 
													rowInfo.node.type === "sub" ?
													[
														<Button
															type="button"
															className="buttonIcon"
															style={{
																verticalAlign: 'middle',
															}}
															onClick={(e) => this.handlePrepareAddSubPlaning(rowInfo)}
															>
															<span className="fa fa-plus "></span>
														</Button>,
														,]:
											[
											<Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={(e) => this.handlePrepareAddItems(rowInfo)}
                                                >
                                                <span className="fa fa-plus "></span>
											</Button>,
											<Button
												type="button"
												className="buttonIcon"
												style={{
													verticalAlign: 'middle',
												}}
												onClick={(e) => this.handleEditPLElementClick(rowInfo)}
												>
												<span className="fa fa-cog "></span>
											</Button>,											
											<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handlePDFItemClickDetail(rowInfo)}
														>
														<span className="fa fa-file-pdf-o "></span>
													</Button>														
													],
											})}                                        
										/>
										</>
									}
								</div>
								<div style={{width: "50%"}}>
                            		<div class="planingCard">								
									<AppBar position="static" color="default">
											<Tabs 	className="grid-Cell-Header"
													value={this.state.tabValue}
													onChange={this.handleTabChange}
													indicatorColor="secondary"
													textColor="white"
													variant="scrollable"
													scrollButtons="auto"
												>
												<Tab label={this.props.t('StreckenVorschau')} />
												<Tab label={this.props.t('Einstellungen')} />
												<Tab label={this.props.t('PDF')} />
											</Tabs>
									</AppBar>  
									{this.state.tabValue === 0 && 
										<>	
											<div class="Vivacardheader">
												<Button
													type="button"
													className="buttonIcon"
													style={{
														verticalAlign: 'middle',
													}}
													
													>
													<span className="fa fa-refresh"></span>
												</Button>																												
											</div>	
											<div className="planingPreview">
												<div style={{margin: "0 auto", width: "650px", height: "1024px"}}>
													{this.state.planningMap.map(pl => (
														<>
															<div className="planingPreviewPage" style={{ width: pl.width+"px", height: pl.height + "px" }}>
																<div style={{overflow: "hidden", backgroundColor: "white", width: "100%", height: "18px",  fontSize: "10px"}}>
																	{pl.header}
																</div>
																<div style={{backgroundColor: "white", width: "100%", height: "298px" }}>
																	{pl.items.map(item => (
																		<div style={{backgroundImage: "url('/images/"+item.formVersion+".png')", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", float: "left", border: "2px solid #c8ced3", width: item.width+ "%", height: "" + item.height+"%"}}>
																		{item.dbId}
																		

																		</div>
																	))}
																</div>

															</div>
															
														</>
													))}
												</div>
											</div>
											 
										</>	
									}									
									{this.state.tabValue === 1 && 
										<>
											<div class="Vivacardheader">
												<Button
													type="button"
													className="buttonIcon"
													style={{
														verticalAlign: 'middle',
													}}
													
													>
													<span className="fa fa-download "></span>
												</Button>																												
											</div>										
											<div className="planingCard">	
												{this.state.currentPlaning.node &&
													<>
														{this.state.currentPlaning.node.type !== "plItem" ? (
															<>
																<div style={{backgroundColor: "#f0f3f5"}}>
																	<div className="row rowMaxWidth">
																		<div className="col-sm">
																			<Typography style={{paddingTop: "10px", paddingBottom: "10px"}}>{this.props.t('Planung')}: {this.state.currentPlaning.node.title}</Typography>							
																		</div>
																		<div className="col-sm">
																			<Typography style={{paddingTop: "10px", paddingBottom: "10px"}}>ElementId: {this.state.currentPlaning.node.plId}</Typography>
																		</div>
																	</div>
																	<div className="row rowMaxWidth">
																		<div className="col-sm">
																			<div className="marginTop">																																										
																				<FormControl  style={{display: "flex"}}>      
																					<InputLabel shrink htmlFor="export-label-placeholder">
																						{this.props.t('Maske')}
																					</InputLabel>
																					<Select
																						className="planingSelect"
																							value={this.state.currentPlaning.node.maskId}
																							onChange={handleChange(1)}
																							input={<Input name="age" id="age-label-placeholder" />}
																							displayEmpty
																							name="export"
																						>
																						<MenuItem value="0">{this.props.t('keine')}</MenuItem>
																						{
																							this.state.masks.map(function(ex) {
																								return (<MenuItem value={ex.id}>{ex.name}</MenuItem>)
																							})
																						}
																					</Select>
																				</FormControl>	
																			</div>	
																		</div>
																		<div className="col-sm">
																			<div className="marginTop">																																										
																				<FormControl  style={{display: "flex"}}>      
																					<InputLabel shrink htmlFor="export-label-placeholder">
																						{this.props.t('Tree Maske')}
																					</InputLabel>
																					<Select
																						className="planingSelect"
																							value={this.state.currentPlaning.node.nodeMaskId}
																							onChange={handleChange(1)}
																							input={<Input name="age" id="age-label-placeholder" />}
																							displayEmpty
																							name="export"
																						>
																						<MenuItem value="0">{this.props.t('keine')}</MenuItem>
																						{
																							this.state.masks.map(function(ex) {
																								return (<MenuItem value={ex.id}>{ex.name}</MenuItem>)
																							})
																						}
																					</Select>
																				</FormControl>	
																			</div>
																		</div>
																	</div>
																	<div className="row rowMaxWidth">
																		<div className="col-sm">
																			<FormGroup row>
																				<FormControlLabel
																					control={
																						<Switch
																							checked={this.state.currentPlaning.node.merge === 1 ? true : false}
																							onChange={handleChange('checkedA')}
																							value={this.state.currentPlaning.node.merge}
																							inputProps={{ 'aria-label': 'secondary checkbox' }}
																						/>
																					}
																					labelPlacement="start"
																					label={this.props.t('zusammmenfassen')}
																				/>
																			</FormGroup>
																		</div>
																	</div>


																	<hr/>
																</div>
																

																													
															</>
														) : (<>
																<VivaMaskEingabe 	
																		maskId="148" 
																			tableId="49" 
																			itemId={this.state.currentItemID} 
																			anchor="xyz" 
																			nextQuery="0"
																			currentHighlightItemId={this.state.currentHighlightItemId} 
																>
																</VivaMaskEingabe>														
														
														</>)
													
													}
													</>
												}
											</div>
										</>
										
									}

									{this.state.tabValue === 2 && 
										<>
											<div>
												<PDFDisplay language={this.state.itemLanguage} items={this.state.exportItems} itemId={this.state.exportItem} nextMask={this.props.match.params.planingId}/>
											</div>
										</>
									}																		
									</div> 
								</div>
							</div> 
						</div>							
                    </div>

					<Drawer anchor="right" 
                            open={this.state.maskEdit} 
                            onClose={((e) => this.handlemaskEditClick(false))} 
                            PaperProps={{ style: this.state.newWidthLinkItems }}>
                        <div
                            id="dragger"
                            onMouseDown={event => {
                                this.handleMousedown(event);
                            }}
                            className="drawerDragger"
                        />
                        <div className="notificationFrame" style={{marginLeft: "5px"}}>
						
																<VivaMaskEingabe 	
																		maskId="101" 
																		tableId="2" 
																		itemId={this.state.currentItemID} 
																		anchor="xyz" 
																		nextQuery="0"
																		currentHighlightItemId={this.state.currentHighlightItemId} 
																		action={this.reloadAction}>
																</VivaMaskEingabe>						
                            
                        </div>
                    </Drawer>

                    <Drawer anchor="right" 
                            open={this.state.notification} 
                            onClose={((e) => this.handleNotificationClick(false))} 
                            PaperProps={{ style: this.state.newWidthLinkItems }}>
                        <div
                            id="dragger"
                            onMouseDown={event => {
                                this.handleMousedown(event);
                            }}
                            className="drawerDragger"
                        />
                        <div className="notificationFrame" style={{marginLeft: "5px"}}>
                            <VivaGrid2  maskId="67" 
                                        tableId="2" 
                                        itemId="-1" 
                                        maskModus="6" 
                                        linkToPlanungElementId={this.state.currentPlElementId}
                                        maskWidth={this.state.currentMaskWidthLink}
                                        actionOnClose={e =>  this.reload(this.props.match.params.planingId)}
                                        action={e => this.handleAddItems}/>
                            
                        </div>
                    </Drawer>					
					<Dialog open={this.state.show} onClose={this.handleClose} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Element hinzufügen')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Vergeben Sie einen Namen für das Element')}
								</DialogContentText>
								<FormControl  style={{width: "100%"}}>
									<InputLabel htmlFor="age-native-helper">Element Typ</InputLabel>
									<Select
										value={this.state.newElemChoice}
										onChange={e => this.handleChangeNewElem(e)}
										input={<Input name="age" id="age-label-placeholder" />}
										displayEmpty
										name="age"
										
									>
										<MenuItem value="sub">Unterplanung</MenuItem>																			
										<MenuItem value="item">Produkte</MenuItem>																			
									
									</Select>
								</FormControl>								
								<TextField
									autoFocus
									margin="dense"
									name="bookmarklabel"
									label="Elementname"
									type="email"
									onChange={e => this.changeElementlabel(e)}
									value={this.state.elementlabel}
									fullWidth
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={this.handleAddSubPlaning} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
					</Dialog>

					<Dialog open={this.state.showEditPLElement} onClose={this.handleCloseEditPLElement} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('PlanungsElement editieren')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Eigenschaften')}
								</DialogContentText>
								<TextField
									autoFocus
									margin="dense"
									name="Label"
									label="Label"
									type="email"
									onChange={e => this.changeElementSubLabel(e)}
									value={this.state.PLElement_SubLabel}
									fullWidth
								/>
								<TextField
									autoFocus
									margin="dense"
									name="Zusatz2"
									label="Zusatz2"
									type="email"
									onChange={e => this.changeElementZusatz2(e)}
									value={this.state.PLElement_Zusatz2}
									fullWidth
								/>
								<TextField
									autoFocus
									margin="dense"
									name="Zusatz3"
									label="Zusatz3"
									type="email"
									onChange={e => this.changeElementZusatz3(e)}
									value={this.state.PLElement_Zusatz3}
									fullWidth
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleCloseEditPLElement} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={this.handleChangeLayoutPLElement} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
					</Dialog>


					<Dialog open={this.state.showLayout} onClose={this.handleCloseLayout} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Layout Einstellung')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Legen Sie die Form fest')} {this.state.viva_form}
								</DialogContentText>
									<div className="row ">
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
											<img src={"/images/" + this.state.viva_formVersion + ".png"} width="200"/>

										</div>
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Formen Name</InputLabel>
												<Select
													value={this.state.viva_formBase}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formBase"
												>
													<MenuItem value="0">{this.props.t('keine')}</MenuItem>
													{
														this.state.forms.map(function(ex) {
															return (<MenuItem value={ex}>{ex}</MenuItem>)
														})
													}																				
												
												</Select>
											</FormControl>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Form Variante</InputLabel>
												<Select
													value={this.state.viva_formVersion}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formVersion"
												>
												{ this.state.viva_formBase != "" ? (						
													
														this.state.formsBase[this.state.viva_formBase].map(function(ex) {
															return (<MenuItem value={ex}>{ex}</MenuItem>)
														})
														
												) : (<></>)}

												</Select>
											</FormControl>											
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">TextBreite</InputLabel>
												<Select
													value={this.state.viva_formz1}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formz1"
												>
													<MenuItem value="100">100% Breite</MenuItem>																			
													<MenuItem value="75">75% Breite</MenuItem>																			
													<MenuItem value="50">50% Breite</MenuItem>																			
													<MenuItem value="25">25% Breite</MenuItem>																			
												
												</Select>
											</FormControl>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Text Spalten</InputLabel>
												<Select
													value={this.state.viva_formz3}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formz3"
												>
													<MenuItem value="1">1 spaltig</MenuItem>																			
													<MenuItem value="2">2 spaltig</MenuItem>																			
													<MenuItem value="3">3 spaltig</MenuItem>																			
													<MenuItem value="4">4 spaltig</MenuItem>																			
												
												</Select>
											</FormControl>	
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Spalten Umbruch</InputLabel>
												<Select
													value={this.state.viva_formz4}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formz4"
												>
													<MenuItem value="normal">kein Umbruch</MenuItem>																			
													<MenuItem value="nachVorteilsPunkten">nach Vorteilspunkten</MenuItem>																			
																		
												
												</Select>
											</FormControl>
																					
										</div>
									</div>
								<br/>
								<DialogContentText>
									{this.props.t('Legen Sie die Details fest')}
								</DialogContentText>					
								<div className="row ">
										<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">
											
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Inhaltsanzeige</InputLabel>
											<Select
												value={this.state.viva_formz2}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_formz2"
											>
												<MenuItem value="all">Alles</MenuItem>																			
												<MenuItem value="content">Inhalt</MenuItem>																			
												<MenuItem value="noDetails">Keine Details</MenuItem>																			
												<MenuItem value="pictures">Nur Bilder</MenuItem>																			
												<MenuItem value="text">Nur Texte</MenuItem>																			
											
											</Select>
										</FormControl>

										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Piktogramme Position</InputLabel>
											<Select
												value={this.state.viva_formst_iconPosition}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_formst_iconPosition"
											>
												<MenuItem value="ka">Keine Anzeige</MenuItem>																			
												<MenuItem value="Position1">Textbereich</MenuItem>																			
												<MenuItem value="Position2">Bildbereich</MenuItem>																																																					
											</Select>
										</FormControl>										
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Logo Position</InputLabel>
											<Select
												value={this.state.viva_markeLogo}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_markeLogo"
											>
												<MenuItem value="ka">Keine Anzeige</MenuItem>																			
												<MenuItem value="TL">Oben Links</MenuItem>																			
												<MenuItem value="TC">Oben Mitte</MenuItem>																			
												<MenuItem value="TR">Oben Rechts</MenuItem>																			
												<MenuItem value="CL">Mitte Links</MenuItem>																			
												<MenuItem value="CC">Mitte Mitte</MenuItem>																			
												<MenuItem value="CR">Mitte Rechts</MenuItem>																			
												<MenuItem value="BL">Unten Links</MenuItem>																			
												<MenuItem value="BC">Unten Mitte</MenuItem>																			
												<MenuItem value="BR">Unten Rechts</MenuItem>																																		
											</Select>
										</FormControl>
										</div>
										<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">
											<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">StörerPreis Position</InputLabel>
											<Select
												value={this.state.viva_formst_preis}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_formst_preis"
											>
												<MenuItem value="ka">Keine Anzeige</MenuItem>																			
												<MenuItem value="TL">Oben Links</MenuItem>																			
												<MenuItem value="TC">Oben Mitte</MenuItem>																			
												<MenuItem value="TR">Oben Rechts</MenuItem>																			
												<MenuItem value="CL">Mitte Links</MenuItem>																			
												<MenuItem value="CC">Mitte Mitte</MenuItem>																			
												<MenuItem value="CR">Mitte Rechts</MenuItem>																			
												<MenuItem value="BL">Unten Links</MenuItem>																			
												<MenuItem value="BC">Unten Mitte</MenuItem>																			
												<MenuItem value="BR">Unten Rechts</MenuItem>																																		
											</Select>
										</FormControl>
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">StörerNeu Position</InputLabel>
											<Select
												value={this.state.viva_formst_neu}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_formst_neu"
											>
												<MenuItem value="ka">Keine Anzeige</MenuItem>																			
												<MenuItem value="TL">Oben Links</MenuItem>																			
												<MenuItem value="TC">Oben Mitte</MenuItem>																			
												<MenuItem value="TR">Oben Rechts</MenuItem>																			
												<MenuItem value="CL">Mitte Links</MenuItem>																			
												<MenuItem value="CC">Mitte Mitte</MenuItem>																			
												<MenuItem value="CR">Mitte Rechts</MenuItem>																			
												<MenuItem value="BL">Unten Links</MenuItem>																			
												<MenuItem value="BC">Unten Mitte</MenuItem>																			
												<MenuItem value="BR">Unten Rechts</MenuItem>																																		
											</Select>
										</FormControl>
										<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Umbruch erzwingen</InputLabel>
												<Select
													value={this.state.viva_pagebreak}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_pagebreak"
												>
													<MenuItem value="nein">Kein Umbruch</MenuItem>																			
													<MenuItem value="ja">Umbruch</MenuItem>																			
																																															
												</Select>
											</FormControl>
										</div>
										<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">
											<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">MasterTabelle Ansicht</InputLabel>
											<Select
												value={this.state.viva_tableDisplay}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_tableDisplay"
											>
												<MenuItem value="large">Erweitert</MenuItem>																			
												<MenuItem value="short">Gruppiert</MenuItem>																			
																																														
											</Select>
										</FormControl>
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Gruppierung nach</InputLabel>
											<Select
												value={this.state.viva_columnGroup}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_columnGroup"
											>
											<MenuItem value="keine">-keine-</MenuItem>																			
											{
												this.state.classColumns.map(function(ex) {
													return (<MenuItem value={ex}>{ex}</MenuItem>)
												})	
											}																	
																																														
											</Select>
										</FormControl>
										</div>
									</div>
									<div className="row " style={{marginTop: "5px"}}>
										<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">
											<TextField
												autoFocus
												margin="dense"
												name="viva_formst_textValue1"
												label="StörerText1"
												type="email"
												onChange={e => this.handleChangeForm(e)}
												value={this.state.viva_formst_textValue1}
												fullWidth
											/>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText1 Position</InputLabel>
												<Select
													value={this.state.viva_formst_text1}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_text1"
												>
													<MenuItem value="ka">Keine Anzeige</MenuItem>																			
													<MenuItem value="TL">Oben Links</MenuItem>																			
													<MenuItem value="TC">Oben Mitte</MenuItem>																			
													<MenuItem value="TR">Oben Rechts</MenuItem>																			
													<MenuItem value="CL">Mitte Links</MenuItem>																			
													<MenuItem value="CC">Mitte Mitte</MenuItem>																			
													<MenuItem value="CR">Mitte Rechts</MenuItem>																			
													<MenuItem value="BL">Unten Links</MenuItem>																			
													<MenuItem value="BC">Unten Mitte</MenuItem>																			
													<MenuItem value="BR">Unten Rechts</MenuItem>																																		
												</Select>
											</FormControl>	
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText1 Form</InputLabel>
												<Select
													value={this.state.viva_formst_textForm1}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_textForm1"
												>
													<MenuItem value="Icon Blanco Quadrat">Blanco Quadrat</MenuItem>																			
													<MenuItem value="Icon Comfort Product">Comfort Product</MenuItem>																																																					
													<MenuItem value="Icon Designed_in_Germany">Designed_in_Germany</MenuItem>																																																					
													<MenuItem value="Icon Eco">Eco</MenuItem>																																																					
													<MenuItem value="Icon Exclusive_Product">Exclusive_Product</MenuItem>																																																					
													<MenuItem value="Icon Highlight_Product">Highlight_Product</MenuItem>																																																					
													<MenuItem value="Icon Info">Info</MenuItem>																																																					
													<MenuItem value="Icon Made_in_Europe">Made_in_Europe</MenuItem>																																																					
													<MenuItem value="Icon Made_in_Germany">Icon Made_in_Germany</MenuItem>																																																					
													<MenuItem value="Icon Neu">Neu</MenuItem>																																																					
													<MenuItem value="Icon Onlineshop">Icon Onlineshop</MenuItem>																																																					
													<MenuItem value="Icon Preis_1">Preis_1</MenuItem>																																																					
													<MenuItem value="Icon Preis_2">Preis_2</MenuItem>																																																					
													<MenuItem value="Icon Preis_Reimo">Preis_Reimo</MenuItem>																																																					
													<MenuItem value="Icon Premium_Product">Premium_Product</MenuItem>																																																					
													<MenuItem value="Icon Professional_Product">Professional_Product</MenuItem>																																																					
													<MenuItem value="Icon Rabatt">Rabatt</MenuItem>																																																					
													<MenuItem value="Icon Tip">Tip</MenuItem>																																																					

												</Select>
											</FormControl>	
											</div>
											<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">	
											<TextField
												autoFocus
												margin="dense"
												name="viva_formst_textValue2"
												label="StörerText2"
												type="email"
												onChange={e => this.handleChangeForm(e)}
												value={this.state.viva_formst_textValue2}
												fullWidth
											/>									
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText2 Position</InputLabel>
												<Select
													value={this.state.viva_formst_text2}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_text2"
												>
													<MenuItem value="ka">Keine Anzeige</MenuItem>																			
													<MenuItem value="TL">Oben Links</MenuItem>																			
													<MenuItem value="TC">Oben Mitte</MenuItem>																			
													<MenuItem value="TR">Oben Rechts</MenuItem>																			
													<MenuItem value="CL">Mitte Links</MenuItem>																			
													<MenuItem value="CC">Mitte Mitte</MenuItem>																			
													<MenuItem value="CR">Mitte Rechts</MenuItem>																			
													<MenuItem value="BL">Unten Links</MenuItem>																			
													<MenuItem value="BC">Unten Mitte</MenuItem>																			
													<MenuItem value="BR">Unten Rechts</MenuItem>																																		
												</Select>
											</FormControl>	
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText2 Form</InputLabel>
												<Select
													value={this.state.viva_formst_textForm2}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_textForm2"
												>
													<MenuItem value="Icon Blanco Quadrat">Blanco Quadrat</MenuItem>																			
													<MenuItem value="Icon Comfort Product">Comfort Product</MenuItem>																																																					
													<MenuItem value="Icon Designed_in_Germany">Designed_in_Germany</MenuItem>																																																					
													<MenuItem value="Icon Eco">Eco</MenuItem>																																																					
													<MenuItem value="Icon Exclusive_Product">Exclusive_Product</MenuItem>																																																					
													<MenuItem value="Icon Highlight_Product">Highlight_Product</MenuItem>																																																					
													<MenuItem value="Icon Info">Info</MenuItem>																																																					
													<MenuItem value="Icon Made_in_Europe">Made_in_Europe</MenuItem>																																																					
													<MenuItem value="Icon Made_in_Germany">Icon Made_in_Germany</MenuItem>																																																					
													<MenuItem value="Icon Neu">Neu</MenuItem>																																																					
													<MenuItem value="Icon Onlineshop">Icon Onlineshop</MenuItem>																																																					
													<MenuItem value="Icon Preis_1">Preis_1</MenuItem>																																																					
													<MenuItem value="Icon Preis_2">Preis_2</MenuItem>																																																					
													<MenuItem value="Icon Preis_Reimo">Preis_Reimo</MenuItem>																																																					
													<MenuItem value="Icon Premium_Product">Premium_Product</MenuItem>																																																					
													<MenuItem value="Icon Professional_Product">Professional_Product</MenuItem>																																																					
													<MenuItem value="Icon Rabatt">Rabatt</MenuItem>																																																					
													<MenuItem value="Icon Tip">Tip</MenuItem>																																																					
												</Select>
											</FormControl>
											</div>
											<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">											
											<TextField
												autoFocus
												margin="dense"
												name="viva_formst_textValue3"
												label="StörerText3"
												type="email"
												onChange={e => this.handleChangeForm(e)}
												value={this.state.viva_formst_textValue3}
												fullWidth
											/>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText3 Position</InputLabel>
												<Select
													value={this.state.viva_formst_text3}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_text3"
												>
													<MenuItem value="ka">Keine Anzeige</MenuItem>																			
													<MenuItem value="TL">Oben Links</MenuItem>																			
													<MenuItem value="TC">Oben Mitte</MenuItem>																			
													<MenuItem value="TR">Oben Rechts</MenuItem>																			
													<MenuItem value="CL">Mitte Links</MenuItem>																			
													<MenuItem value="CC">Mitte Mitte</MenuItem>																			
													<MenuItem value="CR">Mitte Rechts</MenuItem>																			
													<MenuItem value="BL">Unten Links</MenuItem>																			
													<MenuItem value="BC">Unten Mitte</MenuItem>																			
													<MenuItem value="BR">Unten Rechts</MenuItem>																																		
												</Select>
											</FormControl>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText3 Form</InputLabel>
												<Select
													value={this.state.viva_formst_textForm3}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_textForm3"
												>
													<MenuItem value="Icon Blanco Quadrat">Blanco Quadrat</MenuItem>																			
													<MenuItem value="Icon Comfort Product">Comfort Product</MenuItem>																																																					
													<MenuItem value="Icon Designed_in_Germany">Designed_in_Germany</MenuItem>																																																					
													<MenuItem value="Icon Eco">Eco</MenuItem>																																																					
													<MenuItem value="Icon Exclusive_Product">Exclusive_Product</MenuItem>																																																					
													<MenuItem value="Icon Highlight_Product">Highlight_Product</MenuItem>																																																					
													<MenuItem value="Icon Info">Info</MenuItem>																																																					
													<MenuItem value="Icon Made_in_Europe">Made_in_Europe</MenuItem>																																																					
													<MenuItem value="Icon Made_in_Germany">Icon Made_in_Germany</MenuItem>																																																					
													<MenuItem value="Icon Neu">Neu</MenuItem>																																																					
													<MenuItem value="Icon Onlineshop">Icon Onlineshop</MenuItem>																																																					
													<MenuItem value="Icon Preis_1">Preis_1</MenuItem>																																																					
													<MenuItem value="Icon Preis_2">Preis_2</MenuItem>																																																					
													<MenuItem value="Icon Preis_Reimo">Preis_Reimo</MenuItem>																																																					
													<MenuItem value="Icon Premium_Product">Premium_Product</MenuItem>																																																					
													<MenuItem value="Icon Professional_Product">Professional_Product</MenuItem>																																																					
													<MenuItem value="Icon Rabatt">Rabatt</MenuItem>																																																					
													<MenuItem value="Icon Tip">Tip</MenuItem>																																																					
												</Select>
											</FormControl>											

										</div>
									</div>																																						
							</DialogContent>
							
							<DialogActions>
								<Button onClick={this.handleCloseLayout} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={this.handleChangeLayoutItem} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
					</Dialog> 
					<Dialog
                            open={this.state.confirmDialog}
                            onClose={((e) => this.handleConfirmDialog(false))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">Bestätigen</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Möchten Sie das PlanungsItem wirklich löschen?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handleConfirmDialog(false))} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={((e) => this.handleConfirmDialog("confirm"))} color="primary" autoFocus>
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog>
					<Dialog open={this.state.showLayoutDetail} onClose={this.handleCloseLayoutDetail} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Layout festlegen')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Legen Sie das Layout fest')}
								</DialogContentText>
																
								<FormControl  style={{width: "100%"}}>
									<InputLabel htmlFor="age-native-helper">Beziehung</InputLabel>
									<Select
										value={this.state.viva_relationship}
										onChange={e => this.handleChangeForm(e)}
										input={<Input name="age" id="age-label-placeholder" />}
										displayEmpty
										name="viva_relationship"
									>
										<MenuItem value="alternativen">Alternative</MenuItem>																			
										<MenuItem value="detailartikel">Detailartikel</MenuItem>																			
										<MenuItem value="ersatzteil">Ersatzteil</MenuItem>																			
										<MenuItem value="notZubehoer">Notwendige Zubehör</MenuItem>																			
										<MenuItem value="optZubehoer">Optinales Zubehör</MenuItem>																			
										<MenuItem value="varianten">Varianten</MenuItem>																			
									
									</Select>
								</FormControl>								
								<FormControl  style={{width: "100%"}}>
									<InputLabel htmlFor="age-native-helper">Inhaltsanzeige</InputLabel>
									<Select
										value={this.state.viva_layout}
										onChange={e => this.handleChangeForm(e)}
										input={<Input name="age" id="age-label-placeholder" />}
										displayEmpty
										name="viva_layout"
									>
										<MenuItem value="alles">Alles</MenuItem>																			
										<MenuItem value="keine Beschreibung">keine Beschreibung</MenuItem>																			
																			
									
									</Select>
								</FormControl>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleCloseLayoutDetail} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={this.handleChangeLayoutItemDetail} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
					</Dialog> 					


					<Dialog 
						fullWidth="md"
						maxWidth="md"
						open={this.state.showQuery} 
						onClose={((e) => this.handleShowQuery(false))}>
						<DialogTitle id="alert-dialog-title">
							{this.props.t('Abfrage')}: {this.state.queryId}

						</DialogTitle>	
						<QueryDetail 
							queryId={this.state.queryId}
						/>
						<DialogActions>
							<Button onClick={((e) => this.handleShowQuery(false))} color="primary">
								{this.props.t('Abbruch')}
							</Button>
							<Button  autoFocus>
								{this.props.t('Speichern')}
							</Button>
						</DialogActions>   
					</Dialog>


					<Dialog fullWidth="md"
                            maxWidth="md"
							open={this.state.exportPlaning}
                            onClose={((e) => this.handleExportPlaning(false))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">Export</DialogTitle>
                        <DialogContent>
                          {this.state.exportStatus === 0 && 
							  <span>Ihre Planung wird berechnet</span>
						  }
                          {this.state.exportStatus === 1 && 
							  <CSVDisplay language={this.state.itemLanguage} itemId={this.state.exportItem} items={this.state.exportItems} nextMask={this.props.match.params.planingId}/>
						  }

                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handleExportPlaning(false))} color="primary">
                            {this.props.t('Abbruch')}
                        </Button>
                        <Button onClick={((e) => this.handleExportPlaning(false))} color="primary" autoFocus>
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog>


					<Dialog fullScreen open={this.state.ItemsDialog} onClose={this.handleCloseItemDialog}>
						<AppBar className="appBar title grid-Cell-Header">
							<Toolbar>
								<IconButton edge="start" color="inherit" onClick={this.handleCloseItemDialog} aria-label="Close">
									<CloseIcon />
								</IconButton>
								<Typography variant="h6" >
									PlanungsItems
								</Typography>
							</Toolbar>
						</AppBar>
						<div className="maxWidth" id="xyz">
							<VivaGrid 	maskId="67" 
										tableId="2" 
										planingElementId={this.state.planingElementId} 
										itemId="-1" 
										anchor="xyz"
										action={this.reloadAction}></VivaGrid>
						</div>
							
					</Dialog>	
					
					<Dialog open={this.state.bookmarkOpen} onClose={((e) => this.addBookmark('false'))} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Bookmark anlegen')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Vergeben Sie einen Namen für ihren Bookmark')}
								</DialogContentText>
								<FormControl  style={{width: "100%"}}>
									<InputLabel htmlFor="age-native-helper">Ordner Auswahl</InputLabel>
									<NativeSelect
										value={this.state.bookmarkfolderId}
										onChange={e => this.change(e)}
										inputProps={{
											name: 'bookmarkfolder',
											id: 'age-native-helper',
										}}
									>
									{this.state.bookmarkLinks.length && this.state.bookmarkLinks.map(folder => (
										<option value={folder.id}>{folder.label}</option>
									))}
									</NativeSelect>
									<FormHelperText>wählen Sie den Ordner für den Bookmark aus.</FormHelperText>
								</FormControl>								
								<TextField
									autoFocus
									margin="dense"
									name="bookmarklabel"
									label="Bookmarkname"
									type="email"
									onChange={e => this.change(e)}
									value={this.state.bookmarklabel}
									fullWidth
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={((e) => this.addBookmark('false'))} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={((e) => this.addBookmark('add'))} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
						</Dialog>

					<Modal size="lg" show={this.state.exportShow} onHide={this.handleExportClose}>
						<Modal.Header closeButton>
							<Modal.Title>{this.props.t('Export')}: </Modal.Title>
						</Modal.Header>
						<Modal.Body class="modalBody">
							<ExportFields exportId={this.state.currentExportId}/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleExportClose}>
							{this.props.t('Abbruch')}
							</Button>
							<Button variant="primary" onClick={this.handleExportClose}>
							{this.props.t('Speichern')}
							</Button>
						</Modal.Footer>
					</Modal>									
                </> 
            );

		}

    }
}
export default withTranslation()(withSnackbar(PlaningDetails)) ;
