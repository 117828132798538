import React, { Component } from 'react';
import axios from 'axios';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import {Tabs, Tab} from 'react-bootstrap';
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import CreateNew from './blocks/CreateNew';
import Loader from './blocks/Loader'

class ClassColumns extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentTableId: 0,
            offset: 0,
            newCounter: -1,
            chooseList: [],
            currentSearch: "",
            allColumns: []
        };

        this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
        this.reload = this.reload.bind(this);
        this.createNew = this.createNew.bind(this); 
        this.change = this.change.bind(this);    
        this.handleCloseOK = this.handleCloseOK.bind(this);  
        this.addNewEntry = this.addNewEntry.bind(this); 
        this.searchChange = this.searchChange.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);

    }

	deleteEntry(linkid) {
        let cID = this.state.currentTableId;
        let columnList = [];
        this.state.posts.map(function(post) {
            if(post.id == cID) {
                columnList  = post.columns;
            }
        });
        
        this.state.columnList.map(function(cl) {
            if(cl.linkid == linkid) {
                cl.markAsDelete = "true";
            }
        });

        this.setState({ columnList: columnList});        
	}    

    searchChange(e) {
        this.setState({
			currentSearch: e
		});
    }

    createNew(newName) {
        let chooseList = {};
        chooseList.id = -1;
        chooseList.name = newName;
        axios.post(getAppUrl() + '/gatebackend/rest/settings/articleClassColumn/add', 
            chooseList
        ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
            this.props.enqueueSnackbar(this.props.t('Column hinzugefügt'), { variant: 'info' });
         
            this.reload();
        }
            
        )
        .catch((error) => {
            console.log(error);
            alert("error");
        });

    }

    change(e) {
        if(e.target.name === "chooseListName") {
            var chooseList = this.state.chooseList;
            chooseList.name = e.target.value;
            this.setState({
                chooseList : chooseList
            })
        }
        else {
            var columnList = this.state.columnList;
            var id = e.target.name.split("_");

            columnList.map(function(colList, j) {
                if(colList.linkid ===  parseInt(id[1], 10)) {
                    if(e.target.name.startsWith("sortId")) 
                        colList.sortId = e.target.value;
                    if(e.target.name.startsWith("text")) 
                        colList.type = e.target.value;

                }
            }
            );
            console.log(columnList);
            this.setState({
                columnList : columnList
            })
        }
    }

	reload() {
		axios.get(getAppUrl() + '/gatebackend/rest/content/columns', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res1 => {
                console.log(res1.data);
                this.setState({
                    isLoaded : true,
                    posts: res1.data,
                    show: false 
                })                   
                    
            }).catch((error) => {
                console.log(error);
                alert("error");
            });
	}

	handleStateChange() {
		;
	}

   componentDidMount() {
        this.reload();
    }

    handleCloseOK() {
        let columnList = this.state.columnList;
        let classId = this.state.currentTableId;
        axios.post(getAppUrl() + '/gatebackend/rest/settings/articleClass/change/' + classId, 
            columnList
        ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
            this.props.enqueueSnackbar(this.props.t('Einträge gespeichert'), { variant: 'info' });
            this.reload();
        }
            
        )
        .catch((error) => {
            console.log(error);
            alert("error");
        });
    }

    handleClose() {
        this.setState({ show: false });
    }

    addNewEntry(e) {
        let columnList = this.state.columnList;
        let counter = this.state.newCounter;
        let cLE = {};
        cLE.linkid = counter --;
        cLE.sortId = 0;
        cLE.text = "";
        cLE.markAsDelete = "false";
        columnList.push(cLE);

        this.setState({ columnList: columnList,
                        newCounter: counter});
    }

    handleShow(e) {
        let currentTableId = e.currentTarget.dataset.id;
        let currentTableName = e.currentTarget.dataset.tablename;
        let columnList = [];
        this.state.posts.map(function(post) {
            if(post.id == currentTableId) {
                columnList  = post.columns;
            }
        });

        this.setState({
            show : true,
            columnList: columnList,
            currentTableId: currentTableId,
            currentTableName: currentTableName
        })   
    }

    render() {
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">
                           Artikelklassen Columns
                           <CreateNew searchChange={this.searchChange} actionNew={this.createNew} title="Neue Colummn"/>
                        </div>
                        <div class="Vivacardbody">
                            <div class="border">
                                <div class="row clearmargin"  align="start">
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header "> 
                                            ID
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            Name
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            InternName
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            Bearbeiten
                                        </div>
                                    </div>
                                </div>
                                {
                                    posts.map(post => (
                                        <>
                                        {this.state.currentSearch === "" || ((post.id).toString().includes(this.state.currentSearch) ||  post.label.toLowerCase().includes((this.state.currentSearch).toLowerCase())) ? (                                        
                                        <div class="row clearmargin grid-Row" data-id={post.id} data-tablename={post.name}  onXYDoubleClick={((e) => this.handleShow(e))} align="start">
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value" > 
                                                    {post.id}
                                                </div>
                                            </div>
                                            <div class="grid-Cell col-sm"> 
                                                <div class="grid-Cell__value"> 
                                                    {post.label}
                                                </div>
                                            </div> 
                                            <div class="grid-Cell col-sm"> 
                                                <div class="grid-Cell__value"> 
                                                    {post.type}
                                                </div>
                                            </div>                                             
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    <ul>  
														<li data-id={post.id} data-tablename={post.label} class="fa fa-pencil gridIcon" onClick={((e) => this.handleShow(e))}>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>                                                                                    
                                        </div>
                                        ) : (<></>)}
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Artikelklassex: {this.state.currentTableName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body class="modalBody">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={this.state.key}
                                onSelect={key => this.setState({ key })}
                            >
                                <Tab eventKey="fields" title={this.props.t('Einträge')}>
                                    <div className="maskfieldsArea">
                                        <div className="row clearmargin maskFieldRow"  align="start">
                                            <div className="grid-Cell grid-Cell-Header maskField_100" >
                                                <div className="grid-Cell__header "> 
                                                    {this.props.t('id')}
                                                </div>
                                            </div>
                                            <div className="grid-Cell grid-Cell-Header maskField_100" >
                                                <div className="grid-Cell__header "> 
                                                    {this.props.t('SortId')}
                                                </div>
                                            </div>                                            
                                            <div className="grid-Cell grid-Cell-Header maskField_250">
                                                <div className="grid-Cell__header"> 
                                                    {this.props.t('Column')}
                                                </div>
                                            </div>									
                                            <div className="grid-Cell grid-Cell-Header maskField_250">
                                                <div className="grid-Cell__header"> 
                                                    {this.props.t('ColumnType')}
                                                </div>
                                            </div>	
                                            <div className="grid-Cell grid-Cell-Header maskField_100">
                                                <div className="grid-Cell__header"> 
                                                    {this.props.t('Löschen')}
                                                </div>
                                            </div>	                                            																	
                                        </div>
                                    </div>
                                    {this.state.columnList && this.state.columnList ? (
                                        <>
                                    { this.state.columnList.map(column => (
                                        <>   
                                        {column.markAsDelete != "true" ? (                                
                                            <div className="row clearmargin maskFieldRow"  align="start">
                                                <div className="grid-Cell  maskField_100" >
                                                    <div className="grid-Cell__header "> 
                                                        <input maxlength="30" 
                                                                size="30" 
                                                                type="text" 
                                                                name={"Id_" + column.linkid} 
                                                                onChange={e => this.change(e)} 
                                                                value={column.id} />
                                                    </div>
                                                </div>
                                                <div className="grid-Cell  maskField_100" >
                                                    <div className="grid-Cell__header "> 
                                                        <input maxlength="30" 
                                                                size="30" 
                                                                type="text" 
                                                                name={"sortId_" + column.linkid} 
                                                                onChange={e => this.change(e)} 
                                                                value={column.sortid} />
                                                    </div>
                                                </div>                                                
                                                <div className="grid-Cell  maskField_250">
                                                    <div className="grid-Cell__header"> 
                                                        <select name={"text_" + column.linkid}
                                                                onChange={e => this.change(e)} 
                                                                value={column.type}>
                                                            {
                                                                this.state.allColumns.map(column => (
                                                                    <option value={column.type}>{column.label}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>									
                                                <div className="grid-Cell  maskField_250">
                                                    <div className="grid-Cell__header"> 
                                                        {column.type}
                                                    </div>
                                                </div>	
                                                <div className="grid-Cell maskField_100"> 
                                                    <div className="grid-Cell__value">
                                                        <Button className="buttonIcon" aria-controls="simple-menuFlag" aria-haspopup="true" onClick={((e) => this.deleteEntry(column.linkid))}>
                                                            <span class="fa fa-trash-o pull-right"></span>
                                                        </Button>
                                                    </div>
                                                </div>                                            								
                                            </div>
                                            ) : (<></>)}
                                        </>
                                        ))
                                    }
                                    </>) : (<></>)
                                    }
                                    <Button variant="secondary" onClick={this.addNewEntry} style={{border: "1px solid #a0a0a0", margin: "10px"}}>
                                                                                <i class="fa fa-plus-circle"></i>&nbsp;
                                        {this.props.t('hinzufügen')}
                                    </Button>
                                </Tab>
                                <Tab eventKey="settings" title="Einstellungen">
                                    <div class="Vivacard">
                                        <div class="Vivacardheader">
                                            Allgemein                                
                                        </div>
                                        <div class="border">                               
                                            <div className="row clearmargin "  align="start">
                                                <div className="col-sm-2 ">
                                                    ID: 
                                                </div>
                                                <div className="col-sm">
                                                    {this.state.currentTableId}
                                                </div>
                                            </div>
                                            <div className="row clearmargin "  align="start">
                                                <div className="col-sm-2 ">
                                                    Name: 
                                                </div>
                                                <div className="col-sm">
                                                    <input maxlength="30" 
                                                            size="30" 
                                                            type="text" 
                                                            name="chooseListName" 
                                                            onChange={e => this.change(e)} 
                                                            value={this.state.chooseList.name} />
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                            </Tabs>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                            Abbruch
                            </Button>
                            <Button variant="primary" onClick={this.handleCloseOK}>
                            Speichern
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div> 
            );
       }
    }
}

export default withTranslation()(withSnackbar(ClassColumns)) ;
