import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import {getToken} from '../../helpers/Token';
import {getAppUrl} from '../../helpers/Defaults';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {getWSUrl} from '../../helpers/Defaults';
import {Link} from 'react-router-dom';
import Loader from './Loader'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PDFDisplay extends Component {


    constructor(props) {

        super(props);
        this.state = {
            posts: [],
            isLoaded : false,
            numPages: null,
            pageNumber: 1,
            zoom: 1.0,
            items: props.items,
            itemId: props.itemId,
            language: props.language,
            nextMask: props.nextMask
        };
        this.refresh = this.refresh.bind(this);
        this.back = this.back.bind(this);
        this.forward = this.forward.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.createPDF = this.createPDF.bind(this);
    }

    zoomOut(e) {
        let zoom = parseFloat(this.state.zoom);
        if(zoom > parseFloat(0.5))
            zoom = parseFloat(zoom) - parseFloat(0.5);
        try {
            zoom = zoom.toFixed(2);
        }
        catch (e) {
            console.log("zoomOut error:" + zoom);

            zoom = 1.0;
        }
        this.setState({
            zoom: zoom
        })
        
    }
    zoomIn(e) {
        let zoom = parseFloat(this.state.zoom);
        if(zoom < parseFloat(5))
            zoom = parseFloat(zoom) + parseFloat(0.5);
        try {
            zoom = zoom.toFixed(2);
        }
        catch (e) {
            console.log("zoomIn error:" + zoom);
            zoom = 1.0;
        }            
        this.setState({
            zoom: zoom
        })
    }    

    refresh(e) {
        this.createPDF(this.props.itemId, this.props.items, this.props.language);
    }    
    
    back(e) {
        var pageNumber = this.state.pageNumber;
        if(pageNumber > 1)
            pageNumber--;
        this.setState({
            pageNumber: pageNumber
        })
    }

    forward(e) {
        var pageNumber = this.state.pageNumber;
        if(pageNumber < this.state.numPages)
            pageNumber++;
        this.setState({
            pageNumber: pageNumber
        })
    }

    componentDidUpdate() {
        if(this.props.items != this.state.items || this.props.itemId != this.state.itemId || this.props.language != this.state.language)
            this.createPDF(this.props.itemId, this.props.items, this.props.language);
    }

    componentWillMount() {
        let client = new W3CWebSocket(getWSUrl() + '/gatebackend/websocketendpoint');
        client.onopen = () => {
            console.log("connect WS");
        };
        client.onmessage = (message) => {
            let obj = JSON.parse(message.data);
            if(obj.type === "renderPDFUpdate") {
                console.log("Msg:" +  obj.message);
                this.setState({
                    noticeMsg : obj.message ,
                })     
            }

        };
        this.createPDF(this.state.itemId, this.state.items, this.state.language);
    }
    
    createPDF(itemId, items, language) {
        this.setState({
            isLoaded : false,
            itemId: itemId,
            items: items,
            language: language
        });
        let sItemId = "?itemId="+itemId 
        axios.get(getAppUrl() + '/gatebackend/rest/content/createPDF/'+this.state.nextMask + sItemId+ "&language="+language+ "&items="+items, 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                console.log(res.data);
                this.setState({
                    isLoaded : true,
                    posts: res.data,
                    itemId: itemId
                }) 
            })
            .catch((error) => {
       //         localStorage.removeItem("app-token");
         //       this.props.history.push("/login");
            });  
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    downloadPDF(e) {
        const linkSource = "data:application/pdf;base64,"+ this.state.posts.url;
        const downloadLink = document.createElement("a");
        const fileName = this.state.posts.filename;

        downloadLink.href = this.state.posts.url;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    render() {
        const {isLoaded, posts} = this.state;
        const { pageNumber, numPages, zoom } = this.state;
        if(!isLoaded) {
            return (<>
                    <div class="Vivacardheader"></div>
                    <div>    
                        <div style={{width: "50%", margin: "0 auto"}}><Loader/><br/>{this.state.noticeMsg}</div>
                    </div>
                    </>)
        }
        else {
            return (
                <>     
                    <div class="Vivacardheader">
                        <Button
                            type="button"
                            className="buttonIcon"
                            onClick={((e) => this.refresh(e))}
                            style={{
                                verticalAlign: 'middle',
                            }}
                            >
                            <span className="fa fa-refresh"></span>
                        </Button>	
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.back(e))}>
                            <span className="fa fa-backward"></span>
                        </Button> 
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.forward(e))}>
                            <span className="fa fa-forward"></span>
                        </Button>                        
                            Seite {pageNumber} von {numPages} 
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.zoomOut(e))}>
                            <span className="fa fa-search-minus"></span>
                        </Button> 
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.zoomIn(e))}>
                            <span className="fa fa-search-plus"></span>
                        </Button>   
                        <span className="fa fa-search-plu">{zoom}</span>    
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.downloadPDF(e)}>
                            <span className="fa fa-download"></span>
                        </Button>                        																											
                    </div>   
                    {posts.result === "success" ? (                        
                        <Paper>
                            <div style={{maxHeight: "800px", overflow: "scroll"}}>
                                <Document
                                    file={posts.url}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={pageNumber} scale={zoom} width={800}  />
                                </Document>
                            </div>
                        </Paper>
                    ) : (
                        <>
                            <div>    
                                <div style={{width: "50%", margin: "0 auto"}}>
                                    PDF konnte nicht geladen werden<br/>
                                    <a href={posts.messageTxt} target="_blank">Messsage file</a>
                                </div>
                            </div>                        
                        </>
                    )}
                </>
        );
    }
    }
}


export default PDFDisplay;