import React, { Component } from 'react';
import axios from 'axios';
import SortableTree, { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath, getNodeAtPath, toggleExpandedForAll, defaultSearchMethod, map as mapTree } from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import Button from '@material-ui/core/Button';
import 'font-awesome/css/font-awesome.min.css';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CustomNodeContentRenderer from './blocks/CustomNodeInlineEdit/CustomNodeContentRenderer';
import Switch from '@material-ui/core/Switch';
import {getAppUrl} from '../helpers/Defaults';
import {getToken, getExtranetToken} from '../helpers/Token';
import Loader from './blocks/Loader'
import * as ReactQuill from 'react-quill'; // Typescript
import Menu from '@material-ui/core/Menu';
import NativeSelect from '@material-ui/core/NativeSelect';
import { useSnackbar, withSnackbar } from 'notistack';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

function getLanguageID(lang, itemLanguages) {
    let sL = 3660;
    itemLanguages.map(function(d) {
        if(d.iso == lang)
            sL = d.refChooseList;
    });
    return sL;
}


function getLanguageLabel(lang, itemLanguages) {
    let sL = "Deutsch";
    itemLanguages.map(function(d) {
        if(d.iso == lang)
            sL = d.label;
    });
    return sL;
}


class HandleText extends Component {

    constructor(props) {
        super(props);

        let txt = [];
        let elem = {};
        elem.label = "Headline";
        elem.sourceId = -1;
        elem.destinationId = -1;
        elem.display = "block";
        elem.txtTypId = 3639;
        elem.kind = "mainText";
        elem.sourceKatalog = "";
        elem.sourceInternet = "";
        elem.destinationKatalog = "";
        elem.destinationInternet = "";
        elem.advPoint= [];
        txt.push(elem);
        let elem1 = {};
        elem1.label = "SubHeadline";
        elem1.sourceId = -1;
        elem1.destinationId = -1;
        elem1.display = "none";
        elem1.txtTypId = 3659;
        elem1.kind = "mainText";
        elem1.sourceKatalog = "";
        elem1.sourceInternet = "";
        elem1.destinationKatalog = "";
        elem1.destinationInternet = "";        
        elem1.advPoint= [];
        
        txt.push(elem1);
        let elem2 = {};
        elem2.sourceId = -1;
        elem2.destinationId = -1;
        elem2.label = "Description";
        elem2.kind = "mainText";
        elem2.display = "none";
        elem2.txtTypId = 3638;
        elem2.sourceKatalog = "";
        elem2.sourceInternet = "";
        elem2.destinationKatalog = "";
        elem2.destinationInternet = "";  
        elem2.advPoint= [];

        txt.push(elem2);
        let elem3 = {};
        elem3.sourceId = -1;
        elem3.destinationId = -1;
        elem3.label = "Contents";
        elem3.display = "none";
        elem3.kind = "mainText";
        elem3.txtTypId = 3655;
        elem3.sourceKatalog = "";
        elem3.sourceInternet = "";
        elem3.destinationKatalog = "";
        elem3.destinationInternet = "";        
        elem3.advPoint= [];

        txt.push(elem3);
        let elem4 = {};
        elem4.sourceId = -1;
        elem4.destinationId = -1;
        elem4.label = "Vorteilspunkte";
        elem4.display = "none";
        elem4.txtTypId = -1;
        elem4.kind = "advText";
        elem4.sourceKatalog = "";
        elem4.sourceInternet = "";
        elem4.destinationKatalog = "";
        elem4.destinationInternet = "";        
        elem4.advPoint= [];
        txt.push(elem4);

        let elem5 = {};
        elem5.sourceId = -1;
        elem5.destinationId = -1;
        elem5.label = "Details";
        elem5.display = "none";
        elem5.txtTypId = -1;
        elem5.kind = "details";
        elem5.sourceKatalog = "";
        elem5.sourceInternet = "";
        elem5.destinationKatalog = "";
        elem5.destinationInternet = "";        
        elem5.advPoint= [];
        txt.push(elem5);



        this.state = {
            counter: -1,
            isLoaded : false,
            adviceOpen: false,
            adviceOpen1: false,
            show: false,
            texte: txt,
            anchorElFlag: false,
            anchorElFlagD: false,
            languageSource: "de",
            languageDestination: "en",
            itemLanguages: [],
            columnsFilter: [],
            artnr: "",
            sticky: false,
            openTranslate: false,
            advice: false,
            isLoading: false
        };

        this.renderWidget = this.renderWidget.bind(this);
        this.open = this.open.bind(this);
        this.reload = this.reload.bind(this);
        this.handleAdvice = this.handleAdvice.bind(this);
        this.changeItemLanguageSource = this.changeItemLanguageSource.bind(this);
        this.changeItemLanguageDestination = this.changeItemLanguageDestination.bind(this);
		this.handleClickFlag = this.handleClickFlag.bind(this);
        this.handleCloseFlag = this.handleCloseFlag.bind(this);        
		this.handleClickFlagD = this.handleClickFlagD.bind(this);
        this.handleCloseFlagD = this.handleCloseFlagD.bind(this);   
        this.handleTranslate = this.handleTranslate.bind(this); 
        this.dohandleTranslate = this.dohandleTranslate.bind(this); 
        this.switch = this.switch.bind(this);    
        this.save = this.save.bind(this); 
        this.changeDetailFilter = this.changeDetailFilter.bind(this); 
    }	

    handleAdvice(e) {
        this.setState({advice: false});
    }

    save(openTranslate) {
        let currentColumns = this.state.currentColumns;
        let  article = this.state.article;;
        let checkOK = true;
        currentColumns.map(function(cC) {
            if(cC.mandatory === true) {
                let found = false;
                Object.keys(article.values).map(function(child) {
                    if(child === cC.key) {
                        if(article.values[child][0] == "" || article.values[child][0] == undefined|| article.values[child][0] == 0)
                            console.log("kein Wert");
                        else
                            found = true;

                    }
                });
                if(found === false) {
                    checkOK = false;
                }
            }
        });

        if(checkOK === false) {
            this.setState({
                advice: true,
            })             
        }

        let itemID = this.props.match.params.itemId;
        let txtElements = this.state.texte;
                                console.log(txtElements);

        
        txtElements.map(function(txtElement) {
            if(txtElement.kind == 'details')
                txtElement.article = article;
        })
        axios.post(getAppUrl() + '/gatebackend/rest/content/setTxt?itemId='+itemID+ "&sourceLanguage="+this.state.languageSource + "&destLanguage=" +this.state.languageDestination, 
            txtElements,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }).then(res => {
            if(openTranslate === true) {
                this.dohandleTranslate(this.state.txtElem, this.state.which);
            }
                        let txtElements = this.state.texte;
                        let languageSource = this.state.languageSource;
                        let languageDestination = this.state.languageDestination;
                        let itemLanguages = this.state.itemLanguages;
                        res.data.map(function(d) {
                            txtElements.map(function(txtElement) {
                                if(d.kind == "mainText") {
                                    if(d.texttyp == txtElement.txtTypId && d.sprache == getLanguageID(languageSource, itemLanguages)) {
                                        txtElement.sourceId = d.id;
                                        txtElement.sourceKatalog = d.katalogText;
                                        txtElement.sourceInternet = d.internetText;
                                        txtElement.sourceStatus = d.status;
                                    }
                                    if(d.texttyp == txtElement.txtTypId && d.sprache == getLanguageID(languageDestination, itemLanguages)) {
                                        txtElement.destinationId = d.id;
                                        txtElement.destinationKatalog = d.katalogText;
                                        txtElement.destinationInternet = d.internetText;
                                        txtElement.destinationStatus = d.status;
                                    }
                                }
                                if(txtElement.kind == "advText") {
                                    if(d.sprache == getLanguageID(languageSource, itemLanguages)) {
                                        txtElement.sourceId = d.id;
                                        txtElement.sourceadvPoint = d.advPoint;  
                                        txtElement.sourceStatus = d.status;

                                    }
                                }
                                if(txtElement.kind == "advText") {
                                    if(d.sprache == getLanguageID(languageDestination,itemLanguages)) {
                                        txtElement.destinationId = d.id;
                                        txtElement.destinationadvPoint = d.advPoint;
                                        txtElement.destinationStatus = d.status;

                                    }
                                }                                
                            });
                        });

            this.setState({
                texte: txtElements,
                sticky: false
            }) 
            this.props.enqueueSnackbar(this.props.t('Änderungen gespeichert'), { variant: 'info' });

        })
        .catch((error) => {
            console.log(error);
        });
        
    }

    adviceClose() {
        this.setState({
            adviceOpen: false
        }) ;

    }

    adviceClose1() {
        this.setState({
            adviceOpen1: false
        }) ;
    }

    adviceCloseSave() {
        this.setState({
            adviceOpen1: false,
        }) ;
        this.save(true);
    }

    handleTranslate(txtElem, which) {
        if(this.state.sticky === true) {
            this.setState({
                adviceOpen1: true,
                txtElem:  txtElem,
                which: which
            }) ;
        }
        else
            this.dohandleTranslate(txtElem, which)
    }

    dohandleTranslate(txtElem, which) {
        this.setState({
            adviceOpen: true
        }) ;
        let itemID = this.props.match.params.itemId;
        axios.get(getAppUrl() + '/gatebackend/rest/content/getTxtranslation?itemId='+itemID + "&destLanguage=" + this.state.languageDestination + "&textTyp="+txtElem.txtTypId + "&which="+which, 
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }).then(res => {
            let txtElements = this.state.texte;
            let d = res.data;
                txtElements.map(function(txtElement) {
                    if(d.texttyp == txtElement.txtTypId && which == "Katalog") {
                        txtElement.destinationKatalog = d.katalogText;
                        txtElement.destinationStatus = d.status;

                    }
                    if(d.texttyp == txtElement.txtTypId && which == "Internet") {
                        txtElement.destinationInternet = d.internetText;
                        txtElement.destinationStatus = d.status;

                    }                    
                    if(which == "ADV") {
                        txtElement.destinationadvPoint = d.advPoint;
                        txtElement.destinationStatus = d.status;

                    }                    
                });

            this.setState({
                isLoaded : true,
                texte: txtElements,
                adviceOpen: false
            }) 
            

        })
        .catch((error) => {
            console.log(error);
        });
    }


	changeItemLanguageSource(language) {
        let txtElements = this.state.texte;
        let itemLanguages = this.state.itemLanguages;
        let languageDestination =this.state.languageDestination;
        this.state.posts.map(function(d) {
            txtElements.map(function(txtElement) {
                txtElement.sourceKatalog = "";
                txtElement.destinationKatalog = "";
                txtElement.sourceInternet = "";
                txtElement.destinationInternet = "";
                txtElement.sourceId = -1;
                txtElement.sourceadvPoint =[];
            });
        });          
        this.state.posts.map(function(d) {
            txtElements.map(function(txtElement) {

                if(d.texttyp == txtElement.txtTypId && d.sprache == getLanguageID(language, itemLanguages)) {
                    txtElement.sourceKatalog = d.katalogText;
                    txtElement.sourceInternet = d.internetText;
                    txtElement.sourceId = d.id;
                    txtElement.sourceStatus = d.status;

                }
                if(d.texttyp == txtElement.txtTypId && d.sprache == getLanguageID(languageDestination, itemLanguages)) {
                    txtElement.destinationKatalog = d.katalogText;
                    txtElement.destinationInternet = d.internetText;
                    txtElement.destinationId = d.id;

                }
                if(txtElement.kind == "advText" && d.kind == "advText") {
                    if(d.sprache == getLanguageID(language, itemLanguages)) {
                        txtElement.sourceadvPoint = d.advPoint;  
                        txtElement.sourceId = d.id;
                        txtElement.sourceStatus = d.status;

                    }
                }

            });
        });         
		this.setState({
			languageSource: language,
            anchorElFlag: null,
            texte: txtElements,
		})
    }

    switch(txtTypId, which, how) {
        let txtElements = this.state.texte;
        txtElements.map(function(txtElement) {
            if(txtTypId == txtElement.txtTypId && which == "source" && how == "IK" ) {
                txtElement.sourceKatalog = txtElement.sourceInternet;
            }
            if(txtTypId == txtElement.txtTypId && which == "source" && how == "KI" ) {
                txtElement.sourceInternet = txtElement.sourceKatalog;
            }
            if(txtTypId == txtElement.txtTypId && which == "dest" && how == "IK" ) {
                txtElement.destinationKatalog = txtElement.destinationInternet;
            }
            if(txtTypId == txtElement.txtTypId && which == "dest" && how == "KI" ) {
                txtElement.destinationInternet = txtElement.destinationKatalog;
            }

        });
		this.setState({
            texte: txtElements,
		})
    }

	changeItemLanguageDestination(language) {
        let itemLanguages = this.state.itemLanguages;
        let txtElements = this.state.texte;
        let languageSource =this.state.languageSource;
        this.state.posts.map(function(d) {
            txtElements.map(function(txtElement) {
                txtElement.sourceKatalog = "";
                txtElement.destinationKatalog = "";
                txtElement.sourceInternet = "";
                txtElement.destinationInternet = "";
                txtElement.destinationId = -1;
                txtElement.destinationadvPoint =[];
                
            });
        });                 
        this.state.posts.map(function(d) {
            txtElements.map(function(txtElement) {

                if(d.texttyp == txtElement.txtTypId && d.sprache == getLanguageID(languageSource, itemLanguages)) {
                    txtElement.sourceKatalog = d.katalogText;
                    txtElement.sourceInternet = d.internetText;
                    txtElement.sourceId = d.id;

                }
                if(d.texttyp == txtElement.txtTypId && d.sprache == getLanguageID(language, itemLanguages)) {
                    txtElement.destinationKatalog = d.katalogText;
                    txtElement.destinationInternet = d.internetText;
                    txtElement.destinationId = d.id;
                    txtElement.destinationStatus = d.status;

                }
                if(txtElement.kind == "advText" && d.kind == "advText") {
                    if(d.sprache == getLanguageID(language, itemLanguages)) {
                        txtElement.destinationadvPoint = d.advPoint;
                        txtElement.destinationId = d.id;
                        txtElement.destinationStatus = d.status;
  
                    }
                }                                

            });
        });        
		this.setState({
			languageDestination: language,
            anchorElFlag: null,
            texte: txtElements,
		})
    }
    
	handleCloseFlag() {
		this.setState({
			anchorElFlag: null
		})
	}	
	
	handleClickFlag(event) {
		this.setState({
			anchorElFlag: event.currentTarget,
		})
    }

	handleCloseFlagD() {
		this.setState({
			anchorElFlagD: null
		})
	}	
	
	handleClickFlagD(event) {
		this.setState({
			anchorElFlagD: event.currentTarget,
		})
    }
    
    open(txtElem) {

        let txtElements = this.state.texte;
        txtElements.map(function(txtElement) {
            if(txtElem.label == txtElement.label) {
                if(txtElement.display == "block") {
                    txtElement.display = "none";
                }
                else
                    txtElement.display = "block";
            }
        })
        this.setState({
            texte: txtElements
        });
    }

    componentDidMount() {       
        this.reload("reload", this.props.match.params.itemId);
    }

    async reloadDetails(id) {
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }

        let token =  await getEToken();
        this.setState({
            isLoaded : false,
            token: token
        }) 
        axios.get(getAppUrl() +"/extranet/rest/articles/getAllClasses", 
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res5 => {        
                axios.get(getAppUrl() +"/extranet/rest/articles/getAllColumns", 
                    {
                        headers: { 
                            "Authorization": " Bearer " + token
                        }
                    }).then(res4 => {

                        axios.get(getAppUrl() +"/extranet/rest/articles/allArticles?offset=0&searchTerm=", 
                            {
                                headers: { 
                                    "Authorization": " Bearer " + token
                                }
                            }).then(res2 => {

                                axios.get(getAppUrl() +"/extranet/rest/articles/classes-columns", 
                                    {
                                        headers: { 
                                            "Authorization": " Bearer " + token
                                        }
                                    }).then(res1 => {
                                    axios.get(getAppUrl() +"/extranet/rest/articles/" + id +"?language=de&disableCheck=true", 
                                        {
                                            headers: { 
                                                "Authorization": " Bearer " + token
                                            }
                                        }).then(res => {
                                            let articleLabel = "Unbekannt";
                                            let artcile = res.data.articles[0];
                                            let currentColumns = [];

                                            res1.data.map(function(cl) {
                                                if(cl.classes !== undefined ) {
                                                    (Object.keys(cl.classes)).map(function(k1) {
                                                        if(cl.classes[k1].columns !== undefined && cl.classes[k1].key === artcile.product_class) {
                                                            cl.classes[k1].columnsExt.map(function(co) {
                                                                let p = res4.data.columns[co.key];
                                                                p.mandatory = co.mandatory;
                                                                p.dbId = co.dbId;
                                                                currentColumns.push(p);
                                                            
                                                            })
                                                        }
                                                    })
                                                }
                                            })


                                            this.setState({
                                                article: artcile,
                                                articleLabel: articleLabel,
                                                isLoaded : true,
                                                currentColumns: currentColumns,
                                                classColumns: res1.data,
                                                articles: res2.data,
                                                columns: res4.data,
                                                classes: res5.data,
                                                currentMaster: id,
                                                searchMaster: id,                                                 
                                            })   
                                    });  
                                });  
                        
                        });
                });  
            });
    }


    reload(e, itemID) {  
        this.setState({
            isLoaded : false,
            posts: []
        });
            
		axios.get(getAppUrl() + '/gatebackend/rest/content/languages',  {
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}).then(res1 => {	

                    axios.get(getAppUrl() + '/gatebackend/rest/content/getTxt?itemId='+itemID, 
                    {
                        headers: { 
                            "Authorization": " Bearer " + getToken()
                        }
                    }).then(res => {
console.log(res.data);
                        let txtElements = this.state.texte;
                        let artnr = "";
                        res.data.map(function(d) {
                            txtElements.map(function(txtElement) {
                                if(d.kind == "mainText") {
                                    if(d.texttyp == txtElement.txtTypId && d.sprache == 3660) {
                                        artnr = d.artnr;
                                        txtElement.sourceId = d.id;
                                        txtElement.sourceKatalog = d.katalogText;
                                        txtElement.sourceInternet = d.internetText;
                                        txtElement.sourceStatus = d.status;
                                    }
                                    if(d.texttyp == txtElement.txtTypId && d.sprache == 3661) {
                                        artnr = d.artnr;
                                        txtElement.destinationId = d.id;
                                        txtElement.destinationKatalog = d.katalogText;
                                        txtElement.destinationInternet = d.internetText;
                                        txtElement.destinationStatus = d.status;
                                    }
                                }
                                if(txtElement.kind == "advText") {

                                    if(d.sprache == 3660) {
                                        artnr = d.artnr;
                                        
                                        txtElement.sourceId = d.id;
//                                        if(d.advPoint.length == 0);
//                                            txtElement.sourceId = -1;
                                        txtElement.sourceadvPoint = d.advPoint;  
                                        txtElement.sourceStatus = d.status;

                                    }
                                }
                                if(txtElement.kind == "advText") {
                                    if(d.sprache == 3661) {
                                        artnr = d.artnr;
                                        txtElement.destinationId = d.id;
  //                                      if(d.advPoint.length == 0);
  //                                          txtElement.destinationId = -1;
                                        txtElement.destinationadvPoint = d.advPoint;
                                        txtElement.destinationStatus = d.status;

                                    }
                                }                                
                            });
                        });


                        this.reloadDetails(artnr);

                        this.setState({
                            artnr: artnr,
                            isLoaded : false,
                            posts: res.data,
                            texte: txtElements,
                            itemLanguages: res1.data					

                        }) 
                        

                    })
                    .catch((error) => {
                        console.log(error);
                    });
				})
				.catch((error) => {
                    console.log(error);
				});

    }

    renderStatus(status, txtElem, which) {
        const change = (id, kind) => event =>{
            let val = event.target.value;
            let txtElements = this.state.texte;
            txtElements.map(function(txtElement) {
                if(txtElement.txtTypId == id && kind == "source") {
                    txtElement.sourceStatus = parseInt(val);
                }
                if(txtElement.txtTypId == id && kind == "destination") {
                    txtElement.destinationStatus = parseInt(val);
                }
                if(id == "ADV" && kind == "source") {
                    txtElement.sourceStatus = parseInt(val);
                }
                if(id == "ADV"  && kind == "destination") {
                    txtElement.destinationStatus = parseInt(val);
                }
            
            });
            this.setState({
                texte: txtElements,
            })            

        };
        return <>Status:&nbsp;
                <NativeSelect
                    value={status}
                    onChange={change(txtElem, which)}
                    inputProps={{
                        name: 'bookmarkfolder',
                        id: 'age-native-helper',
                    }}
                >
                    <option value={3667}>Automatisch übersetzt</option>
                    <option value={3671}>Überarbeitung notwendig</option>
                    <option value={3669}>Übersetzung ausstehend</option>
                    <option value={3670}>Übersetzung in Auftrag gegeben</option>
                    <option value={3666}>Vollständig übersetzt</option>
                </NativeSelect>
            </>
    }

    renderAdvPoint(text, kind) {
        const handleChangeXY = (id, kind) => event =>{
            let val = event.target.value;
            let txtElements = this.state.texte;
                txtElements.map(function(txtElement) {
                    if(txtElement.kind == "advText") {
                        if(kind == "source")
                            txtElement.sourceadvPoint[id -1] = val;
                        if(kind == "destination")
                            txtElement.destinationadvPoint[id -1] = val;
                    }
                });
            this.setState({
                texte: txtElements,
            })            
        };
        if(text.length == 0) {
            for(let i = 0; i < 10; i++) {
                text.push("");
            }
        }
        let counter = 0;
        return  text.map(function(d) {
                counter++;
                return <TextField 	style={{width: "100%"}}
                                        name={counter + "_" + kind}
                                        label={"Vorteilspunkt" + counter}							
                                        value={d}
                                        onChange={handleChangeXY(counter, kind)}
                                        margin="normal"
                />  
        })      
    }

    


    renderWidget(value, txtElem, which) {
        const handleEChange = (v, id, w) => {
            let sticky = this.state.sticky;
            if(this.state.languageSource == "de") {
                sticky = true;
            }
            let txtElements = this.state.texte;
            txtElements.map(function(txtElement) {
                if(id == txtElement.txtTypId && which == "sI" ) {
                    txtElement.sourceInternet = v;
                
                }
                if(id == txtElement.txtTypId && which == "dI") {
                    txtElement.destinationInternet = v;
                }
                if(id == txtElement.txtTypId && which == "sK") {
                    txtElement.sourceKatalog = v;
                }
                if(id == txtElement.txtTypId && which == "dK") {
                    txtElement.destinationKatalog = v;
                }

            });
            this.setState({
                texte: txtElements,
                sticky: sticky
            })
            
        };    
        return <div style={{height: "240px"}}>
                    <ReactQuill style={{height: "200px"}}
                                value={value}
                                id={"field_"}
                                ref={"field_"}
                                onChange={(	text: string, 
                                            delta: any, 
                                            source: string, 
                                            editor: any) => {
                                                if (source == 'user') {
                                                    handleEChange(text, txtElem.txtTypId, which);
                                                }
                                            }} 
                    />						
                </div> 
    }

    changeDetail(e, key, article) {
        let found = false;
        Object.keys(article.values).map(function(child) {
            if(child === key) {
                found = true;
                article.values[child][0] = e.target.value;
            }
        });
        if(found === false) {
            let m = [];
            m.push(e.target.value);
            article.values[key] = m;
        }
        this.setState({
            article: article,
        })   
    }

    changeDetailFilter(e, key) {
        let x = this.state.columnsFilter;
        x[key] = e.target.value;
        this.setState({
            columnsFilter: x,
        })         
    }

    handleCreate(value, key, artnr) {

        let currentColumns = this.state.currentColumns;
        let  article = this.state.article;;
        let checkOK = true;
        currentColumns.map(function(cC) {
            if(cC.mandatory === true) {
                let found = false;
                Object.keys(article.values).map(function(child) {
                    if(child === cC.key) {
                        if(article.values[child][0] == "" || article.values[child][0] == undefined|| article.values[child][0] == 0)
                            console.log("kein Wert");
                        else
                            found = true;

                    }
                });
                if(found === false) {
                    checkOK = false;
                }
            }
        });

        if(checkOK === false) {
            this.setState({
                advice: true,
            })             
        }

        let itemID = this.props.match.params.itemId;
        let txtElements = this.state.texte;
                                console.log(txtElements);

        
        txtElements.map(function(txtElement) {
            if(txtElement.kind == 'details')
                txtElement.article = article;
        })
        axios.post(getAppUrl() + '/gatebackend/rest/content/setTxt?itemId='+itemID+ "&sourceLanguage="+this.state.languageSource + "&destLanguage=" +this.state.languageDestination, 
            txtElements,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }).then(res => {
                  let obj = {};
        obj.name = value; 
        obj.key = key; 
        this.setState({ isLoading: true });

        axios.post(getAppUrl() + '/extranet/rest/articles/column-value-add', 
                    obj, {
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}).then(res1 => {	

                    this.reloadDetails(artnr);

                    this.setState({
                        isLoading : false,
                    }) 
                        

                    })
                    .catch((error) => {
                        console.log(error);
                    });

            this.setState({
                texte: txtElements,
                sticky: false
            }) 
            this.props.enqueueSnackbar(this.props.t('Änderungen gespeichert'), { variant: 'info' });

        })
        .catch((error) => {
            console.log(error);
        });

  
    }   

    handleChange = (selectedOption) => {
        console.log(`Option selected:`, selectedOption);
        let article = selectedOption.article;
        let key = selectedOption.key;
        let found = false;
        Object.keys(article.values).map(function(child) {
            if(child === key) {
                found = true;
                article.values[child][0] = selectedOption.value;
            }
        });
        if(found === false) {
            let m = [];
            m.push(selectedOption.value);
            article.values[key] = m;
        }
        this.setState({
            article: article,
        })   

    }



    renderSelect(entry, article) {
        let vKey = -1;
        let options = [];
        let t = this;
        
        let yy = Object.keys((this.state.columns.columns[entry.key]).options).map(key => {
            let e = {};
            e.value = key;
            e.label = (t.state.columns.columns[entry.key]).options[key].name;
            e.key = entry.key;
            e.article = article;
            options.push(e);

        }
        )
        Object.keys(article.values).map(function(child) {
            
            if(child === entry.key) {

                let count = -1;
                options.map(o => {
                    count++;
                    if(article.values[child] == o.value)
                        vKey = count;
                })
                
            }
        });

        return  <> 
                <CreatableSelect style={{width: "200px"}}
                        defaultValue={options[vKey]}
                        onChange={this.handleChange}
                        onCreateOption={e => this.handleCreate(e, entry.key, article.number)}
                        isDisabled={this.state.isLoading}
                        isLoading={this.state.isLoading}                        
                        options={options} />
                <hr/>
                </>        
    }  

    renderSelectx(entry, article) {
        let filter = this.state.columnsFilter[entry.key];

        let yy = Object.keys((this.state.columns.columns[entry.key]).options).map(key => 
            <option value={key}>{(this.state.columns.columns[entry.key]).options[key].name}</option>
        )
        let xx = yy;
        for (let k of yy.keys()) {
                            xx.splice(k, 1);

        }
        let value = 0;
        var key = "";
        Object.keys(article.values).map(function(child) {
            if(child === entry.key) {
                value = article.values[child];
                key = child;
            }
        });
        return  <> 
                <TextField 	style={{width: "100px"}}
                                        name={"elementColumnValue_" + entry.key}
                                        label={"Filter"}							
                                        value={this.state.columnsFilter[entry.key]}
                                        onChange={e => this.changeDetailFilter(e, entry.key)} 
                                        margin="normal"
                /> <br/>
                <select name={"elementColumnValue"} 
                    onChange={e => this.changeDetail(e, entry.key, article)} 
                    value={value}>
                    <option value="0">Keine</option>
                    {xx}
                </select>
                <hr/>
                </>        
    }    

    render() {
        const {isLoaded, texte} = this.state;
        
        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {
            return (<>
                <div className="Vivacard">
                    <div className="Vivacardheader">
                        Textübersicht für Artikel: {this.state.artnr}
                        <span className="pull-right">
                            Ziel
                            <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleClickFlagD(e)}>              
							    <span className="fa fa-flag"></span>
							</Button>
                            <Menu	id="simple-menuFlagD "
                                    anchorEl={this.state.anchorElFlagD}
                                    keepMounted
                                    open={Boolean(this.state.anchorElFlagD)}
                                    onClose={this.handleCloseFlagD}
                            >											
                                {this.state.itemLanguages.map(iT => (
                                    <>
                                    <MenuItem onClick={e => this.changeItemLanguageDestination(iT.iso)}><span className={iT.iso == this.state.languageDestination ? "fa fa-check" : ""}></span>&nbsp;{iT.label}</MenuItem>
                                    </>
                                ))}
                            </Menu>
                        </span>                         
                        <span className="pull-right">
                            Quelle
                            <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleClickFlag(e)}>              
							    <span className="fa fa-flag"></span>
							</Button>
                            <Menu	id="simple-menuFlagQ "
                                    anchorEl={this.state.anchorElFlag}
                                    keepMounted
                                    open={Boolean(this.state.anchorElFlag)}
                                    onClose={this.handleCloseFlag}
                            >											
                                {this.state.itemLanguages.map(iT => (
                                    <>
                                    <MenuItem onClick={e => this.changeItemLanguageSource(iT.iso)}><span className={iT.iso == this.state.languageSource ? "fa fa-check" : ""}></span>&nbsp;{iT.label}</MenuItem>
                                    </>
                                ))}
                            </Menu>
                        </span>
                       
                    </div>
                    <br></br>
                    <div className="Vivacardbody vivaGridBody resizeMain" >
                    {texte.map(text => (<>
                        <div className="row">
                            <div className="col-sm">
                                <div className="Vivacardheader" style={{backgroundColor: "rgb(67, 160, 71)"}}>
                                    {text.label}
                                    <span className="pull-right"> 
                                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.open(text)}>
                                            {text.display == "block" ?  (<span className="fa fa-arrow-down"></span>) :(<span className="fa fa-arrow-left"></span>)}
                                        </Button>                                    
                                    </span>                                     
                                </div>
                            </div>
                        </div>
                        
                        <div style={{display: text.display}}>
                            {text.kind == "mainText" ? (
                                <>
                                <div className="row">
                                    <div className="col-sm">
                                        <div style={{height: "40px"}}>
                                        {getLanguageLabel(this.state.languageSource, this.state.itemLanguages)}
    &nbsp;
                                        Internet
                                        <span className="pull-right">
                                            {this.renderStatus(text.sourceStatus, text.txtTypId, "source")}
                                        </span>

                                        </div>
                                        {this.renderWidget(text.sourceInternet, text, "sI")} 
                                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.switch(text.txtTypId, "source", "IK")}>              
                                            <span className="fa fa-caret-down"></span>
                                        </Button>
                                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.switch(text.txtTypId, "source", "KI")}>              
                                            <span className="fa fa-caret-up"></span>
                                        </Button>
        <br/>
        <br/>

                                    </div>
                                    <div className="col-sm">
                                        <div style={{height: "40px"}}>
                                        {getLanguageLabel(this.state.languageDestination, this.state.itemLanguages)}
    &nbsp;
                                        Internet
                                        <span className="pull-right">
                                            {this.renderStatus(text.destinationStatus, text.txtTypId, "destination")}
                                        </span>
                                        </div>
                                        {this.renderWidget(text.destinationInternet, text, "dI")} 
                                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleTranslate(text, "Internet")}>              
                                            <span className="fa fa-globe"></span>
                                        </Button>
                                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.switch(text.txtTypId, "dest", "IK")}>              
                                            <span className="fa fa-caret-down"></span>
                                        </Button>
                                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.switch(text.txtTypId, "dest", "KI")}>              
                                            <span className="fa fa-caret-up"></span>
                                        </Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        {getLanguageLabel(this.state.languageSource, this.state.itemLanguages)}
    &nbsp;

                                        Katalog 
                                        {this.renderWidget(text.sourceKatalog, text, "sK")} 
                                    </div>
                                    <div className="col-sm">
                                        {getLanguageLabel(this.state.languageDestination, this.state.itemLanguages)}
    &nbsp;

                                        Katalog- {text.destinationId}
                                        {this.renderWidget(text.destinationKatalog, text, "dK")} 
                                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleTranslate(text, "Katalog")}>              
                                            <span className="fa fa-globe"></span>
                                        </Button>

                                    </div>
                                </div>
                            </> ) :
                            (
                               <>
                               {text.kind == "advText" ? (
                                    <div className="row">
                                        <div className="col-sm">
                                            <div style={{height: "40px"}}>
                                            {getLanguageLabel(this.state.languageSource, this.state.itemLanguages)}                             
        &nbsp;
                                            <span className="pull-right">
                                                {this.renderStatus(text.sourceStatus, "ADV", "source")}
                                            </span>
                                            </div>
                                            {this.renderAdvPoint(text.sourceadvPoint, "source")}
                                            

                                        </div>
                                        <div className="col-sm">
                                            <div style={{height: "40px"}}>
                                            {getLanguageLabel(this.state.languageDestination, this.state.itemLanguages)}
        &nbsp;
                            {text.destinationId}
                                            <span className="pull-right">
                                                {this.renderStatus(text.destinationStatus, "ADV", "destination")}
                                            </span>
                                            </div>

                                            {this.renderAdvPoint(text.destinationadvPoint, "destination")}
                                            
                                            <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleTranslate(text, "ADV")}>              
                                                <span className="fa fa-globe"></span>
                                            </Button>

                                        </div>
                                    </div>

                               ) : (<>
                                    {text.kind == "details" ? (
                                        <>
                                        {this.state.currentColumns.map(co => (
                                            <>
                                            {co.name} {co.mandatory ? (<> *</>) : (<></>)}<br/>
                                                {this.renderSelect(co, this.state.article)}
                                            <br/><br/>
                                            </>
                                        ))}

                                        </>
                                        ) : (<>
                                        </>)}
                               </>)}
                               </> 
                            )}
                        </div>

                        </>))}


                    </div>
                    <br/>
                    * Eingabe zwingend erforderlich
                    <br/>
                    <div class=" clearmargin gridBottom">
                        <Button onClick={((e) => this.save(false))} color="primary">
                            speichern
                        </Button>                        
                        <Button onClick={e => this.props.history.push("/maske/2/101/" + this.props.match.params.itemId)}>
                            zurück
                        </Button>                        
                    </div>
                </div>
                <Dialog  open={this.state.adviceOpen1}
                            fullWidth="sm"
                            maxWidth="sm"
                            onClose={((e) => this.adviceClose1(e))} 
                            aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Hinweis</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Sie haben den Deutschen Text geändert, dieser dient als Ursprung für Übersetzungen.
                                    <br/>Möchten Sie diesen vorabspeichern?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={((e) => this.adviceClose1(e))} color="primary">
                                    Abbbruch
                                </Button>
                                <Button onClick={((e) => this.adviceCloseSave(e))} color="primary">
                                    Speichern
                                </Button>
                            </DialogActions>
                </Dialog>
                
                <Dialog  open={this.state.adviceOpen}
                            fullWidth="sm"
                            maxWidth="sm"
                            onClose={((e) => this.adviceClose(e))} 
                            aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Hinweis</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Der Text wird aktuell übersetzt
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={((e) => this.adviceClose(e))} color="primary">
                                    OK
                                </Button>
                            </DialogActions>
                </Dialog>

                <Dialog onClose={((e) => this.handleAdvice(e))} 
                        aria-labelledby="customized-dialog-title" open={this.state.advice}
                        fullWidth="md"
                        maxWidth="md"
                        >
                    <DialogTitle id="customized-dialog-title" onClose={((e) => this.handleAdvice(e))}>
                        Hinweis
                        </DialogTitle>
                        <DialogContent dividers>
                            Der Datensatz enhält bei der Eingabe der Details unvollständige Werte!
                        </DialogContent>
                        <DialogActions>
                        <Button autoFocus onClick={((e) => this.handleAdvice(e))} color="primary">
                            {this.props.t('OK')}
                        </Button>
                        </DialogActions>
                </Dialog>                
            </>)
       }
                           
    }
}
export default withTranslation()(withSnackbar(HandleText)) ;

