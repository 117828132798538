import React, { Component } from 'react';
import axios from 'axios';
import SortableTree, { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath, getNodeAtPath, toggleExpandedForAll, defaultSearchMethod, map as mapTree } from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import 'font-awesome/css/font-awesome.min.css';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import Menu from '@material-ui/core/Menu';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import InfiniteScroll from "react-infinite-scroll-component";
import {getAppUrl} from '../helpers/Defaults';
import {getToken, getExtranetToken} from '../helpers/Token';
import Loader from './blocks/Loader'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';


function createTreeElement(label, type, key) {
    let elem = {};
    elem.expanded = true;
    elem.title = label;
    elem.key = key;
    elem.id = 1;
    elem.type = type;
    return elem;
}

function generateTree(plElements, classColumns) {
    let master = plElements.articles[0];
    let treeData = [];
console.log("generateTreegenerateTree");;;
    let base = {};
    base.expanded = true;
    base.title= master.number;
    base.id = 1;
    base.children = [];
    let classes = createTreeElement(master.product_class, "class", master.product_class);
    classes.children = [];
    classColumns.map(function(cl) {
        if(cl.classes !== undefined) {
            (Object.keys(cl.classes)).map(function(k) {
                console.log(cl.classes[k].key);
                console.log(master.product_class);
                
                if(cl.classes[k].columns !== undefined && cl.classes[k].key === master.product_class) {
console.log("trefffer==============================================");;;

                    cl.classes[k].columnsExt.map(function(co) {
                        let kv = {};
                        kv.expanded = true;
                        kv.title = co.name;
                        kv.key = co.key;
                        kv.type = "column";
                        kv.dbId = co.dbId;
                        kv.viewOnly = co.viewOnly;
                        kv.mandatory = co.mandatory;
                        kv.catalogColumn = co.catalogColumn;
                        classes.children.push(kv);
                    });
                }
            });        
        }
    });

    base.children.push(classes);


    let details = {};
    details.expanded = true;
    details.title= "Articles";
    details.type = "articles";
    details.id = 2;
    details.children = [];
    details.columns = classes.children;

    if(master.details !== undefined) {
        master.details.map(function(detail) {
            let article = {};
            article.expanded = true;
            article.title= detail.name;
            article.subtitle = detail.number;
            article.id = detail.number;
            article.type = "article";
            article.artnr = detail.number;

            article.children = [];
            classColumns.map(function(cl) {
                if(cl.classes !== undefined) {
                    (Object.keys(cl.classes)).map(function(k) {
                        if(cl.classes[k].columns !== undefined && cl.classes[k].key === master.product_class) {

                            cl.classes[k].columnsExt.map(function(co) {
                                let kv = {};
                                kv.expanded = true;
                                kv.title = co.name;
                                kv.key = co.key;
                                if(detail.values[co.key] !== undefined ) {
                                    kv.value = detail.values[co.key];
                                    kv.subtitle= detail.values[co.key];                                    
                                }
                                else {
                                    kv.value = "";
                                    kv.subtitle= "";
                                }
                                        console.log(detail.values[co.key]);
                                article.children.push(kv);
                            });
                        }
                    }); 
            }
        });

   
            details.children.push(article);
        })
    }
    base.children.push(details);
    
    treeData.push(base);
    return 	treeData;
}

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8
};

class ConfigureMaster extends Component {

    constructor(props) {
		super(props);
        this.state = {
            counter: -1,
            copyShow: false,
            depCounter: -1,
            isLoaded : false,
            show: false,
            treeData: [],
            currentNode: {},
            personName: [],
            deletedNodes: [],
            cars: [],
            confCars: [],
            currentTab: 0,
            currentTabCA: 0,
            currentSearchAdd: "",
            currentSearchSub: "",
            currentMaster: "ME1889",
            searchMaster: "ME1889",
            newMasterNumber: "",
            articleLabel: "huhu",
            offset: 0,
            carOnline: 1,
            articles: [],
            merkliste: [],
            searchfield: "",
            hasMore: true,
            showColumnProps: false, 
            showNewMaster: false,
            showCleanArticle: false,
            cleanArticle: "",
            newMasterClass: "Markise", 
            isLoading: false           
        };
        this.handleDelete = this.handleDelete.bind(this);

        this.handleClean = this.handleClean.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleAdd = this.handleAdd.bind(this);   
        this.searchChangeAdd = this.searchChangeAdd.bind(this);
        this.searchChangeSub = this.searchChangeSub.bind(this);
        this.searchCar = this.searchCar.bind(this);
        this.loadMaster = this.loadMaster.bind(this);
        this.reload = this.reload.bind(this);
        this.change = this.change.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
        this.changeDependecy = this.changeDependecy.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSaveClose = this.handleSaveClose.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleTabChangeCA = this.handleTabChangeCA.bind(this);
        this.catchReturn = this.catchReturn.bind(this);
        this.changeSearch = this.changeSearch.bind(this);
        this.renderSelect = this.renderSelect.bind(this);
        this.renderClasses = this.renderClasses.bind(this);
        this.handleCloseColumnProps = this.handleCloseColumnProps.bind(this);
        this.renderColumnOptions = this.renderColumnOptions.bind(this);
        this.changeColumnValue = this.changeColumnValue.bind(this);
        this.handleAddColumnValue = this.handleAddColumnValue.bind(this);
        this.addArticles = this.addArticles.bind(this);
        this.addArticlesMerkliste = this.addArticlesMerkliste.bind(this);
        this.handleClickFlag = this.handleClickFlag.bind(this);
        this.handleCloseFlag = this.handleCloseFlag.bind(this);       
        this.selectArticle = this.selectArticle.bind(this);
        this.handleCloseMaster = this.handleCloseMaster.bind(this);
        this.handleCleanArticle = this.handleCleanArticle.bind(this);
        this.doCleanArticle = this.doCleanArticle.bind(this);
        
        this.handleAddMaster = this.handleAddMaster.bind(this);
        this.handleOpenNewMaster = this.handleOpenNewMaster.bind(this);
        this.changeMasterClass = this.changeMasterClass.bind(this);
        this.changeAtnr = this.changeAtnr.bind(this);
        this.handleUpdateDetail = this.handleUpdateDetail.bind(this);
    }	

    handleClickFlag(event, id) {
        this.setState({
            currentDetailId: id,
            anchorElFlag: event.currentTarget,
        })
    }

    handleCloseFlag() {
        this.setState({
            anchorElFlag: null
        })
    }

    changeMasterClass(e) {
        this.setState({ newMasterClass: e.target.value });
    }
    


    handleUpdateDetail(e) {
        this.setState({
            isLoading: true,
        });        
        let token = this.state.token;
        let currentDetailId = this.state.currentDetailId;
        axios.get(getAppUrl()  +"/extranet/rest/articles/"+ this.state.currentDetailId +"/c?language=de&country=de&flatternMaster=false", 
        {
            headers: { 
                "Authorization": " Bearer " + token
            }
        }).then(res2 => {
            let treeData = this.state.treeData;
            let details = undefined;
            let klasse = undefined;
            treeData[0].children.map(function(child) {
                if(child.type === "articles") {
                    details = child;
                }
                if(child.type === "class") {
                    klasse = child.children;                
                }
            });
        
            details.children.map(function(article) {
                if(article.id == currentDetailId) {
                    console.log(article);
                    article.children.map(function(column) {
                        column.value = res2.data.articles[0].values[column.key];
                    });
                }
                                  
                
            });
            let cloneSheepsES6 = [...treeData];

            this.setState({
                treeData: cloneSheepsES6,
                isLoading: false,
                anchorElFlag: null
            });
            
        })
    }

    handleAddMaster() {
        let master = {};
        let articles = [];
        let article = {};
        article.number = this.state.newMasterNumber;
        article.name = "Neuer Master Artikel";
        article.product_class = this.state.newMasterClass;
        article.details = [];
        articles.push(article);
        master.articles = articles;
        master.articleDetails = undefined;
        let treeData = generateTree(master, this.state.classColumns);
        this.setState({
            articleLabel: "neuer Master Artikel",
            isLoaded : true,
            treeData: treeData,
            showNewMaster: false,
            currentMaster: "newNumber"
        }) 

    }    

    selectArticle(entry) {
        let a = this.state.articles;
        a.map(function(detail) {
            if(detail.artnr === entry.artnr) {
                if(detail.checked === 0)
                    detail.checked = 1;
                else
                    detail.checked = 0;
            }
        })
        this.setState({articles: a});
    }

    addArticlesMerkliste(e) {
        let a = this.state.articles;
        
        let details = this.state.merkliste;
        
        a.map(function(detail) {
            if(detail.checked === 1) {
                detail.checked = 0;
                let article = {};
                article.expanded = true;
                article.title= detail.label;
                article.subtitle = detail.artnr;
                article.id = detail.artnr;
                article.type = "article";
                article.artnr = detail.artnr;
                article.children = [];


                details.push(article);                
            }
        });
        
        this.setState({ merkliste: details }); 
    }

    addArticles(e) {
        let a = this.state.articles;

        
        let treeData = this.state.treeData;
        let details = undefined;
        let klasse = undefined;
        treeData[0].children.map(function(child) {
            if(child.type === "articles") {
                details = child;
            }
            if(child.type === "class") {
                klasse = child.children;                
            }
        });
        
        this.state.merkliste.map(function(detail) {
            
                detail.checked = 0;
                let article = {};
                article.expanded = true;
                article.title= detail.label;
                article.subtitle = detail.artnr;
                article.id = detail.artnr;
                article.type = "article";
                article.artnr = detail.artnr;
                article.children = [];
                klasse.map(function(cl) {
                    let kv = {};
                    kv.expanded = false;
                    kv.title = cl.title;
                    kv.key = cl.key;
                    kv.value = "";
                    article.children.push(kv);
                });

                details.children.push(article);                
            
        });
        let cloneSheepsES6 = [...treeData];

        console.log(cloneSheepsES6);


        this.setState({ treeData: cloneSheepsES6, 
                        currentTab: 0,
                        merkliste: []}); 
       
    }

    handleAddColumnValue(e, column) {
        let columns = this.state.columns;
        
        columns.columns[column].options["newKey"] = {};
        columns.columns[column].options["newKey"].name = "huhu";
        columns.columns[column].options["newKey"].key = "newKey";
        this.setState({columns: columns});
    }

    changeColumnValue(e, column, key) {
        let columns = this.state.columns;
        columns.columns[column].options[key].name = e.target.value;
        this.setState({columns: columns});
    }

    changeAtnr(e)  {
        this.setState({ newMasterNumber: e.target.value 
                         });    

    }

    change(e, key, article) {
        let {node, treeIndex, path} = this.state.currentRowInfo;
        let curNode = this.state.currentNode;
        if(e.target.name == "catalogColumn") {
            let columns = this.state.columns;
            columns.columns[key].catalogColumn = (e.target.value == 'true');

            columns.columns[key].dbId = curNode.dbId;
            curNode.catalogColumn = e.target.value;
            this.setState({columns: columns,
                       currentNode: curNode  });   

        } 
        if(e.target.name == "mandatory") {
            let columns = this.state.columns;
            curNode.mandatory = e.target.value;
            columns.columns[key].mandatory = (e.target.value == 'true');
            columns.columns[key].dbId = curNode.dbId;

            this.setState({
                       currentNode: curNode  });   

        }               
        if(e.target.name == "viewOnly") {
            let columns = this.state.columns;
            curNode.viewOnly = e.target.value;
            columns.columns[key].viewOnly = (e.target.value == 'true');
            columns.columns[key].dbId = curNode.dbId;

            this.setState({
                       currentNode: curNode  });   

        }        
        if(e.target.name == "elementColumnValueX") {
            
            let treeData = this.state.treeData;
            let details = undefined;
            treeData[0].children.map(function(child) {
                if(child.type === "articles") {
                    details = child;
                }
            });
            details.children.map(function(child) {

                if(child.id === article.id) {
                    child.children.map(function(c) {
                        if(c.key === key) {
                            c.value = e.target.value;
                            c.subtitle = e.target.value;                           
                        }               
                    });
                }
            });

            let cloneSheepsES6 = [...treeData];
            this.setState({ treeData: cloneSheepsES6 }); 
/*
            curNode.columns.map(function(sentry) {
                if(entry.id === sentry.id) {
                    sentry.value = e.target.value;
                    console.log(sentry);
                }
            });

            let value = 0;
            article.children.map(function(child) {
                if(child.title === entry.title) 
                    value = child.subtitle;
            });
*/
           // node.title = e.target.value;

        }   
/*
        const treeData = changeNodeAtPath({
                treeData: this.state.treeData,
                path: path,
                newNode: node,
                getNodeKey: ({ treeIndex }) =>  treeIndex,
                ignoreCollapsed: true,
        });
        */
        this.setState({
                       currentNode: curNode  });
        
    }    

    catchReturn(ev) {
        if (ev.key === 'Enter') {
            let offset = 0;
            let searchTerm = this.state.searchfield;
            let token = this.state.token;
            axios.get(getAppUrl()  +"/extranet/rest/articles/allArticles?offset=" + offset+"&searchTerm="+searchTerm, 
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res2 => {
                console.log(res2.data);
                offset = offset + 20;
                this.setState({
                    articles: res2.data,
                    offset: offset
                });
            })
            
            ev.preventDefault();
        }        
    }

	handleTabChange(event, newValue) {
        this.setState({
            currentTab : newValue,
        })
	}
	handleTabChangeCA(event, newValue) {
        this.setState({
            currentTabCA : newValue,
        })
	}

    loadMaster(e) {
        this.setState({
			currentMaster: e.target.value
		});        
        this.reload(e.target.value);
    }

    changeDependecy(kind, basisId,  detailId) {
        let bResult = false;
        let {node, treeIndex, path} = this.state.currentRowInfo;
        let depCounter  = this.state.depCounter;
        if(node.entryDependencies && node.entryDependencies.length > 0) {
            let tDep = node.entryDependencies;

            tDep.map(function(entryDependency) {
                if(entryDependency.refEntry === basisId && entryDependency.manageId === detailId && entryDependency.status === kind) {
                    bResult = true;
                    entryDependency.status = "nothing";
                }
                else if(entryDependency.refEntry === basisId && entryDependency.manageId === detailId && entryDependency.status === "nothing") {
                    bResult = true;
                    entryDependency.status = kind;
                }
            });   
            if(bResult === false) {
                let elem = {};
                elem.manageId = detailId;
                elem.status = kind;
                tDep.push(elem);
            }
            const treeData = changeNodeAtPath({
                    treeData: this.state.treeData,
                    path: path,
                    newNode: node,
                    getNodeKey: ({ treeIndex }) =>  treeIndex,
                    ignoreCollapsed: true,
            });
            
            this.setState({ treeData,
                            depCounter: depCounter,
                            currentNode: node  });
        }
        else {
            let tDep = [];           
            let elem = {};
            elem.id = depCounter--;
            elem.refEntry = basisId;
            elem.manageId = detailId;
            elem.status = kind;
            tDep.push(elem);
            node.entryDependencies = tDep;
            const treeData = changeNodeAtPath({
                    treeData: this.state.treeData,
                    path: path,
                    newNode: node,
                    getNodeKey: ({ treeIndex }) =>  treeIndex,
                    ignoreCollapsed: true,
            });
            this.setState({ treeData,
                            depCounter: depCounter,
                            currentNode: node  });

        }
    }

    checkStatus(kind, basisId,  detailId) {
        let node = this.state.currentNode;
        let bResult = false;
        if(node.entryDependencies && node.entryDependencies.length > 0) {
            node.entryDependencies.map(function(entryDependency) {
                if(entryDependency.refEntry === basisId && entryDependency.manageId === detailId && entryDependency.status === kind)
                    bResult = true;
            });
        }
        return bResult;
    }

    changeSearch(e) {
        if(e.target.name === "searchfield") {
            this.setState({
                searchfield : e.target.value,
            })
        } 
        if(e.target.name === "searchMaster") {
            this.setState({
                searchMaster : e.target.value,
            })
        } 
    }

    handleCopyNode(rowInfo) {
        let {node, treeIndex, path} = rowInfo;
        if(node.copy === true)
            node.copy = false;
        else
            node.copy = true;
        const treeData = changeNodeAtPath({
                treeData: this.state.treeDataCopy,
                path: path,
                newNode: node,
                getNodeKey: ({ treeIndex }) =>  treeIndex,
                ignoreCollapsed: true,
        });
        
        this.setState({treeData,
                       });
    }

    handleClean(rowInfo) {
        let {node, treeIndex, path} = rowInfo;
        console.log(node);  
        this.setState({showCleanArticle: true, cleanArticle: node.artnr});            
          
    }


    handleDelete(rowInfo) {
        let {node, treeIndex, path} = rowInfo;
        let deletedNodes = this.state.deletedNodes;
        deletedNodes.push(node);
        const newTree = removeNodeAtPath({
            treeData: this.state.treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
        });
        this.setState({treeData: newTree,
                       deletedNodes: deletedNodes});       
    }


    searchCar(e) {
        this.setState({
			currentCar: e.target.value
		});
    }

    searchChangeAdd(e) {
        this.setState({
			currentSearchAdd: e.target.value
		});
    }

    searchChangeSub(e) {
        this.setState({
			currentSearchSub: e.target.value
		});
    }    

    handleAdd(rowInfo) {
        let newCounter = this.state.counter;
        let NEW_NODE = { title: 'new Node ' + newCounter, noDragging: false, id: newCounter-- };
        let {node, treeIndex, path} = rowInfo;
        //path.pop();
        let parentNode = getNodeAtPath({
            treeData: this.state.treeData,
            path : path,
            getNodeKey: ({ treeIndex }) =>  treeIndex,
            ignoreCollapsed : true
        });
        let getNodeKey = ({ node: object, treeIndex: number }) => {
            return number;
        };
        let parentKey = getNodeKey(parentNode);
        if(parentKey == -1) {
            parentKey = null;
        }
        let newTree = addNodeUnderParent({
                treeData: this.state.treeData,
                newNode: NEW_NODE,
                expandParent: true,
                parentKey: parentKey,
                getNodeKey: ({ treeIndex }) =>  treeIndex
         });
         this.setState({treeData: newTree.treeData,
                        
                        currentNode: NEW_NODE,
                        currentRowInfo: rowInfo,
                        counter: newCounter
        });
    }    

    handleOpenNewMaster() {
        this.setState({ showNewMaster: true });
    }

    handleCloseMaster() {
        this.setState({ showNewMaster: false });
    }
    handleCleanArticle() {
        this.setState({ showCleanArticle: false });
    }

    doCleanArticle() {

        let token =  this.state.token;        

        axios.get(getAppUrl() +"/extranet/rest/articles/cleanArticle?artnr=" + this.state.cleanArticle, 
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res2 => {
                this.setState({
                    showCleanArticle: false
                });
            })


    }

    handleClose() {
        this.setState({ show: false });
    }

    handleCloseColumnProps() {
        this.setState({ showColumnProps: false });
    }

    handleShow(rowInfo) {
        let {node, treeIndex, path} = rowInfo;
        if(node.type === "articles")  {
            this.setState({ show: true,
                            currentTab: 0,
                            merkliste: [],
                            currentNode: node,
                            currentRowInfo: rowInfo,
                            path: path });
        }
        if(node.type === "column")  {
            this.setState({ showColumnProps: true,
                            currentNode: node,
                            currentRowInfo: rowInfo,
                            path: path });
        }

    }
    
    toggleNodeExpansion = expanded => {
        this.setState(prevState => ({
        treeData: toggleExpandedForAll({
            treeData: prevState.treeData,
            expanded,
        }),
        }));
    };

    fetchMoreData = () => {
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }

        let token =  this.state.token;        
        let offset = this.state.offset;
        let searchTerm = this.state.searchfield;
        axios.get(getAppUrl() +"/extranet/rest/articles/allArticles?offset=" + offset+"&searchTerm="+searchTerm, 
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res2 => {
                offset = offset + 20;
                this.setState({
                    articles: this.state.articles.concat(res2.data),
                    offset: offset
                });
            })
        

    };


    async reload(id) {
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }

        let token =  await getEToken();
        this.setState({
            isLoaded : false,
            token: token
        }) 
        axios.get(getAppUrl() +"/extranet/rest/articles/getAllClasses", 
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res5 => {        
                axios.get(getAppUrl() +"/extranet/rest/articles/getAllColumns", 
                    {
                        headers: { 
                            "Authorization": " Bearer " + token
                        }
                    }).then(res4 => {

                        axios.get(getAppUrl() +"/extranet/rest/articles/allArticles?offset=0&searchTerm=", 
                            {
                                headers: { 
                                    "Authorization": " Bearer " + token
                                }
                            }).then(res2 => {

                                axios.get(getAppUrl() +"/extranet/rest/articles/classes-columns", 
                                    {
                                        headers: { 
                                            "Authorization": " Bearer " + token
                                        }
                                    }).then(res1 => {

                                    axios.get(getAppUrl() +"/extranet/rest/articles/" + id +"?language=de", 
                                        {
                                            headers: { 
                                                "Authorization": " Bearer " + token
                                            }
                                        }).then(res => {
                                            let articleLabel = "Unbekannt";
                                            console.log("aaaaARRRRRTIKEL");
                                            console.log(res.data);
                                            console.log(res1.data);
                                            let treeData = generateTree(res.data, res1.data);
                                            this.setState({
                                                articleLabel: articleLabel,
                                                isLoaded : true,
                                                treeData: treeData,
                                                classColumns: res1.data,
                                                articles: res2.data,
                                                columns: res4.data,
                                                classes: res5.data,
                                                currentMaster: id,
                                                searchMaster: id,                                                 
                                            })   
                                    });  
                                });  
                        
                        });
                });  
            });
    }

    handleSaveClose() {
        this.setState({ isLoaded: false });
        let currentMaster = this.state.currentMaster;
        let dObj = {};
        dObj.masterArtikels = this.state.treeData;
        dObj.delNodes = this.state.deletedNodes;
        dObj.columns = this.state.columns;
        console.log(dObj);
        axios.post(getAppUrl() + "/extranet/rest/articles/saveMaster/" + currentMaster +"?language=de", 
            dObj
            ,
        {
            headers: { 
                "Authorization": " Bearer " + getExtranetToken()
            }
        }
        ).then(repsonse => {
            this.setState({ show: false,
                            isLoaded: true});
        }
            
        )
        .catch((error) => {
            console.log(error);
            alert("error");
        });

        this.setState({ show: false });
    }


   	componentDidMount() {          
        this.reload(this.props.match.params.itemId);  

    }

    renderColumnOptions(column) {
        const xx = Object.keys((this.state.columns.columns[column]).options).map(key =>
            <div className="row" style={{marginLeft: "0px"}}>
                <div className="grid-Cell" style={{width: "300px"}}>{key}</div>
                <div className="grid-Cell" style={{width: "400px"}}>
                    <TextField 
                        className="defaultSearch"
                        name="colVal"
                        onChange={(e) => this.changeColumnValue(e, column, key)} 
                        value={(this.state.columns.columns[column]).options[key].name}
                        id="standard-basic" 
                        />                
                </div>
            </div>
        )

        return  <>
                    <div className="row" style={{marginLeft: "0px"}}>
                        <div className="grid-Cell grid-CellHeader" style={{width: "300px"}}>Key</div>
                        <div className="grid-Cell grid-CellHeader" style={{width: "400px"}}>Label</div>
                    </div>        
                    {xx}
                </>        
    }

    renderClasses() {
        const xx = Object.keys(this.state.classes.classes).map(key => 
            <option value={key}>{this.state.classes.classes[key].name}</option>
        )

        return  <select name={"elementColumnValue"} 
                   onChange={e => this.changeMasterClass(e)} 
                    value={this.state.newMasterClass}                    
                >
                    {xx}
                </select>  
    }

    handleChange = (selectedOption) => {
        let article = selectedOption.article;
        let key = selectedOption.key;
        let treeData = this.state.treeData;
        let details = undefined;
        treeData[0].children.map(function(child) {
            if(child.type === "articles") {
                details = child;
            }
        });
        details.children.map(function(child) {

            if(child.id === article.id) {
                child.children.map(function(c) {
                    if(c.key === key) {
                        c.value = selectedOption.value;
                        c.subtitle = selectedOption.value;
                           
                    }               
                });
            }
        });

        let cloneSheepsES6 = [...treeData];
        this.setState({ treeData: cloneSheepsES6 });
    }

    handleCreate(value, key, article) {
        //console.log(this.state.columns.columns[key]);


        let obj = {};
        obj.name = value; 
        obj.key = key; 
        this.setState({ isLoading: true });

        axios.post(getAppUrl() + '/extranet/rest/articles/column-value-add', 
                    obj, {
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}).then(res1 => {	
                    let op = this.state.columns.columns[key].options;
                    let newOption = {};
                    newOption.key = res1.data;
                    newOption.name = value;
                    newOption.position = 0;
                    
                    op[value] = newOption;
                    let columns = this.state.columns;
                    columns.columns[key].options = op;

                    this.setState({
                        columns: columns,                       
                        isLoading : false,
                    }) ;

        let treeData = this.state.treeData;
        let details = undefined;
        treeData[0].children.map(function(child) {
            if(child.type === "articles") {
                details = child;
            }
        });
        console.log("details");
        console.log(details);
        details.children.map(function(child) {

            if(child.id === article.id) {
                child.children.map(function(c) {
                    if(c.key === key) {
                        c.value = value;
                        c.subtitle = value;
                           
                    }               
                });
            }
        });

        let cloneSheepsES6 = [...treeData];
        this.setState({ treeData: cloneSheepsES6 });




                        

                    })
                    .catch((error) => {
                        console.log(error);
                    });     
           
    }    

    renderSelect(entry, article) {
        let vKey = -1;
        let options = [];
        let t = this;
            let e1 = {};
            e1.value = "select...";
            e1.label = "Select...";
            e1.key = entry.key;
            e1.article = article;
            options.push(e1);

        let yy = Object.keys((this.state.columns.columns[entry.key]).options).map(key => {
            let e = {};
            e.value = key;
            e.label = (t.state.columns.columns[entry.key]).options[key].name;
            e.key = entry.key;
            e.article = article;
            options.push(e);

        }
        )
        article.children.map(function(child) {
            if(child.key === entry.key) {
                let count = -1;
                options.map(o => {
                    count++;

                    if(child.value == o.value) {
                        vKey = count;

                    }

                })
            }
        });


        return  <> 
                <CreatableSelect style={{width: "250px"}}
                        value={options[vKey]}
                        onChange={this.handleChange}
                        onCreateOption={e => this.handleCreate(e, entry.key, article)}
                        isDisabled={this.state.isLoading}
                        isLoading={this.state.isLoading}                        
                        options={options} />        

                </>        
    } 

    renderSelectx(entry, article) {
        const xx = Object.keys((this.state.columns.columns[entry.key]).options).map(key => 
            <option value={key}>{(this.state.columns.columns[entry.key]).options[key].name}</option>
        )
        let value = 0;
        let key = "";
        article.children.map(function(child) {
            if(child.key === entry.key) {
                value = child.value;
                key = child.key;
            }
        });
        return  <select name={"elementColumnValue"} 
                    onChange={e => this.change(e, key, article)} 
                    value={value}>
                    <option value="0">Keine</option>
                    {xx}
                </select>        
    }




    render() {
        const {isLoaded} = this.state;

        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };            
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">
                           {this.state.articleLabel}
                        </div>
                        <div class="Vivacardbody" style={{ height: "calc(100vH - 200px)" }}>

                            <div className="row " style={{ height: "calc(100vH - 200px)" }}>
                                <div className="col-md-7 col-lg-7 col-xl-7 disablePaddingRight">
                                    <div class="Vivacardheader" style={{height: "35px"}}>
                                        <span>
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={this.handleSaveClose}
                                                >
                                                <span className="fa fa-download "></span>
                                            </Button>
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={e => this.handleOpenNewMaster()}
                                                >
                                                <span className="fa fa-plus "></span>
                                            </Button>                                     
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={this.toggleNodeExpansion.bind(this, true)}
                                                >
                                                <span className="fa fa-expand "></span>
                                            </Button>   
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={this.toggleNodeExpansion.bind(this, false)}
                                                >
                                                <span className="fa fa-compress"></span>
                                            </Button>
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={e => this.reload(this.state.searchMaster)}
                                                >
                                                <span className="fa fa-refresh "></span>
                                            </Button>                                    
                                            Sprache:&nbsp;
                                            <select name="display" 
                                                    onChange={e => this.change(e)} 
                                                    value={"de"}>
                                                    <option value="de">Deutsch</option>
                                                    <option value="en">English</option>
                                            </select>                                       

                                                                                 
                                        </span>
                                    </div>  
                                    <hr/>     

                                    <SortableTree
                                        treeData={this.state.treeData}
                                        canDrag={({ node }) => !node.noDragging}
                                        onChange={treeData => this.setState({ treeData })}
                                        generateNodeProps={rowInfo => ({
                                            buttons: rowInfo.node.type === "article" ? [ <Button
                                                    type="button"
                                                    className="buttonIcon"
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={() => this.handleShow(rowInfo)}
                                                    >
                                                    <span className="fa fa-pencil "></span>
                                                </Button>,
                                                <Button
                                                    type="button"
                                                    className="buttonIcon"
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={(e) => this.handleDelete(rowInfo)}
                                                    >
                                                    <span className="fa fa-minus "></span>
                                                </Button>,
                                                <Button
                                                    type="button"
                                                    className="buttonIcon"
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={() => this.handleClean(rowInfo)}
                                                    >
                                                    <span className="fa fa-eraser "></span>
                                                </Button>] : 
                                                rowInfo.node.type === "column" ?
                                                [<Button
                                                    type="button"
                                                    className="buttonIcon"
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={() => this.handleShow(rowInfo)}
                                                    >
                                                    <span className="fa fa-pencil "></span>
                                                </Button>]
                                                : 
                                                rowInfo.node.type === "articles" ?
                                                [<Button
                                                    type="button"
                                                    className="buttonIcon"
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={() => this.handleShow(rowInfo)}
                                                    >
                                                    <span className="fa fa-pencil "></span>
                                                </Button>]
                                                :                                                
                                                []


                                            
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <Dialog onClose={this.handleClose} 
                                aria-labelledby="customized-dialog-title" 
                                fullWidth="lg"
                                maxWidth="lg"
                                open={this.state.show}>
                            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                                Detail Artikel editieren 
                                
                                <IconButton style={{float: "right"}} edge="end" color="inherit" onClick={this.handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent dividers>
                                <div>
                                    <AppBar position="static" color="default">
                                            <Tabs 	className="grid-Cell-Header"
                                                    value={this.state.currentTab}
                                                    onChange={this.handleTabChange}
                                                    indicatorColor="secondary"
                                                    textColor="white"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                >
                                                <Tab label="Aktuell" />
                                                <Tab label="Produkte" />
                                            </Tabs>
                                    </AppBar>                                

                                {this.state.currentTab === 0 &&  
                                        <>      
                                            <div  style={{height: "10px", marginLeft: "0px"}}>
                                            </div>
                                            <div  style={{height: "450px", overflow: "scroll"}}>
                                                <div className="row" style={{marginLeft: "0px", width: "2500px"}}>
                                                    <div className="grid-Cell grid-CellHeader"  style={{width: "40px"}}>
                                                        M
                                                    </div> 
                                                    <div className="grid-Cell grid-CellHeader">
                                                        Artnr
                                                    </div>   
                                                    
                                                    {this.state.currentNode.columns && this.state.currentNode.columns.length > 0 ? (                             
                                                        <>
                                                            {this.state.currentNode.columns.map(entry => (
                                                                <>
                                                                    <div  className="grid-Cell grid-CellHeader">
                                                                        {entry.title}
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </>
                                                    ) : (<></>)
                                                    }
                                                </div>
                                                
                                                {this.state.currentNode.children && this.state.currentNode.children.length > 0 ? (
                                                    <>
                                                        {this.state.currentNode.children.map(entry => (
                                                            <div className="row" style={{marginLeft: "0px", width: "2500px"}}>
                                                                <div className="grid-Cell" style={{width: "40px"}}>
                                                                    <Button className="buttonIcon" aria-controls="simple-menuFlag" aria-haspopup="true" onClick={e => this.handleClickFlag(e, entry.id)}>
                                                                        <span class="fa fa-ellipsis-v pull-right"></span>
                                                                    </Button>
                                                                    
                                                                </div>
                                                                <div className="grid-Cell">
                                                                    {entry.id}
                                                                </div>
                                                                {this.state.currentNode.columns.map(sentry => (
                                                                        <>
                                                                            <div  className="grid-Cell">
                                                                            {this.renderSelect(sentry, entry)}
                                                                            </div>
                                                                        </>
                                                                ))}                                                
                                                            </div>
                                                        ))}
                                                    </>
                                                    ) : (<></>)
                                                }
                                                
                                            </div>
                                        </>
                                    }
                                    {this.state.currentTab === 1 &&                                                        
                                    <>
                                        <div  style={{height: "450px"}}>

                                            <div className="row" style={{marginLeft: "0px"}}>
                                                <div className="grid-Cell" style={{width: "800px"}}>
                                                    <div  style={{height: "60px", marginLeft: "0px"}}>
                                                        <span className="pull-right">
                                                            <TextField 
                                                                className="defaultSearch"
                                                                name="searchfield"
                                                                onChange={e => this.changeSearch(e)} 
                                                                onKeyPress={e => this.catchReturn(e)}
                                                                value={this.state.searchfield}
                                                                id="standard-basic" 
                                                                label="Suche" />                                
                                                        </span>
                                                    </div>
                                                    <div  style={{height: "350px", overflow: "scroll", marginLeft: "0px"}}>
                                                        <div className="row" style={{marginLeft: "0px", width: "2500px"}}>
                                                            <div className="grid-Cell  grid-CellHeader" style={{width : "45px"}}>
                                                                <div className="grid-Cell__header" style={{width : "45px"}}>     
                                                                :
                                                                </div>
                                                            </div>
                                                            <div className="grid-Cell grid-CellHeader">
                                                                Artnr
                                                            </div> 
                                                            <div className="grid-Cell grid-CellHeader"  style={{width: "600px"}}>
                                                                Label
                                                            </div> 
                                                        </div>
                                                        {this.state.articles.length > 0 ? (
                                                        <InfiniteScroll
                                                                dataLength={this.state.articles.length}
                                                                next={this.fetchMoreData}
                                                                hasMore={this.state.hasMore}
                                                                loader={<h4>Loading...</h4>}
                                                                height={350}
                                                                endMessage={
                                                                    <p style={{ textAlign: "center" }}>
                                                                    <b>Yay! You have seen it all</b>
                                                                    </p>
                                                                }
                                                                >

                                                                {this.state.articles.map(entry => (
                                                                    <div className="row">
                                                                        <div className="grid-Cell  grid-CellHeader" style={{width : "60px"}}>
                                                                            <div className="grid-Cell__header" style={{width : "60px"}}>                                             
                                                                                <Checkbox
                                                                                    name={"select"}
                                                                                    onClick={e => this.selectArticle(entry)}
                                                                                    checked={entry.checked === 1 ? "checked" : ""}
                                                                                    className="selectSmall"
                                                                                    value="true"
                                                                                    color="primary"
                                                                                />
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div className="grid-Cell">
                                                                            {entry.artnr}
                                                                        </div>
                                                                        <div className="grid-Cell" style={{width: "600px"}}>
                                                                            {entry.label}
                                                                        </div>                                                                                                         
                                                                    </div>
                                                                ))}                                               
                                                        </InfiniteScroll>
                                                        ) : (<>Kein Ergebnis</>)}
                                                        <hr/>
                                        
                                                    </div> 



                                                </div>
                                                <div className="grid-Cell" style={{width: "400px"}}>
                                                    <div  style={{paddingTop: "60px", marginLeft: "0px"}}>
                                                       
                                                       Merkliste <br/>
                                                       <hr/>
                                                        {this.state.merkliste.map(entry => (
                                                            < >
                                                                    {entry.artnr}
                                                                    <br/>
                                                            </ >
 
                                                    ))} 
                                                    </div>                                                                                                    
                                                </div>                                        


                                            </div>
                                            <div className="row" style={{marginLeft: "0px"}}>
                                                <div className="grid-Cell" style={{width: "800px"}}>
                                                    <Button autoFocus onClick={e => this.addArticlesMerkliste()} color="primary">
                                                        Auswahl übernehmen
                                                    </Button>                                                    
                                                </div>  
                                                <div className="grid-Cell" style={{width: "400px"}}>
                                                    <Button autoFocus onClick={e => this.addArticles()} color="primary">
                                                        Auswahl hinzufügen
                                                    </Button>                                                    
                                                </div>                                        

                                            </div>                                                                                      
                                        </div>

                                         
                                    </>
                                    }
                                </div>
<Menu
                                id="simple-menuFlag "
                                anchorEl={this.state.anchorElFlag}
                                keepMounted
                                open={Boolean(this.state.anchorElFlag)}
                                onClose={this.handleCloseFlag}
                            >
                            <MenuItem  onClick={e => this.handleUpdateDetail('aaa')}>aktualisieren</MenuItem>
                            </Menu>
                            </DialogContent>
                            <DialogActions>
                            <Button autoFocus onClick={this.handleClose} color="primary">
                                OK
                            </Button>
                            </DialogActions>
                        </Dialog>
                        
                        <Dialog onClose={this.handleCleanArticle} 
                                aria-labelledby="customized-dialog-title" 
                                fullWidth="xs"
                                maxWidth="xs"
                                open={this.state.showCleanArticle}>
                            <DialogTitle id="customized-dialog-title" onClose={this.handleCleanArticle}>
                                Artikel reinigen: 
                            </DialogTitle>
                            <DialogContent dividers>
                                <div className="row" style={{marginLeft: "0px"}}>
                                    <div  style={{width: "200px"}}>ArtikelNummer: {this.state.cleanArticle}</div>
                                    <div  style={{width: "300px"}}>

                                    </div>
                                </div> 
                                
                            </DialogContent>
                            <DialogActions>
                            <Button autoFocus onClick={this.handleCleanArticle} color="primary">
                                Abbruch
                            </Button>
                            <Button autoFocus onClick={this.doCleanArticle} color="primary">
                                OK
                            </Button>                            
                            </DialogActions>
                        </Dialog>
                        
                        <Dialog onClose={this.handleCloseMaster} 
                                aria-labelledby="customized-dialog-title" 
                                fullWidth="md"
                                maxWidth="md"
                                open={this.state.showNewMaster}>
                            <DialogTitle id="customized-dialog-title" onClose={this.handleCloseMaster}>
                                Master Artikel erstellen: 
                            </DialogTitle>
                            <DialogContent dividers>
                                <div className="row" style={{marginLeft: "0px"}}>
                                    <div  style={{width: "200px"}}>ArtikelNummer:</div>
                                    <div  style={{width: "300px"}}>
                                        <TextField 
                                            className="defaultSearch"
                                            name="articleNummer"
                                            onChange={e => this.changeAtnr(e)} 
                                            value={this.state.newMasterNumber}
                                            
                                            />                                 
                                    </div>
                                </div> 

                                <div className="row" style={{marginLeft: "0px"}}>
                                    <div  style={{width: "200px"}}>Artikelklasse:</div>
                                    <div  style={{width: "300px"}}>{this.renderClasses()}</div>
                                </div> 
                                
                            </DialogContent>
                            <DialogActions>
                            <Button autoFocus onClick={this.handleAddMaster} color="primary">
                                OK
                            </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog onClose={this.handleCloseColumnProps} 
                                aria-labelledby="customized-dialog-title" 
                                fullWidth="lg"
                                maxWidth="lg"
                                open={this.state.showColumnProps}>
                            <DialogTitle id="customized-dialog-title" onClose={this.handleCloseColumnProps}>
                                Column Adminstration: {this.state.currentNode.title}
                            </DialogTitle>
                                    <AppBar position="static" color="default">
                                            <Tabs 	className="grid-Cell-Header"
                                                    value={this.state.currentTabCA}
                                                    onChange={this.handleTabChangeCA}
                                                    indicatorColor="secondary"
                                                    textColor="white"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                >
                                                <Tab label="ColumnWerte" />
                                                <Tab label="Eigenschaften" />
                                            </Tabs>
                                    </AppBar>                                
                                    <div style={{minHeight: "410px"}}>
                                    {this.state.currentTabCA === 0 && 
                                        <DialogContent dividers>
                                            <div style={{height: "400px", overflow: "scroll", marginLeft: "0px"}}>
                                                <>
                                                    {this.state.currentNode.title !== undefined && this.state.currentNode.type === "column" ? (
                                                        <>
                                                            {this.renderColumnOptions(this.state.currentNode.key)}
                                                        </>
                                                    ): (<></>)}
                                                </>
                                            </div>
                                            <hr/>
                                            <Button autoFocus onClick={e => this.handleAddColumnValue(e, this.state.currentNode.key)} color="primary">
                                                Eintrag hinzufügen
                                            </Button>
                                        </DialogContent>
                                    }
                                    {this.state.currentTabCA === 1 && 
                                        <div style={{padding: "4px"}}>
                                        <FormControl  style={{width: "20%"}}>
                                            <InputLabel htmlFor="age-native-helper">Verhalten</InputLabel>
                                            <NativeSelect
                                                value={this.state.currentNode.viewOnly}
                                                onChange={e => this.change(e, this.state.currentNode.key)}
                                                inputProps={{
                                                    name: 'viewOnly',
                                                    id: 'age-native-helper',
                                                }}
                                            >
                                                <option value={false}>Auswahl für Benutzer</option>
                                                <option value={true}>Nur anzeigen</option>
                                            </NativeSelect>
                                        </FormControl>	
                                        <br/><br/>
                                        <FormControl  style={{width: "20%"}}>
                                            <InputLabel htmlFor="age-native-helper">Eingabe</InputLabel>
                                            <NativeSelect
                                                value={this.state.currentNode.mandatory}
                                                onChange={e => this.change(e, this.state.currentNode.key)}
                                                inputProps={{
                                                    name: 'mandatory',
                                                    id: 'age-native-helper',
                                                }}
                                            >
                                                <option value={true}>Pflichtfeld</option>
                                                <option value={false}>optional</option>
                                            </NativeSelect>
                                        </FormControl>	
                                        <br/><br/>                                        
                                        <FormControl  style={{width: "20%"}}>
                                            <InputLabel htmlFor="age-native-helper">Katalog Tabellen Header</InputLabel>
                                            <NativeSelect
                                                value={this.state.currentNode.catalogColumn}
                                                onChange={e => this.change(e, this.state.currentNode.key)}
                                                inputProps={{
                                                    name: 'catalogColumn',
                                                    id: 'age-native-helper',
                                                }}
                                            >
                                                <option value={false}>Nein</option>
                                                <option value={true}>Ja</option>
                                            </NativeSelect>
                                        </FormControl>	
                                        </div>
}
                                    </div>
                            <DialogActions>
                            <Button autoFocus onClick={this.handleCloseColumnProps} color="primary">
                                OK
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <div className="clearmargin gridBottom">
                        <Button onClick={this.handleSaveClose}>
								Speichern
						</Button>
                        <Button onClick={e => this.props.history.push("/maske/2/101/" + this.props.match.params.vivaId)}>
								Abbrechen
						</Button>
                    </div>

                </div>
            );
       }
    }
}
export default ConfigureMaster ;

